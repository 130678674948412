<template>
  <v-container>
    <v-card class="col-12 col-lg-8">
      <v-card-title><b>Admin</b></v-card-title>
      <v-card-text>
        <div>
          <h3>Auth</h3>
          <v-form v-if="!session_info.name" ref="loginForm" v-model="isLoginFormValid" @submit.prevent="login()">
            <v-text-field ref="adminUser" v-model="auth.user" label="Admin user" autocomplete="username" :rules="[rules.required]" />
            <v-text-field ref="adminPass" v-model="auth.pass" type="password" label="Admin pass" autocomplete="current-password" :rules="[rules.required]" />
            <v-btn type="submit" color="primary">
              Login
            </v-btn>
          </v-form>
          <template v-if="session_info.name">
            <read-only-input :value="session_info.name" label="Current User" />
            <read-only-input :value="session_info.roles.join(', ')" label="Current Roles" />
            <v-btn @click="logout()">
              Logout
            </v-btn>
          </template>
        </div>
        <div v-if="session_info.roles.includes('_admin')">
          <h3>Farm Management</h3>
          <v-form ref="farmForm" v-model="isFarmFormValid" @submit.prevent="createFarm()">
            <v-text-field ref="farmId" v-model="farm_id" label="Farm ID" :rules="[rules.required]" />
            <v-text-field ref="farmName" v-model="farm_record.name" label="Farm Name" :rules="[rules.required]" />
            <v-text-field ref="farmHerdNumber" v-model="farm_record.herdNumber" label="Farm Herd Number" :rules="[rules.required]" />
            <read-only-input ref="farmStatus" :value="farm_status" label="Farm Status" :color="farm_db == null ? null : farm_db ? 'green' : 'red'" />
            <read-only-input v-if="farm_users_formatted" ref="farmUsers" :value="farm_users_formatted" label="Farm Users" />
            <v-combobox ref="farmUser" v-model="farmUser.user" :items="farm_users_names" label="User" :rules="[rules.required]" clearable />
            <v-text-field ref="farmUserPass" v-model="farmUser.pass" type="password" label="New pass" :rules="[rules.requiredWhenNewUser]" />
            <v-checkbox ref="farmUserAdmin" v-model="farmUser.admin" label="Admin" />
            <v-btn type="submit" color="primary">
              {{ farm_user_existing_user ? 'Update' : 'Create' }}
            </v-btn>
            <v-btn v-if="farm_user_existing_user" @click="deleteUser()">
              Delete User
            </v-btn>
          </v-form>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ReadOnlyInput from '@/components/ReadOnlyInput'
import PouchDB from 'pouchdb-browser'

export default {
  name: 'Admin',
  components: { ReadOnlyInput },
  data: vm => ({
    farm_id: null,
    auth: {
      user: localStorage.getItem('admin_user'),
      pass: null
    },
    users_db: null,
    farm_db: null,
    farm_record: {
      _id: 'farm',
      herdNumber: null,
      name: null
    },
    farm_users: [],
    farmUser: {
      user: null,
      pass: null,
      admin: false
    },
    session_info: {
      name: null,
      roles: []
    },
    rules: {
      required: value => !!value || 'Required',
      requiredWhenNewUser: value => !!value || !!vm.farm_user_existing_user || 'Required',
      requiredWhenNewFarm: value => !!value || !!vm.farm_db || 'Required'
    },
    isLoginFormValid: false,
    isFarmFormValid: false
  }),
  computed: {
    farm_status() {
      return this.farm_db == null ? '-' : this.farm_db ? 'EXISTING' : 'NEW';
    },
    farm_users_names() {
      return this.farm_users.map(user => user.name.substr(this.farm_id.length + 1));
    },
    farm_users_formatted() {
      return this.farm_users.map(user =>
        user.name.substr(user.name.indexOf('_') + 1) + (this.isUserAdmin(user) ? ' (admin)' : '')
      ).join(', ');
    },
    farm_user_internal_name() {
      return this.farm_id + '_' + this.farmUser.user;
    },
    farm_user_existing_user() {
      return this.farm_users.find(user => user.name == this.farm_user_internal_name);
    }
  },
  watch: {
    async farm_id() {
      await this.updateFarmStatus();
    },
    farm_user_internal_name() {
      this.farmUser.admin = this.farm_user_existing_user && this.isUserAdmin(this.farm_user_existing_user);
    }
  },
  created() {
    this.users_db = this.$pouch.getDB('https://couch.pgmann.com/db/_users');
    this.updateSessionInfo();
  },
  methods: {
    isUserAdmin(user) {
      return user.roles.includes('farm_admin_' + this.farm_id);
    },
    async updateSessionInfo() {
      let session = await this.users_db.getSession();
      this.session_info = session.userCtx;
    },
    async login() {
      if(!this.isLoginFormValid) {
        this.$refs.loginForm.validate();
        let firstInvalidField = this.$refs.loginForm.inputs.find(input => !input.valid)
        if(firstInvalidField) {
          firstInvalidField.focus();
          return;
        }
      }

      if(this.session_info.name) return;

      await this.users_db.logIn(this.auth.user, this.auth.pass);
      let info = await this.users_db.info();
      if (info.error == "unauthorized") console.log('Your username or password is incorrect');
      else if (info.error == "not_found") console.log('hmm');
      else console.log('success');
      await this.updateSessionInfo();
    },
    async logout() {
      await this.users_db.logOut();
      await this.updateSessionInfo();
    },
    async updateFarmStatus() {
      if (!this.farm_id) {
        this.farm_db = null;
        this.farm_users = [];
        this.farm_record.herdNumber = null;
        this.farm_record.name = null;
        return;
      }

      let db = new PouchDB('https://couch.pgmann.com/db/' + this.farm_id, { skip_setup: true });
      let info = await db.info();
      console.log(info);
      if(info.error) {
        this.farm_db = false;
        this.farm_users = [];
        this.farm_record.herdNumber = null;
        this.farm_record.name = null;
        return;
      }
      
      this.farm_db = db;
      let data = await this.users_db.allDocs({
        startkey: 'org.couchdb.user:' + this.farm_id + '_',
        endkey: 'org.couchdb.user:' + this.farm_id + '_\ufff0',
        include_docs: true
      });
      this.farm_users = data.rows.map(user => user.doc);
      try {
        this.farm_record = await this.farm_db.get('farm');
      } catch (e) {
        this.farm_record.herdNumber = null;
        this.farm_record.name = null;
      }
    },
    async createFarm() {
      if(!this.isFarmFormValid) {
        this.$refs.farmForm.validate();
        let firstInvalidField = this.$refs.farmForm.inputs.find(input => !input.valid)
        if(firstInvalidField) {
          firstInvalidField.focus();
          return;
        }
      }

      if(!this.users_db) return;

      let db = new PouchDB('https://couch.pgmann.com/db/' + this.farm_id);
      var farm_member_role = 'farm_member_' + this.farm_id, farm_admin_role = 'farm_admin_' + this.farm_id;
      let info = await db.info();
      console.log(info);
      await db.fetch('_security', {
        method: 'PUT',
        body: JSON.stringify({
          admins: {
            roles: ['_admin']
          },
          members: {
            roles: ['_admin', farm_member_role]
          }
        })
      });
      let designDoc = {
        "_id": "_design/app",
        "views": {
          "animal-breeds": {
            "map": "function (doc) {\n  if(doc.type == \"animal\" && doc.breed) {\n    emit(doc.breed, null);\n  }\n}",
            "reduce": "function (keys, values, rereduce) {\n  return true;\n}"
          },
          "semen-breeds": {
            "map": "function (doc) {\n  if(doc.type == \"purchasedSemen\" && doc.breed) {\n    emit(doc.breed, null);\n  }\n}",
            "reduce": "function (keys, values, rereduce) {\n  return true;\n}"
          },
          "medicine-products": {
            "map": "function (doc) {\n  if(doc.type == \"purchasedMedicine\" && doc.product) {\n    emit(doc.product, null);\n  }\n}",
            "reduce": "function (keys, values, rereduce) {\n  return true;\n}"
          },
          "animal-status": {
            "reduce": "function (keys, values, rereduce) {\r\n  var results = values.filter(value => !!value.date).sort((a,b)=>a.date.localeCompare(b.date));\r\n  var result = results[results.length-1];\r\n  var parity = values.reduce((subtotal, value) => subtotal + (value.parity || 0), 0);\r\n  var newParity = values.filter(value => value.status == 'Awaiting AI' && value.parity == null).length;\r\n  parity += newParity;\r\n  result.parity = parity;\r\n  return result;\r\n}",
            "map": "function (doc) {\r\n  if(doc.type == 'animal') {\r\n    emit(doc._id, {\r\n      status: doc.animalType == 'Boar' ? '-' : 'Awaiting AI',\r\n      date: doc.dateSelected,\r\n      parity: Number(doc.startingParity || 0)\r\n    });\r\n    if(doc.dateCulled) {\r\n      emit(doc._id, {status: 'Moved off', date: doc.dateCulled, details: 'Culled'});\r\n    }\r\n  }\r\n  if(doc.type == 'breeding') {\r\n    emit(doc.id, {\r\n      status: 'Served',\r\n      date: doc.dateOne,\r\n      aiDate: doc.dateOne\r\n    });\r\n    if(doc.scanResult != null) {\r\n      emit(doc.id, {\r\n        status: doc.scanResult === false ? 'Barren' : 'In pig',\r\n        date: doc.scanDate,\r\n        aiDate: doc.dateOne\r\n      });\r\n    }\r\n    if(doc.farrowDate) {\r\n      emit(doc.id, {\r\n        status: 'Farrowed',\r\n        date: doc.farrowDate,\r\n        aiDate: doc.dateOne\r\n      });\r\n    }\r\n    if(doc.weaned != null) {\r\n      emit(doc.id, {\r\n        status: 'Awaiting AI',\r\n        date: doc.weanedDate\r\n      });\r\n    }\r\n  }\r\n  if(doc.type == 'fallenStock') {\r\n    if(/([0-9a-fA-F]{28})|([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/.test(doc.id)) {\r\n      emit(doc.id, {status: 'Moved off', date: doc.date, details: 'Died'});\r\n    }\r\n  }\r\n}"
          },
          "todo-tasks-due": {
            "map": "function (doc) {\r\n  if (doc.type == 'animal' && doc.animalType == 'Sow/gilt') {\r\n    emit(doc.dateSelected, { type: 'aiOneDue' });\r\n  }\r\n  if (doc.type == 'breeding') {\r\n    let offsetDate = function(date, daysOffset) {\r\n      let dateObject = new Date(date);\r\n      dateObject.setDate(dateObject.getDate() + daysOffset);\r\n      return dateObject.toISOString().split('T')[0];\r\n    }\r\n    emit(doc.dateOne, { _id: doc.id, type: 'aiTwoDue' });\r\n    emit(offsetDate(doc.dateOne, 28), { _id: doc.id, type: 'scanDue' });\r\n    if(doc.scanResult) {\r\n      emit(offsetDate(doc.dateOne, 114-42), { _id: doc.id, type: 'giltPreFarrowVaccine' });\r\n      emit(offsetDate(doc.dateOne, 114-21), { _id: doc.id, type: 'sowGiltPreFarrowVaccine' });\r\n      emit(offsetDate(doc.dateOne, 114-5), { _id: doc.id, type: 'crateDue' });\r\n      emit(offsetDate(doc.dateOne, 114), { _id: doc.id, type: 'farrowDue' });\r\n      if(doc.alive) {\r\n        emit(offsetDate(doc.dateOne, 114+21), { _id: doc.id, type: 'sowPreWeanVaccine' });\r\n        emit(offsetDate(doc.dateOne, 114+21), { _id: doc.id, type: 'pigletPreWeanVaccine' });\r\n        emit(offsetDate(doc.dateOne, 114+28), { _id: doc.id, type: 'weanDue' });\r\n      }\r\n    }\r\n    emit(doc.scanResult !== false ? offsetDate(doc.dateOne, 114+28) : doc.scanDate, { _id: doc.id, type: 'aiOneDue' });\r\n  }\r\n}"
          },
          "todo-tasks-completed": {
            "map": "function (doc) {\r\n  if (doc.type == 'breeding') {\r\n    emit(doc.id, { type: 'aiOneDue', recordId: doc._id, date: doc.dateOne, success: true });\r\n    if (doc.dateTwo)    emit(doc.id, { type: 'aiTwoDue', recordId: doc._id, date: doc.dateTwo, success: true });\r\n    if (doc.scanDate)   emit(doc.id, { type: 'scanDue', recordId: doc._id, date: doc.scanDate, success: !!doc.scanResult });\r\n    if (doc.cratedDate) emit(doc.id, { type: 'crateDue', recordId: doc._id, date: doc.cratedDate, success: true });\r\n    if (doc.farrowDate) emit(doc.id, { type: 'farrowDue', recordId: doc._id, date: doc.farrowDate, success: true });\r\n    if (doc.weanedDate) emit(doc.id, { type: 'weanDue', recordId: doc._id, date: doc.weanedDate, success: true });\r\n  }\r\n  if (doc.type == 'medicine') {\r\n    let reasons = {\r\n      'Worming programme': 'specialWormingDue',\r\n      'Routine before weaning': 'sowPreWeanVaccine',\r\n      'Routine before farrow': 'sowGiltPreFarrowVaccine',\r\n      'Routine before farrow (gilts)': 'giltPreFarrowVaccine',\r\n      'Routine at weaning': 'pigletPreWeanVaccine'\r\n    }\r\n    if(doc.reason in reasons) {\r\n      for(let animal of doc.animals) {\r\n        emit(animal, { type: reasons[doc.reason], recordId: doc._id, date: doc.startDate, quantity: doc.animalQuantity, success: true });\r\n      }\r\n    }\r\n  }\r\n}"
          },
          "reminders": {
            "map": "function (doc) {\r\n  if (doc.type == 'animal' && doc.lastCycleDetected) {\r\n    let date = new Date(doc.lastCycleDetected);\r\n    date.setDate(date.getDate() + 19);\r\n    date = date.toISOString().split('T')[0];\r\n    emit(date, {completed: false}); // date will change/be removed when completed\r\n  }\r\n  if (doc.type == 'medicine' && doc.reminders) {\r\n    for(let reminderDate of doc.reminders) {\r\n      emit(reminderDate, {completed: doc.endDate >= reminderDate});\r\n    }\r\n  }\r\n}"
          },
          "farrow-alive": {
            "reduce": "_sum",
            "map": "function (doc) {\r\n  if(doc.type == 'breeding') emit(doc._id, doc.alive || 0);\r\n  if(doc.type == 'fallenStock' && doc.farrowId) emit(doc.farrowId, -doc.quantity);\r\n}"
          },
          "semen-available": {
            "reduce": "function (keys, values, rereduce) {\r\n  values = values.filter(value => value).flat();\r\n  var output = [], purchases = [], uses = [];\r\n  for(var value of values) (value.type == 'breeding' ? uses : value.type == 'boar' ? output : purchases).push(value);\r\n  for(var purchase of purchases) {\r\n    purchase.quantity += uses.reduce((subtotal, use) => use.id == purchase.id ? subtotal + (use.quantity || 0) : subtotal, 0);\r\n    if(purchase.quantity > 0) output.push(purchase);\r\n  }\r\n  output.push(...uses.filter(use => !output.find(purchase => purchase.id == use.id) && !purchases.find(purchase => purchase.id == use.id)));\r\n  return output;\r\n}",
            "map": "function (doc) {\r\n  if(doc.type == 'animal' && doc.animalType == 'Boar' && !doc.dateCulled)\r\n    emit(['semen', 'boar', doc._id], {\r\n      type: 'boar', id: doc._id, title: doc.breed, details: doc.id\r\n    });\r\n  if(doc.type == 'purchasedSemen')\r\n    emit(['semen', 'purchased', doc._id], {\r\n      type: 'purchased', id: doc._id, quantity: doc.quantity, title: doc.breed, details: 'BN ' + doc.batchNo + ' - Boar ID ' + doc.boarId\r\n    });\r\n  if(doc.type == 'breeding') {\r\n    if(doc.semenOne) emit(['semen', 'purchased', doc.semenOne], {type: 'breeding', id: doc.semenOne, quantity: -1});\r\n    if(doc.semenTwo) emit(['semen', 'purchased', doc.semenTwo], {type: 'breeding', id: doc.semenTwo, quantity: -1});\r\n  }\r\n}"
          }
        },
        "language": "javascript"
      };
      try {
        designDoc._rev = (await db.get('_design/app'))._rev;
        await db.put(designDoc);
      } catch(e) {
        await db.post(designDoc);
      }
      let dbMethod = !this.farm_record._rev ? db.post : db.put;
      await dbMethod(this.farm_record);
      
      let roles = [farm_member_role];
      if(this.farmUser.admin) roles.push(farm_admin_role);
      try{
        await this.users_db.signUp(this.farm_user_internal_name, this.farmUser.pass, { roles: roles });
      } catch(e) {
        console.log('Existing user');
        if(this.farmUser.pass) await this.users_db.changePassword(this.farm_user_internal_name, this.farmUser.pass);
        await this.users_db.putUser(this.farm_user_internal_name, { roles: roles });
      }
      
      await this.updateFarmStatus();
    },
    async deleteUser() {
      if(this.farmUser.user && this.users_db) {
        await this.users_db.deleteUser(this.farm_user_internal_name);
        await this.updateFarmStatus();
      }
    }
  }
}
</script>

<style scoped>
.v-card__text > div:not(:first-child) {
  margin-top: 30px;
}
</style>