<template>
  <v-container>
    <portal to="app-bar-icons">
      <v-btn type="submit" icon @click="save()">
        <v-icon>fas fa-save</v-icon>
      </v-btn>
    </portal>
    <v-card>
      <v-card-text>
        <v-form ref="form" v-model="isValid" @submit.prevent="save()">
          <v-autocomplete id="editor_animals" ref="animalsField" v-model="selectedAnimals" label="Animal IDs" :autofocus="!isEditing" :items="animals_data" item-text="id" item-value="_id" :rules="[rules.required, rules.selectedAnimalsError]" clearable multiple chips :disabled="isEditing" @change="sortSelectedAnimals">
            <template v-slot:selection="data">
              <v-chip v-bind="data.attrs" :input-value="data.selected" :title="data.item.error || data.item.repeat" :color="data.item.error ? 'red' : data.item.repeat ? 'yellow' : ''" close @click="data.select" @click:close="removeAnimal(data.item)">
                {{ data.item.id }}
              </v-chip>
            </template>
          </v-autocomplete>
          <template v-if="hasRepeats">
            Repeats are shown in yellow.
          </template>
          <date-picker id="editor_dateOne" v-model="ai.dateOne" label="1st AI Date" :max="getDateToday()" :rules="[rules.required]" />
          <v-autocomplete id="editor_semenOne" ref="semenOneField" v-model="ai.semenOne" label="1st AI Semen" :items="semen_data" item-value="_id" :rules="[rules.required, rules.noPurchasedSemenLeft]" clearable>
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title v-text="data.item.breed" />
                <v-list-item-subtitle v-text="data.item.type == 'animal' ? data.item.id : 'BN ' + data.item.batchNo + ' - Boar ID ' + data.item.boarId" />
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <date-picker id="editor_dateTwo" ref="dateTwoField" v-model="ai.dateTwo" label="2nd AI Date" :min="ai.dateOne" :max="getDateToday()" :rules="[rules.aiTwoBlankOrFull, rules.aiDateTwoNotBeforeDateOne]" @input="$refs.form.validate()" />
          <v-autocomplete id="editor_semenTwo" ref="semenTwoField" v-model="ai.semenTwo" label="2nd AI Semen" :items="semen_data" item-value="_id" :rules="[rules.aiTwoBlankOrFull, rules.noPurchasedSemenLeft]" clearable @change="$refs.form.validate()">
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title v-text="data.item.breed" />
                <v-list-item-subtitle v-text="data.item.type == 'animal' ? data.item.id : 'BN ' + data.item.batchNo + ' - Boar ID ' + data.item.boarId" />
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-textarea id="editor_comments" v-model="ai.comments" label="Comments" />
          <v-btn type="submit" class="d-none" />
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import DatePicker from '@/components/DatePicker';
import Vue from 'vue';

export default {
  name: 'AIEditor',
  components: {
    DatePicker
  },
  data: vm => ({
    isValid: null,
    isSaving: false,
    editing: false,
    hasRepeats: false,
    hasSelectedAnimalErrors: false,
    selectedAnimals: vm.$route.query.animal ? [vm.$route.query.animal] : [],
    originalSemen: {
      semenOne: null,
      semenTwo: null
    },
    ai: {
      type: "breeding",
      dateOne: vm.getDateToday(),
      semenOne: null,
      dateTwo: null,
      semenTwo: null,
      comments: null
    },
    rules: {
      required: value => !vm.isEmptyProperty(value) || 'Required',
      selectedAnimalsError: () => !vm.hasSelectedAnimalErrors || 'Hover over animals highlighted in red for error details',
      aiDateTwoNotBeforeDateOne: value => (vm.isEmptyProperty(value) || vm.isEmptyProperty(vm.ai.dateOne) || (new Date(vm.ai.dateTwo).getTime() >= new Date(vm.ai.dateOne).getTime())) || '2nd AI Date cannot be before 1st AI Date',
      aiTwoBlankOrFull: value => (vm.isEmptyProperty(vm.ai.semenTwo) && vm.isEmptyProperty(vm.ai.dateTwo)) || !vm.isEmptyProperty(value) || 'Required when 2nd AI data is entered',
      noPurchasedSemenLeft: () => {
        if(vm.isEmptyProperty(vm.ai.semenOne) || vm.isEmptyProperty(vm.ai.semenTwo) || vm.ai.semenOne != vm.ai.semenTwo)
          return true;
        if(vm.isEditing && vm.ai.semenOne == vm.originalSemen.semenOne && vm.originalSemen.semenOne == vm.originalSemen.semenTwo)
          return true;
        let semenRecord = vm.semen_data.find(semen => semen.type == 'purchasedSemen' && semen._id == vm.ai.semenOne);
        return !semenRecord || semenRecord.remaining >= 2 || 'Purchased semen quantity not available'
      }
    }
  }),
  computed: {
    isEditing() {
      return !!this.$route.params.id;
    },
    animals_data() {
      return this.animals || [];
    },
    semen_data() {
      return (this.semen || []).map(record => {
        record.text = record.breed + ' - ' + (record.type == 'animal' ? record.id : 'BN ' + record.batchNo + ' - Boar ID ' + record.boarId);
        return record;
      });
    },
    ai_history_data() {
      return this.ai_history || [];
    },
    validate_selected_animals() {
      return this.ai_history_data && this.animals_data;
    },
    end_date() {
      let endDate = '';
      if(this.ai.dateOne) {
        endDate = new Date(this.ai.dateOne);
        endDate.setDate(endDate.getDate() + 114 + 28); // weaned 28 days after farrow, so 114+28
        // return endDate.toISOString().split('T')[0];
      }
      return endDate;
    }
  },
  watch: {
    existing_record() {
      if(!this.editing && this.existing_record) {
        this.ai = this.existing_record;
        this.selectedAnimals = [this.existing_record.id];
        this.originalSemen.semenOne = this.existing_record.semenOne;
        this.originalSemen.semenTwo = this.existing_record.semenTwo;
        this.editing = true;
      }
    },
    validate_selected_animals() {
      let hasError = false, hasRepeats = false;
      for(let animalId of this.selectedAnimals) {
        let animal = this.animals_data.find(animal => animal._id == animalId);
        let animalPastAI = this.ai_history_data.filter(ai => ai._id != this.ai._id && ai.id == animalId);
        if(animalPastAI.length) {
          let noScanResult = animalPastAI.find(ai => ai.scanResult == null);
          if(noScanResult && !this.ai._id) { // doesn't apply when editing
            Vue.set(animal, 'error', 'Existing AI record starting ' + new Date(noScanResult.dateOne).toLocaleDateString() + ' has no scan result');
            hasError = true;
            continue;
          }
          if(animalPastAI.find(ai => ai.dateOne == this.ai.dateOne)) {
            Vue.set(animal, 'error', 'Existing AI record with same 1st AI date');
            hasError = true;
            continue;
          }
          let latestRecord = animalPastAI[0];
          if(latestRecord.scanResult) {
            let latestStartDate = new Date(latestRecord.dateOne);
            if(this.ai.dateOne && latestStartDate >= new Date(this.ai.dateOne) && latestStartDate <= this.end_date) {
              Vue.set(animal, 'error', 'Existing positive AI record starting ' + latestStartDate.toLocaleDateString() + ' would overlap');
              hasError = true;
              continue;
            }
          }
          if(latestRecord.scanResult === false) {
            Vue.set(animal, 'repeat', 'This will be a repeat from ' + new Date(latestRecord.dateOne).toLocaleDateString());
            hasRepeats = true;
          }
        }
        Vue.set(animal, 'error', null);
      }
      this.hasRepeats = hasRepeats;
      if(this.hasSelectedAnimalErrors != hasError) {
        this.hasSelectedAnimalErrors = hasError;
        this.$refs.form.validate();
      }
    }
  },
  methods: {
    getDateToday() {
      return new Date().toISOString().slice(0, 10);
    },
    isEmptyProperty(value) {
      return value == null || value.toString().length == 0;
    },
    removeAnimal(item) {
      const index = this.selectedAnimals.indexOf(item._id);
      if (index >= 0) this.selectedAnimals.splice(index, 1);
    },
    sortSelectedAnimals() {
      this.selectedAnimals.sort((a, b) => {
        let idA = this.animals_data.find(animal => animal._id == a).id;
        let idB = this.animals_data.find(animal => animal._id == b).id;
        return idA.localeCompare(idB);
      });
    },
    save() {
      if(this.isSaving) return;
      if(!this.$refs.form.validate()) {
        let firstInvalidField = this.$refs.form.inputs.find(input => !input.valid)
        if(firstInvalidField) {
          firstInvalidField.focus();
          return;
        }
      }
      this.isSaving = true;
      let db = this.$pouch.getDB();
      let method = this.isEditing ? db.put : db.post;
      Promise.all(this.selectedAnimals.map(animal_id => {
        let record = Object.assign({}, this.ai, {id: animal_id});
        return method(record).then(console.log).catch(console.error);
      })).then(() => {
        this.$store.dispatch('returnToLastRoute', '/ai');
      });
    }
  },
  pouch: {
    animals() {
      return {
        database: 'pig_manager',
        selector: this.isEditing ? {_id: this.existing_record ? this.existing_record.id : null} : {
          type: 'animal',
          animalType: 'Sow/gilt',
          $or: [
            {dateCulled: null},
            {dateCulled: {$exists: false}}
          ]
        },
        sort: [{id: 'asc'}]
      }
    },
    ai_history() {
      return {
        database: 'pig_manager',
        selector: {
          type: 'breeding',
          id: {$in: this.selectedAnimals},
          dateOne: {$lte: this.end_date}
        },
        sort: [{dateOne: 'desc'}]
      }
    },
    semen() {
      return {
        database: 'pig_manager',
        selector: {
          $or: [
            {type: 'animal', animalType: 'Boar'},
            {type: 'purchasedSemen', remaining: {$gt: 0}},
            {_id: this.ai.semenOne},
            {_id: this.ai.semenTwo}
          ]
        }
      }
    },
    existing_record() {
      return {
        database: 'pig_manager',
        selector: {
          type: 'breeding',
          _id: this.$route.params.id
        },
        first: true,
        disabled: !this.isEditing
      }
    }
  }
}
</script>

<style scoped>
.row {
  margin-top: 0;
  margin-bottom: 0;
}

.col {
  padding-top: 0;
  padding-bottom: 0;
}
</style>