<template>
  <div>
    <v-snackbar v-model="reauthPrompt" timeout="-1">
      Sync requires your password
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="reauthPrompt = false; $router.push({path: '/login', query: {redirect: $route.fullPath}})">
          Fix
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: 'Reauth',
  data: () => ({
    reauthPrompt: false
  }),
  computed: {
    needsReauth() {
      return this.$store.state.needsReauth && this.$route.name != 'Log In';
    }
  },
  watch: {
    needsReauth: {
      immediate: true,
      handler(newVal) {
        this.reauthPrompt = newVal;
      }
    }
  }
}
</script>