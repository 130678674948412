import Vue from "vue";

let todoTypesTemplate = {
  aiOneDue: {
    name: 'AI pigs',
    type: 'ai',
    day: 1,
    week: 2,
    createsNewRecord: true
  },
  aiTwoDue: {
    name: 'Second AI pigs',
    type: 'ai',
    day: 2,
    week: 2
  },
  scanDue: {
    name: 'Sows/gilts due to scan',
    type: 'scan',
    day: 2,
    week: 0
  },
  farrowDue: {
    name: 'Sows due to farrow',
    type: 'farrow',
    day: 3,
    week: 0
  },
  sowPreWeanVaccine: {
    name: 'Vaccinate next batch of sows due to wean',
    type: 'medicine',
    target: 'mother',
    products: ['Ery+Parvo+Lepto'],
    reason: 'Routine before weaning',
    day: 3,
    week: 0
  },
  sowGiltPreFarrowVaccine: {
    name: 'Vaccinate sows/gilts due to farrow in 3 weeks',
    type: 'medicine',
    target: 'mother',
    products: ['Gletvax 6'],
    reason: 'Routine before farrow',
    day: 3,
    week: 0
  },
  giltPreFarrowVaccine: {
    name: 'Vaccinate gilts due to farrow in 6 weeks',
    type: 'medicine',
    target: 'mother',
    products: ['Gletvax 6'],
    reason: 'Routine before farrow (gilts)',
    day: 3,
    week: 0
  },
  weanDue: {
    name: 'Wean pigs',
    type: 'wean',
    day: 3,
    week: 1
  },
  pigletPreWeanVaccine: {
    name: 'Administer piglet pre-wean vaccines',
    type: 'medicine',
    target: 'piglet',
    products: ['Circoflex', 'Hyogen'],
    reason: 'Routine at weaning',
    day: 4,
    week: 0
  },
  crateDue: {
    name: 'Sows enter farrow crates',
    type: 'crate',
    day: 6,
    week: 2
  }
}

function getWeekType() {
  // the hardcoded date is known to be Monday on a farrowing week.
  const diffWeeks = Math.floor((new Date() - new Date('2022-02-28')) / (86400000 * 7)); // milliseconds in a week
  return diffWeeks % 3; // 0 = farrow, 1 = wean, 2 = AI
}

function getWeekTypeName() {
  return ['Farrow', 'Wean', 'AI'][getWeekType()];
}

function isTypeVisible(type, hideEmptyAllWeeks) {
  return (type.allWeeks && (type.animals.length > 0 || !hideEmptyAllWeeks)) || type.week == getWeekType();
}

function getDayOfWeek(index) {
  let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  return days[index];
}

function getDateToday() {
  return new Date().toISOString().slice(0, 10);
}

function getWeekStartDate(week) {
  // gets Monday at start of week (week day 1)
  // adapted from: https://stackoverflow.com/a/46544455/1476989
  let date = !week || typeof week == 'number' ? new Date() : typeof week == 'string' ? new Date(week) : week;
  date.setDate(date.getDate() - ((date.getDay() + 6) % 7) + (week && typeof week == 'number' ? week : 0) * 7);
  return date.toISOString().slice(0, 10);
}

function getNextEventDate(event) {
  let batchStartDate = new Date(getWeekStartDate(-getWeekType()));
  batchStartDate.setDate(batchStartDate.getDate() + 7 * event.week + (event.day - 1));
  if(batchStartDate < new Date()) batchStartDate.setDate(batchStartDate.getDate() + 21);
  return batchStartDate.toISOString().slice(0, 10);
}

function getLastDayBeforeNextEventDate(event) {
  let eventDate = new Date(getNextEventDate(event));
  eventDate.setDate(eventDate.getDate() - 1);
  return eventDate.toISOString().slice(0, 10);
}

async function getTodos() {
  let earliestCutoffDate = getWeekStartDate(-12);
  let todoTypes = JSON.parse(JSON.stringify(todoTypesTemplate));
  let todoTasksDue = (await Vue.prototype.$pouch.getDB().query('app/todo-tasks-due', {include_docs: true, startkey: earliestCutoffDate})).rows;
  let allAnimalIds = [...new Set(todoTasksDue.map(todo => todo.doc._id)), 'Piglets'];
  let weekStartDate = getWeekStartDate();
  let animalStatuses = (await Vue.prototype.$pouch.getDB().query('app/animal-status', {group: true, keys: allAnimalIds})).rows;
  let todoTasksCompleted = (await Vue.prototype.$pouch.getDB().query('app/todo-tasks-completed', {keys: allAnimalIds})).rows;
  let allBreedingIds = [];
  let allMedicineIds = [];

  for(let type of Object.values(todoTypes)) {
    type.animals = [];
    type.weekDay = getDayOfWeek(type.day);
  }
  for(let task of todoTasksDue) {
    if(task.value.type in todoTypes) { 
      let type = todoTypes[task.value.type];
      // tasks show up on the list if:
      // - task is due this week (or any date before next event)
      // - task was due before this week but was incomplete until this week
      // - hard cutoff of 12 weeks ago
      // - pre-farrowing injection shows early i.e. anything that will occur before the next time this task is due
      // - post-farrowing injections don't appear until next time the task is due
      let animalStatus = animalStatuses.find(animal => animal.key == task.doc._id);
      let targetsPiglets = type.target == 'piglet';
      if((animalStatus.value.status != 'Moved off' || task.value.date > task.key || targetsPiglets) && isTypeVisible(type) && task.key < getNextEventDate(type)) {
        let completedTasks = todoTasksCompleted.filter(completed =>
          completed.key == (targetsPiglets ? "Piglets" : task.doc._id) && // associated with task's animal
          completed.value.type == task.value.type && // same task type
          (
            task.id == completed.id || // completed record is the same as the record which triggered the task
            completed.value.date >= getWeekStartDate(task.key) // completed record is after the task due date (used for first aiOneDue and medicine todos)
          )
        );
        // piglet tasks are only shown for the week the task is due on
        let isCompleted = !!completedTasks.length;
        if((!targetsPiglets || getWeekStartDate(task.key) == weekStartDate) && (!isCompleted || completedTasks.find(completedTask => completedTask.value.date >= weekStartDate))) {
          type.animals.push({
            id: task.doc.id,
            status: isCompleted ? completedTasks.find(completedTask => completedTask.value.success) != null : null,
            task: task,
            completed: completedTasks
          });
          if(type.createsNewRecord) allBreedingIds.push(...completedTasks.map(completed => completed.id));
          else allBreedingIds.push(task.id);

          if(type.type == 'medicine') allMedicineIds.push(...completedTasks.map(completed => completed.id));
        }
      }
    }
  }
  let breeding = allBreedingIds.length > 0 ? (await Vue.prototype.$pouch.getDB().allDocs({include_docs: true, keys: [...new Set(allBreedingIds)]})).rows.map(row => row.doc) : [];
  let medicine = allMedicineIds.length > 0 ? (await Vue.prototype.$pouch.getDB().allDocs({include_docs: true, keys: [...new Set(allMedicineIds)]})).rows.map(row => row.doc) : [];
  let allProductIds = [...new Set(medicine.map(item => item.product))];
  let products = allProductIds.length > 0 ? (await Vue.prototype.$pouch.getDB().allDocs({include_docs: true, keys: allProductIds})).rows.map(row => row.doc) : [];
  for(let type of Object.values(todoTypes)) {
    if(type.createsNewRecord) {
      // e.g. for AI 1, load existing breeding records, based only on a completed task, and only if available (since we are creating a new Breeding record)
      for(let [, animal] of type.animals.entries()) {
        animal.breeding = breeding.find(breeding => animal.completed.find(completedTask => completedTask.value.recordId == breeding._id) != null);
      }
    } else {
      // load the existing breeding records identified by the task itself (since we are modifying an existing Breeding record)
      for(let [, animal] of type.animals.entries()) {
        animal.breeding = breeding.find(breeding => breeding._id == animal.task.id);
      }
    }

    if(type.type == 'medicine') {
      // calculate task quantities for each medicine line, while also working out overall completion status if targeting piglets
      let targetsPiglets = type.target == 'piglet';
      if(targetsPiglets) {
        let allBreedingIdsForType = [...new Set(type.animals.map(animal => animal.breeding._id))];
        type.farrowStillAlive = (await Vue.prototype.$pouch.getDB().query('app/farrow-alive', {group: true, keys: allBreedingIdsForType})).rows;
        type.expectedTotal = type.farrowStillAlive.reduce((subtotal, breeding) => subtotal + breeding.value, 0);
      }

      let complete = true;
      type.productData = [];
      for(let product of type.products) {
        let productData = {
          name: product,
          records: !type.animals.length ? [] : medicine.map(medicine => {
            medicine.productData = products.find(product => product._id == medicine.product);
            return medicine;
          }).filter(medicine => medicine.productData.product == product)
        };
        productData.total = productData.records.reduce((subtotal, medicine) => subtotal + medicine.animalQuantity, 0);
        if(targetsPiglets && productData.total < type.expectedTotal) complete = false;
        type.productData.push(productData);
      }

      // Work out completion status for piglet todos
      if(targetsPiglets) {
        for(let animal of type.animals) {
          let stillAlive = type.farrowStillAlive.find(item => item.key == animal.task.id);
          animal.numberStillAlive = stillAlive ? stillAlive.value : 0;
          if(complete) animal.status = true;
        }
      }
    }

    type.visible = isTypeVisible(type, true);
    type.animals.sort((a, b) => a.id.localeCompare(b.id));
  }
  return todoTypes;
}

export default { 
  getTodos,
  getWeekType,
  getWeekTypeName,
  isTypeVisible,
  getDayOfWeek,
  getDateToday,
  getWeekStartDate,
  getNextEventDate,
  getLastDayBeforeNextEventDate
}