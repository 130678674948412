<template>
  <v-menu ref="menu" v-model="isOpen" :close-on-content-click="false" :open-on-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field :id="id" ref="value" :value="date ? new Date(date).toLocaleDateString() : ''" :label="label" prepend-icon="far fa-calendar" readonly :clearable="clearable" v-bind="attrs" :rules="rules" @mousedown="recordMouseDown()" @focus="open(false)" @click="open(true)" @click:clear="handleClear" v-on="on" />
    </template>
    <v-date-picker v-model="date" v-bind="$attrs" @input="isOpen = false" />
  </v-menu>
</template>

<script>
export default {
  name: 'DatePicker',
  props: {
    label: {type: String, default: null},
    value: {type: String, default: ''},
    rules: {type: Array, default() {return []}},
    id: {type: String, default: null},
    clearable: {type: Boolean, default: true}
  },
  data: vm => ({
    isOpen: false,
    lastMouseDown: null,
    date: vm.value
  }),
  watch: {
    value(newVal) {
      this.date = newVal;
    },
    date() {
      this.$emit('input', this.date);
    }
  },
  methods: {
    recordMouseDown() {
      this.lastMouseDown = new Date();
    },
    open(isMouse) {
      if(isMouse || !this.lastMouseDown || new Date().getTime() > this.lastMouseDown.getTime() + 250) {
        this.isOpen = true;
      }
    },
    handleClear() {
      this.date = null;
    },
    validate() {
      this.$refs.value.validate();
    }
  }
}
</script>
