<template>
  <v-container>
    <portal to="app-bar-icons">
      <v-btn icon to="/discarded-medicine/add">
        <v-icon>fas fa-plus-circle</v-icon>
      </v-btn>
    </portal>
    <v-data-table :headers="headers" :items="discarded_medicine_data" item-key="_id" class="elevation-2" mobile-breakpoint="0" @click:row="editRecord" @contextmenu:row.prevent="openContextMenu" />
    <v-menu v-model="contextMenu.visible" absolute :position-x="contextMenu.x" :position-y="contextMenu.y" offset-y>
      <v-list>
        <v-list-item v-for="item in contextMenu.items" :key="item.text" :ripple="item.color ? {class: item.color + '--text'} : true" @click="item.action">
          <v-list-item-icon><v-icon :color="item.color" v-text="item.icon" /></v-list-item-icon>
          <v-list-item-title :class="item.color ? item.color + '--text' : ''" v-text="item.text" />
        </v-list-item>
      </v-list>
    </v-menu>
  </v-container>
</template>

<script>
export default {
  name: 'DiscardedMedicine',
  data: vm => ({
    headers: [
      {text: 'Product', value: 'product'},
      {text: 'Batch No', value: 'batchNo'},
      {text: 'Expiry Date', value: 'expiryDateFormatted'},
      {text: 'Date Discarded', value: 'dateFormatted'},
      {text: 'Qty Discarded', value: 'quantityDiscardedFormatted'}
    ],
    contextMenu: {
      visible: false,
      x: 0,
      y: 0,
      recordId: null,
      items: [
        {
          icon: 'fa-pencil-alt',
          text: 'Edit',
          action() {
            vm.$router.push('/discarded-medicine/' + vm.contextMenu.recordId);
          }
        },
        {
          icon: 'fa-trash',
          text: 'Delete',
          color: 'red',
          action() {
            vm.$router.push('/discarded-medicine/' + vm.contextMenu.recordId + '/delete');
          }
        }
      ]
    }
  }),
  computed: {
    discarded_medicine_data() {
      return (this.discarded_medicine || []).map(row => {
        row.quantityDiscardedFormatted = row.quantityDiscarded + row.measurementUnit;
        // format date for user's locale
        row.expiryDateFormatted = new Date(row.expiryDate).toLocaleDateString();
        row.dateFormatted = new Date(row.date).toLocaleDateString();
        return row;
      })
    }
  },
  methods: {
    getDateToday() {
      return new Date().toISOString().slice(0, 10);
    },
    editRecord(record) {
      this.$router.push('/discarded-medicine/' + record._id);
    },
    openContextMenu(event, row) {
      this.contextMenu.visible = true;
      this.contextMenu.x = event.x;
      this.contextMenu.y = event.y;
      this.contextMenu.recordId = row.item._id;
    }
  },
  pouch: {
    discarded_medicine() {
      return {
        database: 'pig_manager',
        sort: [{date: "desc"}],
        selector: {type: 'discardedMedicine'}
      }
    }
  }
}
</script>

<style>
tbody tr {
  cursor: pointer;
}
</style>