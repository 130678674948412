<template>
  <v-container>
    <portal to="app-bar-icons">
      <v-btn icon :to="'/herd/' + animal_id">
        <v-icon>fas fa-pencil-alt</v-icon>
      </v-btn>
    </portal>
    <v-card v-if="animal_data">
      <v-card-title>
        <v-icon large left color="black">fas fa-hashtag</v-icon>
        <b>{{ animal_data.id }}</b>
      </v-card-title>
      <v-card-text>
        <div class="animal-status">
          <v-icon>far fa-calendar</v-icon>
          <div>Selected for breeding on {{ new Date(animal_data.dateSelected).toLocaleDateString() }}</div>
          
          <v-icon>fas fa-redo</v-icon>
          <div>Parity {{ animal_status.parity }}</div>

          <v-icon>fas fa-map-marker-alt</v-icon>
          <div>{{ animal_status.status }} {{ animal_status.status != '-' ? '(' + animal_status.duration + ' ago - ' + animal_status.dateFormatted + ')' : '' }}</div>
        </div>
      </v-card-text>
    </v-card>
    <v-data-table :headers="breeding_headers" :items="breeding_data" item-key="_id" class="mt-8 elevation-2" mobile-breakpoint="0" disable-pagination hide-default-footer @click:row="editRecord" @contextmenu:row.prevent="openContextMenu">
      <template v-slot:top>
        <v-card-text class="pb-0 pt-2 d-flex align-center font-weight-bold">
          AI &amp; Farrowing
          <v-spacer />
          <v-btn icon @click="$router.push('/ai/add?animal=' + animal_id)">
            <v-icon>fa-plus</v-icon>
          </v-btn>
        </v-card-text>
      </template>
    </v-data-table>
    <v-data-table :headers="medicine_headers" :items="medicine_data" item-key="_id" class="mt-8 elevation-2" mobile-breakpoint="0" disable-pagination hide-default-footer @click:row="editRecord" @contextmenu:row.prevent="openContextMenu">
      <template v-slot:top>
        <v-card-text class="pb-0 pt-2 d-flex align-center font-weight-bold">
          Medicine
          <v-spacer />
          <v-btn icon @click="$router.push('/medicine/add?animals=' + animal_id)">
            <v-icon>fa-plus</v-icon>
          </v-btn>
        </v-card-text>
      </template>
    </v-data-table>
    <v-card v-if="animal_data" class="mt-8">
      <v-card-text>
        <div class="font-weight-bold black--text">Relatives</div>
        <div class="relatives-tree">
          <v-btn v-if="animal_data.damId && parent_animal" color="yellow" class="animal-id parent-animal" :to="'/animal/' + parent_animal._id" v-text="parent_animal.id" />
          <v-btn color="orange" class="animal-id font-weight-bold" v-text="animal_data.id" />
          <div v-if="offspring && offspring.length" class="offspring">
            <v-btn v-for="offspring_animal in offspring" :key="offspring_animal._id" color="yellow" class="animal-id" :to="'/animal/' + offspring_animal._id" v-text="offspring_animal.id" />
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-menu v-model="contextMenu.visible" absolute :position-x="contextMenu.x" :position-y="contextMenu.y" offset-y>
      <v-list>
        <v-list-item v-for="item in contextMenu.items" :key="item.text" :ripple="item.color ? {class: item.color + '--text'} : true" @click="item.action">
          <v-list-item-icon><v-icon :color="item.color" v-text="item.icon" /></v-list-item-icon>
          <v-list-item-title :class="item.color ? item.color + '--text' : ''" v-text="item.text" />
        </v-list-item>
      </v-list>
    </v-menu>
  </v-container>
</template>

<script>
export default {
  name: 'Animal',
  data: vm => ({
    animal_id: null,
    animal_status: {},
    breeding_headers: [
      {text: '#', value: 'index'},
      {text: '1st AI Date', value: 'dateOneFormatted'},
      {text: '2nd AI Date', value: 'dateTwoFormatted'},
      {text: 'Scan Date', value: 'scanDateFormatted'},
      {text: 'Scan Result', value: 'scanResultFormatted'},
      {text: 'Farrow Date', value: 'farrowDateFormatted'},
      {text: 'Weaned/Alive/Total', value: 'stats'}
    ],
    medicine_headers: [
      {text: 'Product', value: 'productName'},
      {text: 'Start Date', value: 'startDateFormatted'},
      {text: 'By Whom', value: 'modifiedBy'},
      {text: 'Treatment Duration', value: 'duration'},
      {text: 'Clearance Date', value: 'clearanceDateFormatted'},
      {text: 'Total Qty', value: 'totalQuantityUsedFormatted'},
      {text: 'Reason', value: 'reason'}
    ],
    contextMenu: {
      visible: false,
      x: 0,
      y: 0,
      record: null,
      items: [
        {
          icon: 'fa-pencil-alt',
          text: 'Edit',
          action() {
            vm.editRecord(vm.contextMenu.record);
          }
        },
        {
          icon: 'fa-trash',
          text: 'Delete',
          color: 'red',
          action() {
            vm.$router.push('/' + vm.getRecordPath(vm.contextMenu.record) + '/' + vm.contextMenu.record._id + '/delete');
          }
        }
      ]
    }
  }),
  computed: {
    animal_data() {
      return this.animal || {};
    },
    breeding_data() {
      let index = 0;
      return (this.breeding || []).map(row => {
        row.index = row.scanResult !== false ? ++index : '';
        row.dateOneFormatted = new Date(row.dateOne).toLocaleDateString();
        row.dateTwoFormatted = row.dateTwo ? new Date(row.dateTwo).toLocaleDateString() : '';
        row.scanDateFormatted = row.scanDate ? new Date(row.scanDate).toLocaleDateString() : '';
        row.scanResultFormatted = row.scanResult == null ? '-' : row.scanResult ? 'Positive' : 'Negative';
        row.farrowDateFormatted = row.farrowDate ? new Date(row.farrowDate).toLocaleDateString() : '';
        row.stats = (row.weaned ? row.weaned + '/' : '') + (row.alive ? row.alive + '/' + (Number(row.alive) + Number(row.stillborn) + Number(row.mummified)) : '');
        return row;
      });
    },
    products_data() {
      return this.products || [];
    },
    product_ids() {
      return [...new Set((this.medicine || []).map(row => row.product))];
    },
    medicine_data() {
      return (this.medicine || []).map(row => {
        let product = this.products_data.find(product => product._id == row.product);
        row.productName = product ? product.product : '';
        row.startDateFormatted = new Date(row.startDate).toLocaleDateString();
        let duration = Math.ceil((new Date(row.endDate) - new Date(row.startDate) + 1) / 86400000); // milliseconds in a day
        row.duration = duration + (duration == 1 ? ' day' : ' days');
        row.clearanceDateFormatted = new Date(row.clearanceDate).toLocaleDateString();
        row.totalQuantityUsedFormatted = (row.doseAmount * duration) + (product ? product.measurementUnit : 'ml');
        return row;
      });
    }
  },
  watch: {
    '$route': {
      immediate: true,
      async handler() {
        if(this.$route.meta.modal) return;
        this.animal_id = this.$route.params.id;
        this.animal_status = (await this.$pouch.getDB().query('app/animal-status', {group:true, key: this.animal_id})).rows[0].value;

        let barrenCutoffDate = new Date();
        barrenCutoffDate.setDate(barrenCutoffDate.getDate() - 9); // marked as barren on 10th day if still awaiting AI (would mean they haven't come in heat)
        barrenCutoffDate = barrenCutoffDate.toISOString().split('T')[0];
        if(this.animal_status.status == 'Awaiting AI' && this.animal_status.parity > 0 && this.animal_status.date < barrenCutoffDate) {
          this.animal_status.status = 'Barren';
        }
        let statusDate = new Date(this.animal_status.date);
        let duration = Math.ceil((new Date() - statusDate) / 86400000) - 1; // days
        this.animal_status.duration = duration + (duration == 1 ? ' day' : ' days');
        this.animal_status.dateFormatted = statusDate.toLocaleDateString();
      }
    }
  },
  methods: {
    getRecordPath(record) {
      return record.type == 'breeding' ? 'ai' : record.type;
    },
    editRecord(record) {
      this.$router.push('/' + this.getRecordPath(record) + '/' + record._id);
    },
    openContextMenu(event, row) {
      this.contextMenu.visible = true;
      this.contextMenu.x = event.x;
      this.contextMenu.y = event.y;
      this.contextMenu.record = row.item;
    }
  },
  pouch: {
    animal() {
      return {
        database: 'pig_manager',
        selector: {_id: this.animal_id, type: 'animal'},
        first: true
      }
    },
    parent_animal() {
      return {
        database: 'pig_manager',
        selector: {_id: this.animal_data.damId, type: 'animal'},
        first: true,
        disabled: !this.animal_data.damId
      }
    },
    offspring() {
      return {
        database: 'pig_manager',
        selector: {damId: this.animal_id, type: 'animal'}
      }
    },
    breeding() {
      return {
        database: 'pig_manager',
        selector: {id: this.animal_id, type: 'breeding'},
        sort: [{dateOne: 'asc'}]
      }
    },
    products() {
      return {
        database: 'pig_manager',
        selector: {type: 'purchasedMedicine', _id: {$in: this.product_ids}}
      }
    },
    medicine() {
      return {
        database: 'pig_manager',
        selector: {type: 'medicine', animals: {$elemMatch: {$in: [this.animal_id]}}},
        sort: [{startDate: 'desc'}]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
tbody tr {
  cursor: pointer;
}

.animal-status {
  display: grid;
  align-items: center;
  gap: 10px;
  grid-template-columns: max-content auto;
  color: black;
}

.relatives-tree {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  gap: 40px;
  padding: 15px;

  .animal-id::after {
    content: "";
    border-width: 2px;
    border-color: black;
    position: absolute;
    height: 20px;
    box-shadow: none;
  }

  > .animal-id:not(:last-child)::after {
    bottom: -20px;
    border-left-style: solid;
  }

  .animal-id.parent-animal::after {
    height: 40px;
    bottom: -40px;
  }

  .offspring {
    display: flex;
    gap: 20px;

    > .animal-id::after {
      top: -20px;
      height: 20px;
      border-left-style: solid;
    }

    > .animal-id:not(:last-child)::after {
      left: 24px;
      width: 70px;
      border-top-style: solid;
    }
  }
}

.animal-id.v-btn {
  color: black;
  text-align: center;
  padding: 5px;
  min-width: 50px;
  height: 30px;
  position: relative;
}
</style>