<template>
  <component :is="$route.meta.component ? $route.meta.component : $store.state.lastComponent" v-bind="$attrs" />
</template>

<script>
// This component is used to allow determining whether this route has an actual component associated with it,
// and if not, it will fall back to the last component to be shown on a previous route.
// This is extremely useful for modal routes such as the login page, which will keep the context of the previous page.

export default {
  name: "ComponentProxy",
};
</script>