import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@fortawesome/fontawesome-free/css/all.css';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'fa'
  },
  theme: {
    themes: {
      light: {
        primary: "#0062FF", // #E53935
        secondary: "#0062FF", // #FFCDD2
        accent: "#0062FF" // #3F51B5
      }
    }
  }
});