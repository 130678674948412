<template>
  <v-container>
    <portal to="app-bar-icons">
      <v-btn icon to="/ai/add">
        <v-icon>fas fa-plus-circle</v-icon>
      </v-btn>
    </portal>
    <v-data-table :headers="headers" :items="ai_data" item-key="_id" :item-class="(item) => item.scanResult === false ? 'repeat-row' : null" class="elevation-2" mobile-breakpoint="0" @click:row="editRecord" @contextmenu:row.prevent="openContextMenu">
      <template v-slot:item.scanResultFormatted="props">
        <div @click.stop="$router.push('/ai/scan/' + props.item._id)" v-text="props.item.scanResultFormatted" />
      </template>
    </v-data-table>
    <v-menu v-model="contextMenu.visible" absolute :position-x="contextMenu.x" :position-y="contextMenu.y" offset-y>
      <v-list>
        <v-list-item v-for="item in contextMenu.items" :key="item.text" :ripple="item.color ? {class: item.color + '--text'} : true" @click="item.action">
          <v-list-item-icon><v-icon :color="item.color" v-text="item.icon" /></v-list-item-icon>
          <v-list-item-title :class="item.color ? item.color + '--text' : ''" v-text="item.text" />
        </v-list-item>
      </v-list>
    </v-menu>
  </v-container>
</template>

<script>
export default {
  name: 'AI',
  data: vm => ({
    headers: [
      {text: 'Animal ID', value: 'displayId'},
      {text: '1st AI Date', value: 'dateOneFormatted'},
      {text: 'Breed', value: 'breedOne'},
      {text: 'Batch No', value: 'batchOne'},
      {text: 'Boar ID', value: 'boarOne'},
      {text: '2nd AI Date', value: 'dateTwoFormatted'},
      {text: 'Breed', value: 'breedTwo'},
      {text: 'Batch No', value: 'batchTwo'},
      {text: 'Boar ID', value: 'boarTwo'},
      {text: 'Scan Result', value: 'scanResultFormatted'},
      {text: 'Vaccine Date', value: 'vaccineDate'},
      {text: 'Farrow Room Date', value: 'farrowRoomDate'},
      {text: 'Farrow Due Date', value: 'farrowDueDate'},
      {text: 'Comments', value: 'comments'}
    ],
    contextMenu: {
      visible: false,
      x: 0,
      y: 0,
      recordId: null,
      items: [
        {
          icon: 'fa-pencil-alt',
          text: 'Edit',
          action() {
            vm.$router.push('/ai/' + vm.contextMenu.recordId);
          }
        },
        {
          icon: 'fa-trash',
          text: 'Delete',
          color: 'red',
          action() {
            vm.$router.push('/ai/' + vm.contextMenu.recordId + '/delete');
          }
        }
      ]
    }
  }),
  computed: {
    semen_data() {
      return this.semen || [];
    },
    animals_data() {
      return this.animals || [];
    },
    ai_data() {
      return (this.ai || []).map(row => {
        // format data
        let animal = this.animals_data.find(animal => animal._id == row.id);
        if(animal) row.displayId = animal.id;

        let semenOne = this.semen_data.find(record => record._id == row.semenOne);
        if(semenOne) {
          let isAnimal = semenOne.type == 'animal';
          row.breedOne = semenOne.breed;
          row.batchOne = isAnimal ? '-' : semenOne.batchNo;
          row.boarOne = isAnimal ? semenOne.id : semenOne.boarId;
        }

        let semenTwo = this.semen_data.find(record => record._id == row.semenTwo);
        if(semenTwo) {
          let isAnimal = semenTwo.type == 'animal';
          row.breedTwo = semenTwo.breed;
          row.batchTwo = isAnimal ? '-' : semenTwo.batchNo;
          row.boarTwo = isAnimal ? semenTwo.id : semenTwo.boarId;
        }

        row.scanResultFormatted = row.scanResult == null ? '-' : row.scanResult ? 'Positive' : 'Negative';
        if(row.scanResult === true) {
          row.vaccineDate = this.getDaysBeforeDueDate(row, 21).toLocaleDateString();
          row.farrowRoomDate = this.getDaysBeforeDueDate(row, 4).toLocaleDateString();
          row.farrowDueDate = this.getDaysBeforeDueDate(row, 0).toLocaleDateString();
        }
        // format dates for user's locale
        if(/[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(row.dateOne)) {
          row.dateOneFormatted = new Date(row.dateOne).toLocaleDateString();
        }
        if(/[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(row.dateTwo)) {
          row.dateTwoFormatted = new Date(row.dateTwo).toLocaleDateString();
        }
        return row;
      })
    }
  },
  methods: {
    editRecord(record) {
      this.$router.push('/ai/' + record._id);
    },
    openContextMenu(event, row) {
      this.contextMenu.visible = true;
      this.contextMenu.x = event.x;
      this.contextMenu.y = event.y;
      this.contextMenu.recordId = row.item._id;
    },
    getDaysBeforeDueDate(record, daysBeforeDue) {
      // x days before due date of 114 day gestation
      let date = new Date(record.dateOne);
      date.setDate(date.getDate() + 114 - daysBeforeDue);
      return date;
    }
  },
  pouch: {
    ai() {
      return {
        database: 'pig_manager',
        sort: [{dateOne: "desc"}],
        selector: {type: 'breeding'}
      }
    },
    animals() {
      return {
        database: 'pig_manager',
        selector: {
          type: 'animal',
          animalType: 'Sow/gilt'
        }
      }
    },
    semen() {
      return {
        database: 'pig_manager',
        selector: {
          $or: [
            {type: 'animal', animalType: 'Boar'},
            {type: 'purchasedSemen'}
          ]
        }
      }
    }
  }
}
</script>

<style>
.repeat-row {
  color: red;
  text-decoration: line-through;
}

tbody tr {
  cursor: pointer;
}
</style>