<template>
  <v-container>
    <portal to="app-bar-icons">
      <v-btn icon to="/purchased-medicine/add">
        <v-icon>fas fa-plus-circle</v-icon>
      </v-btn>
    </portal>
    <v-data-table :headers="headers" :items="purchased_medicine_data" item-key="_id" class="elevation-2" mobile-breakpoint="0" @click:row="editRecord" @contextmenu:row.prevent="openContextMenu" />
    <v-menu v-model="contextMenu.visible" absolute :position-x="contextMenu.x" :position-y="contextMenu.y" offset-y>
      <v-list>
        <v-list-item v-for="item in contextMenu.items" :key="item.text" :ripple="item.color ? {class: item.color + '--text'} : true" @click="item.action">
          <v-list-item-icon><v-icon :color="item.color" v-text="item.icon" /></v-list-item-icon>
          <v-list-item-title :class="item.color ? item.color + '--text' : ''" v-text="item.text" />
        </v-list-item>
      </v-list>
    </v-menu>
    <record-printer name="Purchased Medicine" :structure="headers" :data="purchased_medicine_data" start-date-key="purchaseDate" end-date-key="purchaseDate" />
  </v-container>
</template>

<script>
import RecordPrinter from '@/components/RecordPrinter';

export default {
  name: 'PurchasedMedicine',
  components: { RecordPrinter },
  data: vm => ({
    headers: [
      {text: 'Purchase Date', value: 'purchaseDateFormatted'},
      {text: 'Product', value: 'product'},
      {text: 'Batch No', value: 'batchNo'},
      {text: 'Expiry Date', value: 'expiryDateFormatted'},
      {text: 'Withdrawal Period', value: 'withdrawalPeriodFormatted'},
      {text: 'Total Qty', value: 'totalQuantityFormatted'},
      {text: 'Qty Left', value: 'remainingQuantityFormatted'},
      {text: 'Status', value: 'status'}
    ],
    contextMenu: {
      visible: false,
      x: 0,
      y: 0,
      recordId: null,
      items: [
        {
          icon: 'fa-copy',
          text: 'Copy to Discarded',
          action() {
            vm.$router.push('/discarded-medicine/add?copy=' + vm.contextMenu.recordId);
          }
        },
        {
          icon: 'fa-pencil-alt',
          text: 'Edit',
          action() {
            vm.$router.push('/purchased-medicine/' + vm.contextMenu.recordId);
          }
        },
        {
          icon: 'fa-trash',
          text: 'Delete',
          color: 'red',
          action() {
            vm.$router.push('/purchased-medicine/' + vm.contextMenu.recordId + '/delete');
          }
        }
      ]
    }
  }),
  computed: {
    suppliers_data() {
      return this.suppliers || [];
    },
    purchased_medicine_data() {
      let today = this.getDateToday();
      return (this.purchased_medicine || []).map(row => {
        row.withdrawalPeriodFormatted = row.withdrawalPeriod + (row.withdrawalPeriod == 1 ? ' day' : ' days');
        row.totalQuantityFormatted = row.totalQuantity + row.measurementUnit;
        row.remainingQuantityFormatted = row.remainingQuantity + row.measurementUnit;
        row.status = row.remainingQuantity == 0 ? 'Empty' : today > row.expiryDate ? 'Expired' : 'Available';
        // format date for user's locale
        row.purchaseDateFormatted = new Date(row.purchaseDate).toLocaleDateString();
        row.expiryDateFormatted = new Date(row.expiryDate).toLocaleDateString();
        return row;
      })
    }
  },
  methods: {
    getDateToday() {
      return new Date().toISOString().slice(0, 10);
    },
    editRecord(record) {
      this.$router.push('/purchased-medicine/' + record._id);
    },
    openContextMenu(event, row) {
      this.contextMenu.visible = true;
      this.contextMenu.x = event.x;
      this.contextMenu.y = event.y;
      this.contextMenu.recordId = row.item._id;
    }
  },
  pouch: {
    purchased_medicine() {
      return {
        database: 'pig_manager',
        sort: [{purchaseDate: "desc"}],
        selector: {type: 'purchasedMedicine'}
      }
    }
  }
}
</script>

<style>
tbody tr {
  cursor: pointer;
}
</style>