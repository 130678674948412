<template>
  <v-container>
    <portal to="app-bar-icons">
      <v-btn type="submit" icon @click="save()">
        <v-icon>fas fa-save</v-icon>
      </v-btn>
    </portal>
    <v-card>
      <v-card-text>
        <v-form ref="form" v-model="isValid" @submit.prevent="save()">
          <v-row>
            <v-col :cols="$vuetify.breakpoint.smAndUp ? 8 : 12">
              <v-combobox id="editor_animal" ref="animalField" v-model="selectedAnimal" label="Animal ID" :autofocus="!isEditing" :items="animals_data" item-text="id" :rules="[rules.required]" />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.smAndUp ? 4 : 12">
              <read-only-input v-if="selected_animal_is_object" id="editor_quantity" ref="quantityField" :value="fallenStock.quantity" label="Quantity" :rules="[rules.weanedNotNegative]" />
              <v-text-field v-if="!selected_animal_is_object" id="editor_quantity" ref="quantityField" v-model="fallenStock.quantity" label="Quantity" type="number" :rules="[rules.required, rules.moreThanZero, rules.weanedNotNegative]" />
            </v-col>
          </v-row>
          <v-text-field id="editor_location" v-model="fallenStock.location" label="Location" :rules="[rules.required]" />
          <v-autocomplete id="editor_farrow" ref="farrowField" v-model="selectedFarrow" label="Litter (for unweaned piglets)" :items="farrows_data" item-text="animalId" return-object clearable>
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title v-text="data.item.animalId" />
                <v-list-item-subtitle v-text="'Due date ' + data.item.farrowDateFormatted" />
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-text-field id="editor_reason" v-model="fallenStock.reason" label="Reason for Death" :rules="[rules.required]" />
          <v-checkbox id="editor_euthanized" v-model="fallenStock.euthanized" label="Euthanised" />
          <date-picker id="editor_date" v-model="fallenStock.date" label="Date of Death" :max="getDateToday()" :rules="[rules.required]" />
          <v-btn type="submit" class="d-none" />
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import DatePicker from '@/components/DatePicker';
import ReadOnlyInput from '@/components/ReadOnlyInput';

export default {
  name: 'FallenStockEditor',
  components: {
    DatePicker,
    ReadOnlyInput
  },
  data: vm => ({
    isValid: null,
    isSaving: false,
    editing: false,
    selectedAnimal: null,
    selectedFarrow: null,
    fallenStock: {
      type: "fallenStock",
      id: null,
      quantity: 0,
      location: null,
      reason: null,
      date: vm.getDateToday(),
      euthanized: false,
      farrowId: null
    },
    rules: {
      required: value => !vm.isEmptyProperty(value) || 'Required',
      moreThanZero: value => value > 0 || 'Number must be more than zero',
      weanedNotNegative: () => !vm.fallenStock.farrowId || vm.farrow_still_living_amount >= 0 || 'Quantity dead cannot be more than total born alive',
    }
  }),
  computed: {
    isEditing() {
      return !!this.$route.params.id;
    },
    animals_data() {
      return this.animals || [];
    },
    farrows_data() {
      return (this.farrows || []).map(data => {
        let animal = this.animals_data.find(animal => animal._id == data.id);
        data.animalId = animal ? animal.id : '';
        data.farrowDateFormatted = new Date(data.farrowDate).toLocaleDateString();

        return data;
      }).filter(data => data.animalId || (this.existing_record && data._id == this.existing_record.farrowId));
    },
    selected_animal_is_object() {
      return this.selectedAnimal && typeof this.selectedAnimal == 'object';
    },
    selected_animal_id() {
      return this.selected_animal_is_object ? this.selectedAnimal._id : this.selectedAnimal;
    },
    farrow_still_living_amount() {
      if(!this.selectedFarrow || !this.farrow_deaths) return 0;

      return this.selectedFarrow.alive - (this.farrow_deaths.reduce((subtotal, deathRecord) => 
        deathRecord.quantity > 0 ? subtotal + Number(deathRecord.quantity) : subtotal, 0
      ) + (Number(this.fallenStock.quantity) || 0));
    }
  },
  watch: {
    existing_record() {
      if(!this.editing && this.existing_record) {
        this.fallenStock = Object.assign({}, this.existing_record);
        let animal = this.animals_data.find(animal => animal._id == this.existing_record.id);
        this.selectedAnimal = animal || this.existing_record.id;
        if(this.existing_record.farrowId) {
          let farrow = this.farrows_data.find(farrow => farrow._id == this.existing_record.farrowId);
          if(farrow) this.selectedFarrow = farrow;
        }
        this.editing = true;
      }
    },
    selected_animal_id(newVal) {
      this.fallenStock.id = newVal;
      if(this.selected_animal_is_object) this.fallenStock.quantity = 1;
    },
    selectedFarrow(newVal) {
      this.fallenStock.farrowId = newVal ? newVal._id : null;
    },
    selectedAnimal() {
      if(typeof this.selectedAnimal == 'string') {
        let match = this.animals_data.find(animal => animal.id == this.selectedAnimal);
        if(match) this.selectedAnimal = match;
      }
    },
    farrow_still_living_amount() {
      this.$refs.form.validate();
    }
  },
  methods: {
    getDateToday() {
      return new Date().toISOString().slice(0, 10);
    },
    isEmptyProperty(value) {
      return value == null || value.toString().length == 0;
    },
    save() {
      if(this.isSaving) return;
      if(!this.$refs.form.validate() || !this.$refs.quantityField.valid) {
        let firstInvalidField = this.$refs.form.inputs.find(input => !input.valid)
        if(firstInvalidField) {
          firstInvalidField.focus();
          return;
        }
      }
      this.isSaving = true;
      let db = this.$pouch.getDB();
      let method = this.isEditing ? db.put : db.post;
      return method(this.fallenStock).then(res => {
        console.log(res);
        this.$store.dispatch('returnToLastRoute', '/fallen-stock');
      }).catch(console.error);
    }
  },
  pouch: {
    animals() {
      return {
        database: 'pig_manager',
        selector: {
          type: 'animal',
          $or: [
            {dateCulled: null},
            {dateCulled: {$exists: false}},
            {_id: this.isEditing && this.existing_record && this.selected_animal_is_object ? this.existing_record.id : null},
            {_id: this.isEditing && this.selectedFarrow ? this.selectedFarrow.id : null}
          ]
        },
        sort: [{id: 'asc'}]
      }
    },
    farrows() {
      return {
        database: 'pig_manager',
        selector: {
          type: 'breeding',
          $or: [
            {
              farrowDate: {$exists: true},
              $or: [
                {weaned: null},
                {weaned: {$exists: false}}
              ]
            },
            {_id: this.existing_record ? this.existing_record.farrowId : null}
          ]
        }
      }
    },
    farrow_deaths() {
      let selector = {
        type: 'fallenStock',
        farrowId: this.fallenStock.farrowId
      }
      if(this.fallenStock._id) selector['$not'] = {_id: this.fallenStock._id}
      return {
        database: 'pig_manager',
        selector: selector,
        disabled: !this.fallenStock.farrowId
      }
    },
    existing_record() {
      return {
        database: 'pig_manager',
        selector: {
          type: 'fallenStock',
          _id: this.$route.params.id
        },
        first: true,
        disabled: !this.isEditing
      }
    }
  }
}
</script>

<style scoped>
.row {
  margin-top: 0;
  margin-bottom: 0;
}

.col {
  padding-top: 0;
  padding-bottom: 0;
}
</style>