<template>
  <div>
    <portal to="app-bar-subheading"> - {{ weekType }} week</portal>
    <v-container>
      <template v-for="(todo, key) in todos">
        <v-card v-if="todo.visible" :key="key" :to="'/todo/' + todo.type + '/' + key" class="mb-4">
          <v-card-title><b>{{ todo.weekDay }}:&nbsp;</b>{{ todo.name }}</v-card-title>
          <v-card-text>
            <v-chip v-for="animal in todo.animals" :key="animal._id" class="ma-1" :color="animal.status ? 'green' : animal.status === false ? 'red' : 'yellow'" v-text="animal.id" />
            <template v-if="!todo.animals.length">
              No animals to process yet
            </template>
          </v-card-text>
        </v-card>
      </template>
    </v-container>
  </div>
</template>

<script>
import Todos from '@/scripts/todos';

export default {
  name: 'TodoList',
  data: () => ({
    todos: {}
  }),
  computed: {
    weekType() {
      return Todos.getWeekTypeName();
    }
  },
  async created() {
    this.todos = await Todos.getTodos();
  }
}
</script>