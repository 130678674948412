<template>
  <div>
    <v-container>
      <portal to="app-bar-icons">
        <v-btn type="submit" icon @click="save()">
          <v-icon>fas fa-save</v-icon>
        </v-btn>
      </portal>
      <v-card v-if="todo" class="mb-4">
        <v-card-title><b>{{ todo.weekDay }}:&nbsp;</b>{{ todo.name }}</v-card-title>
        <v-card-text>
          <v-form ref="form" class="animal-row" @submit.prevent="save()">
            <template v-for="animal in todo.animals">
              <div :key="animal.id + '-chip'">
                <v-chip class="ma-1" :color="animal.status ? 'green' : animal.status === false ? 'red' : 'yellow'" :to="'/farrow/' + animal.breeding._id" v-text="animal.id" />
              </div>
              <v-text-field :key="animal.id + '-alive'" v-model="animal.pendingAlive" type="number" label="Alive" />
              <v-text-field :key="animal.id + '-stillborn'" v-model="animal.pendingStillborn" type="number" label="Stillborn" />
              <v-text-field :key="animal.id + '-mummified'" v-model="animal.pendingMummified" type="number" label="Mummified" />
            </template>
            <template v-if="!todo.animals.length">
              No animals to process yet
            </template>
          </v-form>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Todos from '@/scripts/todos';

export default {
  name: 'TodoFarrow',
  data: vm => ({
    todo: null,
    breeding: [],
    isSaving: false,
    rules: {
      required: value => !vm.isEmptyProperty(value) || 'Required'
    }
  }),
  async created() {
    let todo = (await Todos.getTodos())[this.$route.params.key];
    for(let [, animal] of todo.animals.entries()) {
      animal.pendingAlive = animal.breeding.alive !== undefined ? animal.breeding.alive : null;
      animal.pendingStillborn = animal.breeding.stillborn !== undefined ? animal.breeding.stillborn : null;
      animal.pendingMummified = animal.breeding.mummified !== undefined ? animal.breeding.mummified : null;
    }
    this.todo = todo;
  },
  methods: {
    getDateToday() {
      return new Date().toISOString().slice(0, 10);
    },
    isEmptyProperty(value) {
      return value == null || value.toString().length == 0;
    },
    async save() {
      if(this.isSaving) return;
      if(!this.$refs.form.validate()) {
        let firstInvalidField = this.$refs.form.inputs.find(input => !input.valid)
        if(firstInvalidField) {
          firstInvalidField.focus();
          return;
        }
      }
      this.isSaving = true;
      let db = this.$pouch.getDB();
      
      Promise.all(this.todo.animals.map(animal => {
        let hadData = !!animal.breeding.farrowDate;
        let hasData = !!animal.pendingAlive || !!animal.pendingStillborn || !!animal.pendingMummified;
        let totalBorn = [animal.pendingAlive, animal.pendingStillborn, animal.pendingMummified].reduce((subtotal, value) => value ? subtotal + Number(value) : subtotal, 0);
        if(totalBorn == 0) hasData = false;
        if(animal.breeding.alive === animal.pendingAlive && animal.breeding.stillborn === animal.pendingStillborn && animal.breeding.mummified === animal.pendingMummified) return Promise.resolve();

        animal.breeding.alive = hasData ? Number(animal.pendingAlive) || 0 : null;
        animal.breeding.stillborn = hasData ? Number(animal.pendingStillborn) || 0 : null;
        animal.breeding.mummified = hasData ? Number(animal.pendingMummified) || 0 : null;
        animal.breeding.totalBorn = hasData ? totalBorn : null;
        if(hadData != hasData) animal.breeding.farrowDate = hasData ? this.getDateToday() : null;
        return db.put(animal.breeding).then(res => {
          console.log(res);
        });
      })).then(() => {
        this.$store.dispatch('returnToLastRoute', '/todo');
      }).catch(err => {
        console.error(err);
        this.isSaving = false;
      });
    }
  }
}
</script>

<style scoped>
.animal-row {
  display: grid;
  align-items: center;
  gap: 0 20px;
  grid-template-columns: max-content auto auto auto;
  color: black;
}
</style>