<template>
  <v-container>
    <portal to="app-bar-icons">
      <v-btn type="submit" icon @click="save()">
        <v-icon>fas fa-save</v-icon>
      </v-btn>
    </portal>
    <v-card>
      <v-card-text>
        <v-form ref="form" v-model="isValid" @submit.prevent="save()">
          <v-row>
            <v-col cols="6">
              <v-combobox id="editor_breed" ref="breedField" v-model="purchasedSemen.breed" label="Breed" :autofocus="!isEditing" :items="breeds" :rules="[rules.required]" clearable @keydown="handleEnterKey" @keyup="handleEnterKeyFinish" />
            </v-col>
            <v-col cols="6">
              <v-text-field id="editor_quantity" v-model="purchasedSemen.quantity" label="Quantity" type="number" :rules="[rules.required, rules.moreThanZero, rules.remainingNotNegative]" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field id="editor_batchNo" v-model="purchasedSemen.batchNo" label="Batch No." :rules="[rules.required]" />
            </v-col>
            <v-col cols="6">
              <v-text-field id="editor_boarId" v-model="purchasedSemen.boarId" label="Boar ID" :rules="[rules.required]" />
            </v-col>
          </v-row>
          <v-combobox id="editor_supplierName" ref="supplierNameField" v-model="supplier.name" label="Supplier Name" :items="supplier_names" :rules="[rules.required]" clearable @keydown="handleEnterKey" @keyup="handleEnterKeyFinish" />
          <v-textarea id="editor_supplierDetails" v-model="supplier.details" label="Supplier Details" />
          <date-picker id="editor_date" v-model="purchasedSemen.date" label="Date Purchased" :max="getDateToday()" :rules="[rules.required]" />
          <v-btn type="submit" class="d-none" />
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import DatePicker from '@/components/DatePicker';
import Vue from 'vue';

export default {
  name: 'PurchasedSemenEditor',
  components: {
    DatePicker
  },
  data: vm => ({
    isValid: null,
    isSaving: false,
    editing: false,
    breeds: [],
    existingUses: 0,
    purchasedSemen: {
      type: "purchasedSemen",
      breed: null,
      quantity: null,
      remaining: null,
      batchNo: null,
      boarId: null,
      date: vm.getDateToday(),
      supplier: null
    },
    supplier: {
      type: "supplier",
      name: null,
      details: null
    },
    rules: {
      required: value => (value != null && value.toString().length > 0) || 'Required',
      moreThanZero: value => value > 0 || 'Number must be more than zero',
      remainingNotNegative: () => vm.purchasedSemen.remaining == null || vm.purchasedSemen.remaining >= 0 || 'Quantity cannot be less than number of existing uses'
    }
  }),
  computed: {
    isEditing() {
      return !!this.$route.params.id;
    },
    suppliers_data() {
      return this.suppliers || [];
    },
    supplier_names() {
      return this.suppliers_data.map(supplier => supplier.name);
    },
    supplier_name() {
      return this.supplier.name;
    },
    existing_uses_data() {
      return this.existing_uses || [];
    },
    watch_quantity() {
      return this.purchasedSemen.quantity;
    }
  },
  watch: {
    existing_record() {
      if(!this.editing && this.existing_record) {
        this.purchasedSemen = this.existing_record;
        let existingSupplier = this.suppliers_data.find(supplier => supplier._id == this.purchasedSemen.supplier);
        if(existingSupplier) this.supplier = JSON.parse(JSON.stringify(existingSupplier));
        this.editing = true;
      }
    },
    supplier_name() {
      let existingSupplier = this.suppliers_data.find(supplier => supplier.name == this.supplier_name);
      if(existingSupplier) {
        this.supplier = JSON.parse(JSON.stringify(existingSupplier)); // unlink reactive binding
        this.purchasedSemen.supplier = existingSupplier._id;
      } else {
        this.supplier = {
          type: "supplier",
          name: this.supplier_name,
          details: null
        };
        this.purchasedSemen.supplier = null;
      }
    },
    watch_quantity(newVal) {
      this.purchasedSemen.remaining = newVal != null ? newVal - this.existingUses : null;
    },
    existing_uses(newVal) {
      this.existingUses = newVal.reduce((subtotal, use) => {
        if(use.semenOne == this.purchasedSemen._id) subtotal++;
        if(use.semenTwo == this.purchasedSemen._id) subtotal++;
        return subtotal;
      }, 0);
    }
  },
  async created() {
    this.breeds = (await this.$pouch.getDB().query('app/semen-breeds', {group:true})).rows.map(row => row.key);
  },
  methods: {
    getDateToday() {
      return new Date().toISOString().slice(0, 10);
    },
    save() {
      if(this.isSaving) return;
      if(this.$refs.breedField.isFocused && this.$refs.breedField.value != this.$refs.breedField.internalSearch) {
        // the value isn't usually updated until the combobox loses focus
        this.$refs.breedField.setValue(this.$refs.breedField.internalSearch);
      }
      if(this.$refs.supplierNameField.isFocused && this.$refs.supplierNameField.value != this.$refs.supplierNameField.internalSearch) {
        // the value isn't usually updated until the combobox loses focus
        this.$refs.supplierNameField.setValue(this.$refs.supplierNameField.internalSearch);
      }
      if(!this.isValid) {
        this.$refs.form.validate();
        let firstInvalidField = this.$refs.form.inputs.find(input => !input.valid)
        if(firstInvalidField) {
          firstInvalidField.focus();
          return;
        }
      }
      this.isSaving = true;
      this.purchasedSemen.quantity = Number(this.purchasedSemen.quantity);
      let db = this.$pouch.getDB();
      let supplierMethod = this.purchasedSemen.supplier != null ? db.put : db.post;
      supplierMethod(this.supplier).then((res) => {
        console.log(res);
        this.purchasedSemen.supplier = res.id;
        let method = this.isEditing ? db.put : db.post;
        method(this.purchasedSemen).then((res) => {
          console.log(res);
          this.$store.dispatch('returnToLastRoute', '/purchased-semen');
        }).catch(err => {
          console.error(err);
        });
      }).catch(err => {
        console.error(err);
      });
    },
    handleEnterKey(event) {
      if(event.code === "Enter") {
        let el = event.target;
        while(!el.__vue__ && el.parentNode) el = el.parentNode;
        if(el.__vue__.internalSearch && el.__vue__.value == el.__vue__.internalSearch) {
          event.preventDefault();
          this.save();
        }
      }
    },
    handleEnterKeyFinish(event) {
      if(event.code === "Enter") {
        let el = event.target;
        while(!el.__vue__ && el.parentNode) el = el.parentNode;
        if(el.__vue__.internalSearch && el.__vue__.value == el.__vue__.internalSearch) {
          Vue.nextTick(() => {
            el.__vue__.isMenuActive = false;
          });
        }
      }
    }
  },
  pouch: {
    suppliers() {
      return {
        database: 'pig_manager',
        selector: {
          type: 'supplier'
        }
      }
    },
    existing_record() {
      return {
        database: 'pig_manager',
        selector: {
          type: 'purchasedSemen',
          _id: this.$route.params.id
        },
        first: true,
        disabled: !this.isEditing
      }
    },
    existing_uses() {
      return {
        database: 'pig_manager',
        selector: {
          type: 'breeding',
          $or: [
            {semenOne: this.purchasedSemen._id},
            {semenTwo: this.purchasedSemen._id}
          ]
        },
        disabled: !this.purchasedSemen._id
      }
    }
  }
}
</script>

<style scoped>
.row {
  margin-top: 0;
  margin-bottom: 0;
}

.col {
  padding-top: 0;
  padding-bottom: 0;
}
</style>