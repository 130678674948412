<template>
  <div>
    <v-container>
      <portal to="app-bar-icons">
        <v-btn type="submit" icon @click="save()">
          <v-icon>fas fa-save</v-icon>
        </v-btn>
      </portal>
      <v-card v-if="todo" class="mb-4">
        <v-card-title><b>{{ todo.weekDay }}:&nbsp;</b>{{ todo.name }}</v-card-title>
        <v-card-text>
          <v-form ref="form" class="animal-row" @submit.prevent="save()">
            <template v-for="animal in todo.animals">
              <v-checkbox :key="animal.task.id + '-checkbox'" v-model="animal.pendingAi" />
              <div :key="animal.task.id + '-chip'">
                <v-chip class="ma-1" :color="animal.status ? 'green' : animal.status === false ? 'red' : 'yellow'" :to="animal.breeding ? '/ai/' + animal.breeding._id : null" v-text="animal.id" />
              </div>
              <v-autocomplete v-show="animal.pendingAi" :key="animal.task.id + '-semen'" v-model="animal.pendingSemen" label="Semen" :items="available_semen" item-value="id" :rules="[rules.required, rules.noPurchasedSemenLeft]">
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title v-text="data.item.title" />
                    <v-list-item-subtitle v-text="data.item.details" />
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <div v-show="!animal.pendingAi" :key="animal.task.id + '-semen-placeholder'" style="height: 70px" />
            </template>
            <template v-if="!todo.animals.length">
              No animals to process yet
            </template>
          </v-form>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Todos from '@/scripts/todos';

export default {
  name: 'TodoAI',
  data: vm => ({
    todo: null,
    isSaving: false,
    isAiOne: vm.$route.params.key == 'aiOneDue',
    semenRecords: [],
    semenAvailable: [],
    rules: {
      required: value => !vm.isEmptyProperty(value) || 'Required',
      noPurchasedSemenLeft: value => {
        if(vm.isEmptyProperty(value))
          return true;
        let semenRecord = vm.available_semen.find(semen => semen.type == 'purchased' && semen.id == value);
        return !semenRecord || semenRecord.remaining >= 0 || 'Purchased semen quantity not available';
      }
    }
  }),
  computed: {
    available_semen() {
      // collect a unique list of all semen ids, either available or already used by the visible records
      let semenIds = [];
      semenIds.push(...this.semenAvailable.map(record => record.id));
      semenIds.push(...this.semenRecords.map(record => record._id));
      return [...new Set(semenIds)].map(semenId => {
        let available = this.semenAvailable.find(available => available.id == semenId);
        if(!available) {
          let semen = this.semenRecords.find(record => record._id == semenId);
          available = {
            id: semen._id,
            type: 'purchased',
            title: semen.breed,
            details: 'BN ' + semen.batchNo + ' - Boar ID ' + semen.boarId,
            quantity: 0 // default to none available
          };
        }
        available.text = available.title + ' - ' + available.details;
        let addedUses = this.todo.animals.filter(animal => animal.pendingAi && animal.pendingSemen == available.id && (!animal.breeding || (this.isAiOne ? animal.breeding.semenOne != available.id : animal.breeding.semenTwo != available.id))).length;
        let removedUses = this.todo.animals.filter(animal => (!animal.pendingAi || animal.pendingSemen != available.id) && animal.breeding && (this.isAiOne ? animal.breeding.semenOne == available.id : animal.breeding.semenTwo == available.id)).length;
        available.remaining = available.quantity - addedUses + removedUses;
        return available;
      });
    }
  },
  async created() {
    let todo = (await Todos.getTodos())[this.$route.params.key];
    for(let [, animal] of todo.animals.entries()) {
      animal.pendingAi = !!animal.breeding && (this.isAiOne || !!animal.breeding.semenTwo);
      animal.pendingSemen = animal.pendingAi ? this.isAiOne ? animal.breeding.semenOne : animal.breeding.semenTwo : null;
    }
    let semenAvailable = (await this.$pouch.getDB().query('app/semen-available')).rows;
    if(semenAvailable.length > 0) this.semenAvailable = semenAvailable[0].value;
    let allSemenIds = [...new Set(todo.animals.filter(animal => animal.breeding).map(animal => this.isAiOne ? animal.breeding.semenOne : animal.breeding.semenTwo).filter(semen => semen))];
    if(allSemenIds.length > 0) this.semenRecords = (await this.$pouch.getDB().allDocs({include_docs: true, keys: allSemenIds})).rows.map(row => row.doc);
    this.todo = todo;
  },
  methods: {
    getDateToday() {
      return new Date().toISOString().slice(0, 10);
    },
    isEmptyProperty(value) {
      return value == null || value.toString().length == 0;
    },
    async save() {
      if(this.isSaving) return;
      if(!this.$refs.form.validate()) {
        let firstInvalidField = this.$refs.form.inputs.find(input => !input.valid && input.$el.style.display != 'none')
        if(firstInvalidField) {
          firstInvalidField.focus();
          return;
        }
      }
      this.isSaving = true;
      let db = this.$pouch.getDB();
      
      for(let [, animal] of this.todo.animals.entries()) {
        if(animal.breeding && animal.pendingAi && (this.isAiOne ? animal.breeding.semenOne == animal.pendingSemen : animal.breeding.semenTwo == animal.pendingSemen)) continue;
        if(!animal.breeding && !animal.pendingAi) continue;
        if(!animal.breeding) {
          animal.breeding = {type: "breeding", id: animal.task.doc._id}
        }
        if(this.isAiOne) {
          animal.breeding.semenOne = animal.pendingSemen;
          animal.breeding.dateOne = animal.pendingSemen != null ? this.getDateToday() : null;
        } else {
          animal.breeding.semenTwo = animal.pendingAi ? animal.pendingSemen : null;
          animal.breeding.dateTwo = animal.pendingAi && animal.pendingSemen != null ? this.getDateToday() : null;
        }
        try {
          console.log(await (!animal.pendingAi && this.isAiOne ? db.remove : animal.breeding._id ? db.put : db.post)(animal.breeding));
        } catch(err) {
          console.error(err);
          this.isSaving = false;
          return;
        }
      }
      this.$store.dispatch('returnToLastRoute', '/todo');
    }
  }
}
</script>

<style scoped>
.animal-row {
  display: grid;
  align-items: center;
  gap: 0 5px;
  grid-template-columns: max-content max-content auto;
  color: black;
}
</style>