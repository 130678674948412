import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home';
import TodoList from '@/views/todo/TodoList';
import TodoAI from '@/views/todo/TodoAI';
import TodoScan from '@/views/todo/TodoScan';
import TodoCrate from '@/views/todo/TodoCrate';
import TodoFarrow from '@/views/todo/TodoFarrow';
import TodoWean from '@/views/todo/TodoWean';
import TodoMedicine from '@/views/todo/TodoMedicine';
import Reminders from '@/views/reminders/Reminders';
import Login from '@/views/LogIn';
import Admin from '@/views/Admin';
import Herd from '@/views/herd/Herd';
import Animal from '@/views/herd/Animal';
import HerdEditor from '@/views/herd/HerdEditor';
import FallenStock from '@/views/herd/FallenStock';
import FallenStockEditor from '@/views/herd/FallenStockEditor';
import PurchasedMedicine from '@/views/medicine/PurchasedMedicine';
import PurchasedMedicineEditor from '@/views/medicine/PurchasedMedicineEditor';
import DiscardedMedicine from '@/views/medicine/DiscardedMedicine';
import DiscardedMedicineEditor from '@/views/medicine/DiscardedMedicineEditor';
import Medicine from '@/views/medicine/Medicine';
import MedicineEditor from '@/views/medicine/MedicineEditor';
import PurchasedSemen from '@/views/breeding/PurchasedSemen';
import PurchasedSemenEditor from '@/views/breeding/PurchasedSemenEditor';
import AI from '@/views/breeding/AI';
import AIEditor from '@/views/breeding/AIEditor';
import AIScanEditor from '@/views/breeding/AIScanEditor';
import Farrow from '@/views/breeding/Farrow';
import FarrowEditor from '@/views/breeding/FarrowEditor';
import DeleteRecord from '@/views/DeleteRecord';
import About from '@/views/About';
import ComponentProxy from '@/views/ComponentProxy';
import store from '@/store';

Vue.use(VueRouter);

const herdTabs = {
  'Herd': '/herd',
  'Fallen Stock': '/fallen-stock'
};
const medicineTabs = {
  'Medicine': '/medicine',
  'Purchased': '/purchased-medicine',
  'Discarded': '/discarded-medicine'
};
const breedingTabs = {
  'AI': '/ai',
  'Purchased Semen': '/purchased-semen',
  'Farrow': '/farrow'
};

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: ComponentProxy,
      meta: {
        component: Home
      }
    },
    {
      path: '/todo',
      name: 'To do list',
      component: ComponentProxy,
      meta: {
        component: TodoList
      }
    },
    {
      path: '/todo/ai/:key',
      name: 'To do - AI',
      component: ComponentProxy,
      meta: {
        component: TodoAI
      }
    },
    {
      path: '/todo/scan/:key',
      name: 'To do - Scan',
      component: ComponentProxy,
      meta: {
        component: TodoScan
      }
    },
    {
      path: '/todo/crate/:key',
      name: 'To do - Crate',
      component: ComponentProxy,
      meta: {
        component: TodoCrate
      }
    },
    {
      path: '/todo/farrow/:key',
      name: 'To do - Farrow',
      component: ComponentProxy,
      meta: {
        component: TodoFarrow
      }
    },
    {
      path: '/todo/wean/:key',
      name: 'To do - Wean',
      component: ComponentProxy,
      meta: {
        component: TodoWean
      }
    },
    {
      path: '/todo/medicine/:key',
      name: 'To do - Medicine',
      component: ComponentProxy,
      meta: {
        component: TodoMedicine
      }
    },
    {
      path: '/reminders/:offset?',
      name: 'Reminders',
      component: ComponentProxy,
      meta: {
        component: Reminders,
        tabs: {
          'Today': '/reminders',
          '+1d': '/reminders/1',
          '+2d': '/reminders/2'
        }
      }
    },
    {
      path: '/herd',
      name: 'Herd',
      component: ComponentProxy,
      meta: {
        component: Herd,
        tabs: herdTabs
      }
    },
    {
      path: '/herd/add',
      name: 'Create Herd Record',
      component: ComponentProxy,
      meta: {
        component: HerdEditor
      }
    },
    {
      path: '/animal/:id',
      name: 'Animal Details',
      component: ComponentProxy,
      meta: {
        component: Animal
      }
    },
    {
      path: '/herd/:id',
      name: 'Edit Herd Record',
      component: ComponentProxy,
      meta: {
        component: HerdEditor
      }
    },
    {
      path: '/herd/:id/delete',
      name: 'Delete Herd Record',
      component: ComponentProxy,
      meta: {
        modal: DeleteRecord,
        type: "Animal"
      }
    },
    {
      path: '/fallen-stock',
      name: 'Fallen Stock',
      component: ComponentProxy,
      meta: {
        component: FallenStock,
        tabs: herdTabs
      }
    },
    {
      path: '/fallen-stock/add',
      name: 'Create Fallen Stock Record',
      component: ComponentProxy,
      meta: {
        component: FallenStockEditor
      }
    },
    {
      path: '/fallen-stock/:id',
      name: 'Edit Fallen Stock Record',
      component: ComponentProxy,
      meta: {
        component: FallenStockEditor
      }
    },
    {
      path: '/fallen-stock/:id/delete',
      name: 'Delete Fallen Stock Record',
      component: ComponentProxy,
      meta: {
        modal: DeleteRecord,
        type: "Fallen Stock"
      }
    },
    {
      path: '/purchased-medicine',
      name: 'Purchased Medicine',
      component: ComponentProxy,
      meta: {
        component: PurchasedMedicine,
        tabs: medicineTabs
      }
    },
    {
      path: '/purchased-medicine/add',
      name: 'Create Purchased Medicine Record',
      component: ComponentProxy,
      meta: {
        component: PurchasedMedicineEditor
      }
    },
    {
      path: '/purchased-medicine/:id',
      name: 'Edit Purchased Medicine Record',
      component: ComponentProxy,
      meta: {
        component: PurchasedMedicineEditor
      }
    },
    {
      path: '/purchased-medicine/:id/delete',
      name: 'Delete Purchased Medicine Record',
      component: ComponentProxy,
      meta: {
        modal: DeleteRecord,
        type: "Purchased Medicine"
      }
    },
    {
      path: '/discarded-medicine',
      name: 'Discarded Medicine',
      component: ComponentProxy,
      meta: {
        component: DiscardedMedicine,
        tabs: medicineTabs
      }
    },
    {
      path: '/discarded-medicine/add',
      name: 'Create Discarded Medicine Record',
      component: ComponentProxy,
      meta: {
        component: DiscardedMedicineEditor
      }
    },
    {
      path: '/discarded-medicine/:id',
      name: 'Edit Discarded Medicine Record',
      component: ComponentProxy,
      meta: {
        component: DiscardedMedicineEditor
      }
    },
    {
      path: '/discarded-medicine/:id/delete',
      name: 'Delete Discarded Medicine Record',
      component: ComponentProxy,
      meta: {
        modal: DeleteRecord,
        type: "Discarded Medicine"
      }
    },
    {
      path: '/medicine',
      name: 'Medicine',
      component: ComponentProxy,
      meta: {
        component: Medicine,
        tabs: medicineTabs
      }
    },
    {
      path: '/medicine/add',
      name: 'Create Medicine Record',
      component: ComponentProxy,
      meta: {
        component: MedicineEditor
      }
    },
    {
      path: '/medicine/:id',
      name: 'Edit Medicine Record',
      component: ComponentProxy,
      meta: {
        component: MedicineEditor
      }
    },
    {
      path: '/medicine/:id/delete',
      name: 'Delete Medicine Record',
      component: ComponentProxy,
      meta: {
        modal: DeleteRecord,
        type: "Medicine"
      }
    },
    {
      path: '/purchased-semen',
      name: 'Purchased Semen',
      component: ComponentProxy,
      meta: {
        component: PurchasedSemen,
        tabs: breedingTabs
      }
    },
    {
      path: '/purchased-semen/add',
      name: 'Create Purchased Semen Record',
      component: ComponentProxy,
      meta: {
        component: PurchasedSemenEditor
      }
    },
    {
      path: '/purchased-semen/:id',
      name: 'Edit Purchased Semen Record',
      component: ComponentProxy,
      meta: {
        component: PurchasedSemenEditor
      }
    },
    {
      path: '/purchased-semen/:id/delete',
      name: 'Delete Purchased Semen Record',
      component: ComponentProxy,
      meta: {
        modal: DeleteRecord,
        type: "Purchased Semen"
      }
    },
    {
      path: '/ai',
      name: 'AI',
      component: ComponentProxy,
      meta: {
        component: AI,
        tabs: breedingTabs
      }
    },
    {
      path: '/ai/add',
      name: 'Create AI Record',
      component: ComponentProxy,
      meta: {
        component: AIEditor
      }
    },
    {
      path: '/ai/:id',
      name: 'Edit AI Record',
      component: ComponentProxy,
      meta: {
        component: AIEditor,
        tabs: {
          'AI': '/ai/:id',
          'Scan': '/ai/scan/:id',
          'Farrow': '/farrow/:id'
        }
      }
    },
    {
      path: '/ai/scan/:id',
      name: 'Edit AI Scan Record',
      component: ComponentProxy,
      meta: {
        component: AIScanEditor,
        tabs: {
          'AI': '/ai/:id',
          'Scan': '/ai/scan/:id',
          'Farrow': '/farrow/:id'
        }
      }
    },
    {
      path: '/ai/:id/delete',
      name: 'Delete AI Record',
      component: ComponentProxy,
      meta: {
        modal: DeleteRecord,
        type: 'Breeding'
      }
    },
    {
      path: '/farrow',
      name: 'Farrow',
      component: ComponentProxy,
      meta: {
        component: Farrow,
        tabs: breedingTabs
      }
    },
    {
      path: '/farrow/add',
      name: 'Create Farrow Record',
      component: ComponentProxy,
      meta: {
        component: FarrowEditor
      }
    },
    {
      path: '/farrow/:id',
      name: 'Edit Farrow Record',
      component: ComponentProxy,
      meta: {
        component: FarrowEditor,
        tabs: {
          'AI': '/ai/:id',
          'Scan': '/ai/scan/:id',
          'Farrow': '/farrow/:id'
        }
      }
    },
    {
      path: '/farrow/:id/delete',
      name: 'Delete Farrow Record',
      component: ComponentProxy,
      meta: {
        modal: DeleteRecord,
        type: 'Breeding',
        farrow: true
      }
    },
    {
      path: '/about',
      name: 'About',
      component: ComponentProxy,
      meta: {
        component: About,
        guest: true
      }
    },
    {
      path: '/login',
      name: 'Log In',
      component: ComponentProxy,
      meta: {
        guestOnly: true,
        modal: Login
      }
    },
    {
      path: '/admin',
      name: 'Admin',
      component: ComponentProxy,
      meta: {
        component: Admin
      }
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
});
router.beforeEach((to, from, next) => {
  store.dispatch('checkAuthState').then(isAuthenticated => {
    if(isAuthenticated && !store.state.needsReauth && to.matched.some(route => route.meta.guestOnly)) {
      next({
        path: from.query.redirect || '/'
      });
    } else if(!isAuthenticated && to.matched.some(route => !route.meta.guest && !route.meta.guestOnly)) {
      next(to.fullPath === '/' ? '/about' : {
        path: '/login',
        query: {redirect: to.fullPath}
      });
    } else {
      next();
    }
  });
});
router.afterEach((to, from) => {
  store.commit('pageNavigation', {to: to.matched[0], from: from});
  Vue.nextTick(() => {
    document.title = "Pig Manager - " + to.name;
  })
});

export default router;
