<template>
  <v-container>
    <portal to="app-bar-icons">
      <v-btn icon to="/herd/add">
        <v-icon>fas fa-plus-circle</v-icon>
      </v-btn>
    </portal>
    <v-data-table :headers="headers" :items="herd_data" item-key="_id" class="elevation-2" mobile-breakpoint="0" @click:row="record => $router.push('/animal/' + record._id)" @contextmenu:row.prevent="openContextMenu" />
    <v-menu v-model="contextMenu.visible" absolute :position-x="contextMenu.x" :position-y="contextMenu.y" offset-y>
      <v-list>
        <v-list-item v-for="item in contextMenu.items" :key="item.text" :ripple="item.color ? {class: item.color + '--text'} : true" @click="item.action">
          <v-list-item-icon><v-icon :color="item.color" v-text="item.icon" /></v-list-item-icon>
          <v-list-item-title :class="item.color ? item.color + '--text' : ''" v-text="item.text" />
        </v-list-item>
      </v-list>
    </v-menu>
    <record-printer name="Herd" :structure="headers" :data="herd_data" start-date-key="dateSelected" end-date-key="dateSelected" :filter="record => record.status != 'Moved off'" />
  </v-container>
</template>

<script>
import RecordPrinter from '@/components/RecordPrinter';

export default {
  name: 'Herd',
  components: { RecordPrinter },
  data: vm => ({
    headers: [
      {text: 'Herd No', value: 'herd'},
      {text: 'Animal', value: 'id'},
      {text: 'Date Selected', value: 'dateSelectedFormatted'},
      {text: 'Breed', value: 'breed'},
      {text: 'Parity', value: 'parity'},
      {text: 'Status', value: 'status'},
      {text: 'Status Date', value: 'statusDateFormatted'},
      {text: 'Details', value: 'details'}
    ],
    contextMenu: {
      visible: false,
      x: 0,
      y: 0,
      record: null,
      items: [
        {
          icon: 'fa-pencil-alt',
          text: 'Edit',
          action() {
            vm.editRecord(vm.contextMenu.record);
          }
        },
        {
          icon: 'fa-trash',
          text: 'Delete',
          color: 'red',
          action() {
            vm.$router.push('/herd/' + vm.contextMenu.record._id + '/delete');
          }
        }
      ]
    },
    animalStatuses: null,
    herd_data: []
  }),
  computed: {
    farm_data() {
      return this.farm || [];
    }
  },
  watch: {
    async herd() {
      this.animalStatuses = (await this.$pouch.getDB().query('app/animal-status', {group:true})).rows;
      let barrenCutoffDate = new Date();
      barrenCutoffDate.setDate(barrenCutoffDate.getDate() - 9); // marked as barren on 10th day if still awaiting AI (would mean they haven't come in heat)
      barrenCutoffDate = barrenCutoffDate.toISOString().split('T')[0];
      this.herd_data = this.herd.map(row => {
        row.herd = this.farm_data.herdNumber;
        let animalStatus = this.animalStatuses.find(animal => animal.key == row._id);
        if(animalStatus) {
          animalStatus = animalStatus.value;
          row.parity = animalStatus.parity;
          if(animalStatus.status == 'Awaiting AI' && animalStatus.parity > 0 && animalStatus.date < barrenCutoffDate) {
            row.status = 'Barren';
          } else {
            row.status = animalStatus.status;
          }
          if(animalStatus.details) {
            row.details = animalStatus.details;
          } else if(row.status != '-') {
            let statusDate = new Date(animalStatus.aiDate || animalStatus.date);
            const diffTime = Math.abs(statusDate - (new Date()));
            const diffDays = Math.ceil(diffTime / 86400000) - 1; // milliseconds in a day
            statusDate.setDate(statusDate.getDate() + 114); // move to due date
            if(animalStatus.status == 'Farrowed') {
              row.details = 'Was due ' + statusDate.toLocaleDateString();
            } else {
              row.details = diffDays + (diffDays == 1 ? ' day' : ' days');
              if(animalStatus.aiDate && animalStatus.status != 'Barren') {
                row.details += ' - due ' + statusDate.toLocaleDateString();
              }
            }
          }
          if(!row.details) row.details = ''; // printing fix - prevent showing 'undefined'
          row.statusDate = animalStatus.date;
          row.statusDateFormatted = new Date(row.statusDate).toLocaleDateString();
        }
        // format date for user's locale
        row.dateSelectedFormatted = new Date(row.dateSelected).toLocaleDateString();
        return row;
      })

    }
  },
  methods: {
    editRecord(record) {
      this.$router.push('/herd/' + record._id);
    },
    openContextMenu(event, row) {
      this.contextMenu.visible = true;
      this.contextMenu.x = event.x;
      this.contextMenu.y = event.y;
      this.contextMenu.record = row.item;
    }
  },
  pouch: {
    herd() {
      return {
        database: 'pig_manager',
        sort: [{id: "asc"}],
        selector: {type: 'animal'}
      }
    },
    farm() {
      return {
        database: 'pig_manager',
        selector: {_id: 'farm'},
        first: true
      }
    }
  }
}
</script>

<style>
tbody tr {
  cursor: pointer;
}
</style>