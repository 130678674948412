import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import About from '@/views/About.vue';

Vue.use(Vuex)

let sync = null, isGoingBack = false;

window.addEventListener('popstate', () => {
  isGoingBack = true;
});

export default new Vuex.Store({
  state: {
    loggedIn: false,
    syncAlive: false,
    syncActive: false,
    syncEror: null,
    lastSyncActive: null,
    needsReauth: false,
    lastComponent: About,
    lastRoute: []
  },
  mutations: {
    authStateChange(state, loggedIn) {
      state.loggedIn = !!loggedIn;
      if (!loggedIn) {
        state.syncAlive = false;
        state.syncActive = false;
        state.needsReauth = false;
        state.syncEror = null;
      }
    },
    syncStateChange(state, syncStatus) {
      state.syncAlive = syncStatus.alive;
      state.syncActive = syncStatus.active;
      state.needsReauth = syncStatus.unauthorized;
      state.syncError = syncStatus.error || null;
      if (!syncStatus.alive) sync = null;
      if (syncStatus.active) state.lastSyncActive = new Date();
    },
    pageNavigation(state, routes) {
      if(routes.to.path == '') state.lastRoute = [];
      else {
        if(isGoingBack) state.lastRoute.pop();
        else state.lastRoute.push(routes.from.fullPath);
      }
      isGoingBack = false;
      if (routes.to.meta.component) {
        state.lastComponent = routes.to.meta.component;
      }
    }
  },
  actions: {
    updateAuth(context, loggedIn) {
      if (context.state.loggedIn != loggedIn) context.commit('authStateChange', loggedIn);
      if (sync && !loggedIn) {
        sync.cancel();
        sync = null;
      }
      if (loggedIn && !sync) {
        let farmId = localStorage.getItem('farmId');
        let url = new URL(farmId, 'https://couch.pgmann.com/db/');
        Vue.prototype.$pouch.getDB().put({
          _id: '_local/connection_details',
          url: url.href
        });
        sync = Vue.prototype.$pouch.sync(
          url, {
            skip_setup: true,
            back_off_function: () => {
              // could track state here to work out if currently retrying
              return Math.floor(Math.random() * 9000 + 1000)
            }
          }
        ).on('paused', info => {
          // paused just means everything is up to date, so for the purposes of
          // state management sync is still technically active as it will pick up changes instantly
          console.log('Sync paused', info);
          context.commit('syncStateChange', { alive: true, active: false, unauthorized: false });
        }).on('active', info => {
          console.log('Sync active', info);
          context.commit('syncStateChange', { alive: true, active: true, unauthorized: false });
        }).on('error', e => {
          console.log('Sync error', e);
          context.commit('syncStateChange', { alive: false, active: false, unauthorized: e.error == 'unauthorized', error: e });
        }).on('complete', info => {
          console.log('Sync cancelled', info);
          context.commit('syncStateChange', { alive: false, active: false, unauthorized: false });
        });
      }
    },
    logIn(context, data) {
      return new Promise((resolve, reject) => {
        data.loginData.status = 'Please wait while we log you in!';
        let db = Vue.prototype.$pouch.getDB(new URL(data.farmId, 'https://couch.pgmann.com/db/'), { skip_setup: true });
        db.logIn(data.farmId + '_' + data.user, data.pass).then(() => {
          db.info().then(info => {
            if (info.error) {
              reject(info.reason.replace(/db|database/gi, 'farm') || (window.navigator.onLine ? 'An unexpected error occurred during login.' : 'An internet connection is required to log in.'));
            } else {
              data.loginData.status = 'Login successful, syncing your data...';
              localStorage.setItem('farmId', data.farmId);
              localStorage.setItem('user', data.user);
              Vue.prototype.$pouch.sync(db, { live: false }).on("complete", () => {
                context.dispatch('updateAuth', true);
                resolve();
              });
            }
          })
        }).catch(e => {
          reject(e.message || (window.navigator.onLine ? 'An unexpected error occurred during login.' : 'An internet connection is required to log in.'));
        });
      })
    },
    logOut(context) {
      Vue.prototype.$pouch.getDB().destroy().then(() => {
        context.dispatch('updateAuth', false);
        router.push({
          path: '/about'
        });
      })
    },
    async checkAuthState(context) {
      let info = await Vue.prototype.$pouch.getDB().info();
      if (context.state.loggedIn != info.doc_count) {
        context.dispatch('updateAuth', !!info.doc_count);
      }
      return context.state.loggedIn;
    },
    returnToLastRoute(context, defaultRoute) {
      if(context.state.lastRoute.length) router.back();
      else router.replace(defaultRoute);
    }
  }
})
