<template>
  <v-container>
    <v-data-table :headers="headers" :items="reminders_data" item-key="_id" class="elevation-2" mobile-breakpoint="0" @click:row="editRecord" @contextmenu:row.prevent="openContextMenu" />
    <v-menu v-model="contextMenu.visible" absolute :position-x="contextMenu.x" :position-y="contextMenu.y" offset-y>
      <v-list>
        <v-list-item v-for="item in contextMenu.items" :key="item.text" :ripple="item.color ? {class: item.color + '--text'} : true" @click="item.action">
          <v-list-item-icon><v-icon :color="item.color" v-text="item.icon" /></v-list-item-icon>
          <v-list-item-title :class="item.color ? item.color + '--text' : ''" v-text="item.text" />
        </v-list-item>
      </v-list>
    </v-menu>
  </v-container>
</template>

<script>
export default {
  name: 'Reminders',
  data: vm => ({
    headers: [
      {text: 'Animal', value: 'id'},
      {text: 'Reminder Type', value: 'type'},
      {text: 'Details', value: 'details'},
      {text: 'Reminder For', value: 'for'}
    ],
    contextMenu: {
      visible: false,
      x: 0,
      y: 0,
      record: null,
      items: [
        {
          icon: 'fa-pencil-alt',
          text: 'Edit',
          action() {
            vm.editRecord(vm.contextMenu.record);
          }
        },
        {
          icon: 'fa-eye-slash',
          text: 'Dismiss',
          color: 'red',
          action() {
            vm.dismissReminder(vm.contextMenu.record);
          }
        }
      ]
    },
    reminders: [],
    changeListener: null
  }),
  computed: {
    animal_lookup_ids() {
      return this.reminders.map(row => row.doc.type == 'medicine' ? row.doc.animals : null).filter(row => !!row).flat();
    },
    product_lookup_ids() {
      return this.reminders.map(row => row.doc.type == 'medicine' ? row.doc.product : null).filter(row => !!row);
    },
    animals_data() {
      return this.animals || [];
    },
    purchased_medicine_data() {
      return this.purchased_medicine || [];
    },
    reminders_data() {
      return (this.reminders || []).map(row => {
        let isAnimal = row.doc.type == 'animal';
        let animals = isAnimal ? [row.doc] : this.animals_data.filter(animal => row.doc.animals.includes(animal._id));
        let product = isAnimal ? null : this.purchased_medicine_data.find(product => product._id == row.doc.product);
        return {
          _id: row.doc._id,
          doc: row.doc,
          id: isAnimal ? row.doc.id : this.formatAnimals(animals),
          type: (isAnimal ? 'Heat' : 'Medicine') + (row.value.completed ? ' ✔️' : ''),
          details: isAnimal ? 'Cycle due' : product ? row.doc.doseAmount + product.measurementUnit + ' ' + product.product + ' - ' + row.doc.reason : '',
          for: row.doc.modifiedBy
        };
      })
    },
    date() {
      return this.getRelativeDate(this.$route.params.offset || 0)
    }
  },
  watch: {
    '$route': {
      immediate: true,
      async handler() {
        await this.updateReminders();
      }
    }
  },
  created() {
    this.changeListener = this.$pouch.changes({
      live: true,
      since: 'now'
    }).on('change', async () => this.updateReminders());
  },
  destroyed() {
    if(this.changeListener) this.changeListener.cancel();
  },
  methods: {
    async updateReminders() {
      this.reminders = (await this.$pouch.getDB().query('app/reminders', {key: this.date, include_docs: true})).rows;
    },
    getRelativeDate(daysOffset) {
      let dateObject = new Date();
      dateObject.setDate(dateObject.getDate() + Number(daysOffset));
      return dateObject.toISOString().split('T')[0];
    },
    formatAnimals(animals) {
      let formatted = animals.map(animal => animal.id).sort().join(', ');
      return formatted.length < 100 ? formatted : animals.length + ' animals';
    },
    editRecord(record) {
      this.$router.push((record.type == 'Heat' ? '/herd/' : '/medicine/') + record._id);
    },
    async dismissReminder(record) {
      let db = this.$pouch.getDB();
      let doc = record.doc;
      if(doc.type == 'animal') doc.lastCycleDetected = null;
      if(doc.type == 'medicine') doc.reminders.splice(doc.reminders.indexOf(this.date), 1);
      await db.put(doc);
    },
    openContextMenu(event, row) {
      this.contextMenu.visible = true;
      this.contextMenu.x = event.x;
      this.contextMenu.y = event.y;
      this.contextMenu.record = row.item;
    }
  },
  pouch: {
    animals() {
      return {
        database: 'pig_manager',
        selector: {
          _id: {$in: this.animal_lookup_ids}
        },
        disabled: !this.animal_lookup_ids.length
      }
    },
    purchased_medicine() {
      return {
        database: 'pig_manager',
        selector: {
          _id: {$in: this.product_lookup_ids}
        },
        disabled: !this.product_lookup_ids.length
      }
    }
  }
}
</script>