<template>
  <v-container>
    <portal to="app-bar-icons">
      <v-btn icon to="/fallen-stock/add">
        <v-icon>fas fa-plus-circle</v-icon>
      </v-btn>
    </portal>
    <v-data-table :headers="headers" :items="fallen_stock_data" item-key="_id" class="elevation-2" mobile-breakpoint="0" @click:row="editRecord" @contextmenu:row.prevent="openContextMenu" />
    <v-menu v-model="contextMenu.visible" absolute :position-x="contextMenu.x" :position-y="contextMenu.y" offset-y>
      <v-list>
        <v-list-item v-for="item in contextMenuItems" :key="item.text" :ripple="item.color ? {class: item.color + '--text'} : true" @click="item.action">
          <v-list-item-icon><v-icon :color="item.color" v-text="item.icon" /></v-list-item-icon>
          <v-list-item-title :class="item.color ? item.color + '--text' : ''" v-text="item.text" />
        </v-list-item>
      </v-list>
    </v-menu>
    <record-printer name="Fallen Stock" :structure="headers" :data="fallen_stock_data" start-date-key="date" end-date-key="date" />
  </v-container>
</template>

<script>
import RecordPrinter from '@/components/RecordPrinter';
export default {
  name: 'FallenStock',
  components: { RecordPrinter },
  data: vm => ({
    headers: [
      {text: 'Date of Death', value: 'dateFormatted'},
      {text: 'Animal ID', value: 'displayId'},
      {text: 'Quantity', value: 'quantity'},
      {text: 'Location', value: 'location'},
      {text: 'Reason for Death', value: 'reason'},
      {text: 'Euthanised?', value: 'euthanizedFormatted'}
    ],
    contextMenu: {
      visible: false,
      x: 0,
      y: 0,
      record: null,
      items: [
        {
          icon: 'fa-pencil-alt',
          text: 'Edit Farrow Record',
          action() {
            vm.$router.push('/farrow/' + vm.contextMenu.record.farrowId);
          },
          visible(record) {
            return record.farrowId != null;
          }
        },
        {
          icon: 'fa-pencil-alt',
          text: 'Edit',
          action() {
            vm.$router.push('/fallen-stock/' + vm.contextMenu.record._id);
          }
        },
        {
          icon: 'fa-trash',
          text: 'Delete',
          color: 'red',
          action() {
            vm.$router.push('/fallen-stock/' + vm.contextMenu.record._id + '/delete');
          }
        }
      ]
    }
  }),
  computed: {
    animals_data() {
      return this.animals || [];
    },
    fallen_stock_data() {
      return (this.fallen_stock || []).map(row => {
        // format data
        let animal = this.animals_data.find(animal => animal._id == row.id);
        row.displayId = animal ? animal.id : row.id;

        row.euthanizedFormatted = !row.euthanized ? 'No' : 'Yes';
        row.dateFormatted = new Date(row.date).toLocaleDateString();
        return row;
      })
    },
    contextMenuItems() {
      let record = this.contextMenu.record;
      return this.contextMenu.items.filter(item => !item.visible || (record && item.visible(record)));
    }
  },
  methods: {
    editRecord(record) {
      this.$router.push('/fallen-stock/' + record._id);
    },
    openContextMenu(event, row) {
      this.contextMenu.visible = true;
      this.contextMenu.x = event.x;
      this.contextMenu.y = event.y;
      this.contextMenu.record = row.item;
    }
  },
  pouch: {
    fallen_stock() {
      return {
        database: 'pig_manager',
        sort: [{date: "desc"}],
        selector: {type: 'fallenStock'}
      }
    },
    animals() {
      return {
        database: 'pig_manager',
        selector: {
          type: 'animal'
        }
      }
    }
  }
}
</script>

<style>
tbody tr {
  cursor: pointer;
}
</style>