<template>
  <v-container>
    <v-row v-if="!$vuetify.breakpoint.smAndUp">
      <v-col cols="6">
        <v-card to="/todo">
          <v-card-title class="counter">{{ todo_count }}</v-card-title>
          <v-card-text>{{ todo_pluralized }}</v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card to="/reminders">
          <v-card-title class="counter">{{ reminder_count }}</v-card-title>
          <v-card-text>{{ reminder_pluralized }}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="$vuetify.breakpoint.smAndUp" cols="3">
        <v-card to="/todo">
          <v-card-title class="counter">{{ todo_count }}</v-card-title>
          <v-card-text>{{ todo_pluralized }}</v-card-text>
        </v-card>
        <v-card to="/reminders" style="margin-top: 30px">
          <v-card-title class="counter">{{ reminder_count }}</v-card-title>
          <v-card-text>{{ reminder_pluralized }}</v-card-text>
        </v-card>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.smAndUp ? 9 : 12">
        <v-text-field ref="animalSearchField" v-model="animal_id" placeholder="Search by Animal ID" solo append-icon="fa-search" :rules="[rules.notInvalidAnimalId]" @keydown.enter="goToAnimal" @click:append="goToAnimal" />
        <v-card>
          <v-card-title><b>Records</b></v-card-title>
          <v-card-text>
            <v-list>
              <v-list-group v-for="record_group in record_groups" :key="record_group.name" :prepend-icon="record_group.icon" :no-action="$vuetify.breakpoint.name != 'xs'">
                <template v-slot:activator>
                  <v-list-item-content inactive :ripple="false">
                    <v-list-item-title v-text="record_group.name" />
                  </v-list-item-content>
                </template>
                <v-list-item v-for="record in record_group.records" :key="record" ripple :to="'/' + record.toLowerCase().replace(' ', '-')">
                  <v-list-item-content>
                    <v-list-item-title v-text="record" />
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Todos from '@/scripts/todos';

export default {
  name: 'Home',
  data: vm => ({
    animal_id: null,
    record_groups: [
      {
        name: 'Herd',
        icon: 'fa-book',
        records: ['Herd', 'Fallen Stock']
      },
      {
        name: 'Medicine',
        icon: 'fa-briefcase-medical',
        records: ['Medicine', 'Purchased Medicine', 'Discarded Medicine']
      },
      {
        name: 'Breeding',
        icon: 'fa-sync-alt',
        records: ['AI', 'Purchased Semen', 'Farrow']
      }
    ],
    reminders: [],
    todos: {},
    rules: {
      notInvalidAnimalId: value => !value || !!vm.animal || 'No animal found'
    }
  }),
  computed: {
    reminder_pluralized() {
      return this.reminders_count == 1 ? 'Reminder' : 'Reminders';
    },
    reminder_count() {
      return this.reminders.length;
    },
    todo_pluralized() {
      return this.todo_count == 1 ? 'To do item' : 'To do items';
    },
    todo_count() {
      return Object.values(this.todos).reduce((subtotal, type) => subtotal + (type.visible ? type.animals.filter(animal => animal.status == null).length : 0), 0);
    }
  },
  watch: {
    animal() {
      this.$refs.animalSearchField.validate();
    }
  },
  async created() {
    this.reminders = (await this.$pouch.getDB().query('app/reminders', {key: this.getDateToday()})).rows.filter(reminder => !reminder.value.completed);
    this.todos = await Todos.getTodos();
  },
  methods: {
    getDateToday() {
      return new Date().toISOString().slice(0, 10);
    },
    goToAnimal() {
      if(this.animal) this.$router.push('/animal/' + this.animal._id);
      else this.$refs.animalSearchField.focus();
    }
  },
  pouch: {
    animal() {
      return {
        database: 'pig_manager',
        selector: {id: this.animal_id, type: 'animal'},
        first: true,
        disabled: !this.animal_id
      }
    },
  }
}
</script>

<style scoped>
.counter {
  font-size: 40px;
  font-weight: bold
}

.col {
  padding: 15px;
}
</style>