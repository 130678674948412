<template>
  <div>
    <v-container>
      <portal to="app-bar-icons">
        <v-btn type="submit" icon @click="save()">
          <v-icon>fas fa-save</v-icon>
        </v-btn>
      </portal>
      <v-card v-if="todo" class="mb-4">
        <v-card-title><b>{{ todo.weekDay }}:&nbsp;</b>{{ todo.name }}</v-card-title>
        <v-card-text>
          <v-form ref="form" class="animal-row" @submit.prevent="save()">
            <read-only-input key="header-mother" label="Mother" compact align="center" />
            <read-only-input key="header-alive" label="Alive" compact align="center" />
            <read-only-input key="header-stillborn" label="Stillborn" compact align="center" />
            <read-only-input key="header-mummified" label="Mummified" compact align="center" />
            <read-only-input key="header-weaned" label="Weaned" compact align="center" />
            <div />
            <div v-for="i in 6" :key="'top-divider-' + i" :class="'mt-2 divider divider-' + i" />
            <template v-for="animal in todo.animals">
              <div :key="animal.id + '-chip'">
                <v-chip class="ma-1" :color="animal.status ? 'green' : animal.status === false ? 'red' : 'yellow'" :to="'/farrow/' + animal.breeding._id" v-text="animal.id" />
              </div>
              <read-only-input :key="animal.id + '-alive'" :value="animal.breeding.alive" compact align="center" />
              <read-only-input :key="animal.id + '-stillborn'" :value="animal.breeding.stillborn" compact align="center" />
              <read-only-input :key="animal.id + '-mummified'" :value="animal.breeding.mummified" compact align="center" />
              <read-only-input :key="animal.id + '-weaned'" :value="animal.numberStillAlive" compact align="center" />
              <v-checkbox :key="animal.id + '-confirm'" v-model="animal.pendingWeaned" class="compact-checkbox" />
            </template>
            <template v-if="todo.animals.length">
              <div v-for="i in 6" :key="'mid-divider-' + i" :class="'divider divider-' + i" />
              <read-only-input key="total-farrowed" :value="totals.farrowed" compact align="center" />
              <read-only-input key="total-alive" :value="totals.alive" compact align="center" />
              <read-only-input key="total-stillborn'" :value="totals.stillborn" compact align="center" />
              <read-only-input key="total-mummified'" :value="totals.mummified" compact align="center" />
              <read-only-input key="total-weaned'" :value="totals.weaned" compact align="center" />
              <div />
              <div v-for="i in 6" :key="'low-divider-' + i" :class="'divider divider-' + i" />
            </template>
            <template v-if="!todo.animals.length">
              No animals to process yet
            </template>
          </v-form>
          <div class="text-center black--text mt-4">Average Weaned: <b v-text="average_weaned" /></div>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Todos from '@/scripts/todos';
import ReadOnlyInput from '@/components/ReadOnlyInput';

export default {
  name: 'TodoWean',
  components: { ReadOnlyInput },
  data: vm => ({
    todo: null,
    breeding: [],
    pigletDeaths: [],
    isSaving: false,
    rules: {
      required: value => !vm.isEmptyProperty(value) || 'Required'
    }
  }),
  computed: {
    totals() {
      return this.todo.animals.reduce((summary, animal) => {
        return animal.pendingWeaned ? {
          farrowed: summary.farrowed + 1,
          alive: summary.alive + animal.breeding.alive,
          stillborn: summary.stillborn + animal.breeding.stillborn,
          mummified: summary.mummified + animal.breeding.mummified,
          weaned: summary.weaned + animal.numberStillAlive
        } : summary;
      }, {farrowed: 0, alive: 0, stillborn: 0, mummified: 0, weaned: 0});
    },
    average_weaned() {
      return this.totals && this.totals.farrowed ? Math.round(this.totals.weaned / this.totals.farrowed * 100) / 100 : 0;
    }
  },
  async created() {
    let todo = (await Todos.getTodos())[this.$route.params.key];
    let allBreedingIds = [...new Set(todo.animals.map(animal => animal.breeding._id))];
    let stillAlive = (await this.$pouch.getDB().query('app/farrow-alive', {group: true, keys: allBreedingIds})).rows;
    for(let [, animal] of todo.animals.entries()) {
      animal.pendingWeaned = animal.breeding.weaned != null;
      animal.numberStillAlive = stillAlive.find(item => item.key == animal.breeding._id).value;
    }
    this.todo = todo;
  },
  methods: {
    getDateToday() {
      return new Date().toISOString().slice(0, 10);
    },
    isEmptyProperty(value) {
      return value == null || value.toString().length == 0;
    },
    async save() {
      if(this.isSaving) return;
      if(!this.$refs.form.validate()) {
        let firstInvalidField = this.$refs.form.inputs.find(input => !input.valid)
        if(firstInvalidField) {
          firstInvalidField.focus();
          return;
        }
      }
      this.isSaving = true;
      let db = this.$pouch.getDB();
      
      Promise.all(this.todo.animals.map(animal => {
        if((animal.pendingWeaned && animal.breeding.weaned === animal.numberStillAlive) || (animal.breeding.weaned == null && !animal.pendingWeaned)) return Promise.resolve();

        animal.breeding.weaned = animal.pendingWeaned ? animal.numberStillAlive : null;
        animal.breeding.weanedDate = animal.pendingWeaned ? this.getDateToday() : null;
        return db.put(animal.breeding).then(res => {
          console.log(res);
        });
      })).then(() => {
        this.$store.dispatch('returnToLastRoute', '/todo');
      }).catch(err => {
        console.error(err);
        this.isSaving = false;
      });
    }
  }
}
</script>

<style scoped lang="scss">
.animal-row {
  display: grid;
  align-items: center;
  gap: 0 20px;
  grid-template-columns: max-content auto auto auto auto max-content;
  color: black;
}

.compact-checkbox {
  margin-top: 0;
  margin-bottom: -16px;
}

.divider {
  height: 1px;
  background: black;

  &:not(.divider-6) {
    margin-right: -20px;
  }
}
</style>