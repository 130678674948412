<template>
  <v-container>
    <portal to="app-bar-icons">
      <v-btn type="submit" icon @click="save()">
        <v-icon>fas fa-save</v-icon>
      </v-btn>
    </portal>
    <v-card>
      <v-card-text>
        <v-form ref="form" v-model="isValid" @submit.prevent="save()">
          <v-combobox id="editor_product" ref="productField" v-model="discardedMedicine.product" label="Product" :autofocus="!isEditing" :items="medicineProducts" :rules="[rules.required]" @keydown="handleEnterKey" @keyup="handleEnterKeyFinish" />
          <v-row>
            <v-col cols="6">
              <v-text-field id="editor_batchNo" v-model="discardedMedicine.batchNo" label="Batch No." :rules="[rules.required]" />
            </v-col>
            <v-col cols="6">
              <date-picker id="editor_expiryDate" v-model="discardedMedicine.expiryDate" label="Expiry Date" :rules="[rules.required]" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field id="editor_quantityDiscarded" v-model="discardedMedicine.quantityDiscarded" :label="'Discarded Qty (' + discardedMedicine.measurementUnit + ')'" type="number" :rules="[rules.required, rules.moreThanZero]" />
            </v-col>
            <v-col cols="6">
              <v-select id="editor_measurementUnit" ref="measurementUnitField" v-model="discardedMedicine.measurementUnit" label="Measurement Unit" :items="measurementUnits" :rules="[rules.required]" />
            </v-col>
          </v-row>
          <date-picker id="editor_date" v-model="discardedMedicine.date" label="Date Discarded" :max="getDateToday()" :rules="[rules.required]" />
          
          <v-btn type="submit" class="d-none" />
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import DatePicker from '@/components/DatePicker';
import Vue from 'vue';

export default {
  name: 'DiscardedMedicineEditor',
  components: {
    DatePicker
  },
  data: vm => ({
    isValid: null,
    isSaving: false,
    editing: false,
    copying: false,
    medicineProducts: [],
    discardedMedicine: {
      type: "discardedMedicine",
      product: null,
      batchNo: null,
      expiryDate: null,
      quantityDiscarded: null,
      measurementUnit: "ml",
      date: vm.getDateToday()
    },
    measurementUnits: [
      {text: 'Millilitres (ml)', value: 'ml'},
      {text: 'Kilograms (kg)', value: 'kg'}
    ],
    rules: {
      required: value => (value != null && value.toString().length > 0) || 'Required',
      notNegative: value => value >= 0 || 'Number cannot be less than zero',
      moreThanZero: value => value > 0 || 'Number must be more than zero'
    }
  }),
  computed: {
    isEditing() {
      return !!this.$route.params.id;
    }
  },
  watch: {
    existing_record() {
      if(!this.editing && this.existing_record) {
        this.discardedMedicine = this.existing_record;
        this.editing = true;
      }
    },
    template_record() {
      if(!this.isEditing && !this.copying && this.template_record) {
        this.discardedMedicine.product = this.template_record.product;
        this.discardedMedicine.batchNo = this.template_record.batchNo;
        this.discardedMedicine.expiryDate = this.template_record.expiryDate;
        this.discardedMedicine.quantityDiscarded = this.template_record.remainingQuantity;
        this.discardedMedicine.measurementUnit = this.template_record.measurementUnit;
        this.copying = true;
      }
    }
  },
  async created() {
    this.medicineProducts = (await this.$pouch.getDB().query('app/medicine-products', {group:true})).rows.map(row => row.key);
  },
  methods: {
    getDateToday() {
      return new Date().toISOString().slice(0, 10);
    },
    save() {
      if(this.isSaving) return;
      if(this.$refs.productField.isFocused && this.$refs.productField.value != this.$refs.productField.internalSearch) {
        // the value isn't usually updated until the combobox loses focus
        this.$refs.productField.setValue(this.$refs.productField.internalSearch);
      }
      if(!this.isValid) {
        this.$refs.form.validate();
        let firstInvalidField = this.$refs.form.inputs.find(input => !input.valid)
        if(firstInvalidField) {
          firstInvalidField.focus();
          return;
        }
      }
      this.isSaving = true;
      this.discardedMedicine.quantityDiscarded = Number(this.discardedMedicine.quantityDiscarded);

      let db = this.$pouch.getDB();
      let method = this.isEditing ? db.put : db.post;
      method(this.discardedMedicine).then((res) => {
        console.log(res);
        this.$router.replace('/discarded-medicine'); // always go here, even if we copied a record from purchased medicine
      }).catch(err => {
        console.error(err);
      });
    },
    handleEnterKey(event) {
      if(event.code === "Enter") {
        let el = event.target;
        while(!el.__vue__ && el.parentNode) el = el.parentNode;
        if(el.__vue__.internalSearch && el.__vue__.value == el.__vue__.internalSearch) {
          event.preventDefault();
          this.save();
        }
      }
    },
    handleEnterKeyFinish(event) {
      if(event.code === "Enter") {
        let el = event.target;
        while(!el.__vue__ && el.parentNode) el = el.parentNode;
        if(el.__vue__.internalSearch && el.__vue__.value == el.__vue__.internalSearch) {
          Vue.nextTick(() => {
            el.__vue__.isMenuActive = false;
          });
        }
      }
    }
  },
  pouch: {
    existing_record() {
      return {
        database: 'pig_manager',
        selector: {
          type: 'discardedMedicine',
          _id: this.$route.params.id
        },
        first: true,
        disabled: !this.isEditing
      }
    },
    template_record() {
      return {
        database: 'pig_manager',
        selector: {
          type: 'purchasedMedicine',
          _id: this.$route.query.copy
        },
        first: true,
        disabled: this.isEditing || !this.$route.query.copy
      }
    }
  }
}
</script>

<style scoped>
.row {
  margin-top: 0;
  margin-bottom: 0;
}

.col {
  padding-top: 0;
  padding-bottom: 0;
}
</style>