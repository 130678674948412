<template>
  <v-app>
    <app-bar />

    <v-main class="main">
      <v-container fluid>
        <reauth />
        <router-view />
        <component :is="$route.meta.modal" v-if="$route.meta.modal" v-bind="$attrs" />
      </v-container>
    </v-main>
  </v-app>
</template>

<style>
html {
  overflow-y: auto; /* override a default from vuetify's _reset.scss */
}
.main {
  background: #E6E6E6;
}
.subheading {
  font-size: 14px;
}
</style>

<script>
import AppBar from './components/AppBar.vue';
import Reauth from './components/Reauth.vue';

export default {
  name: 'App',
  components: {
    AppBar,
    Reauth
  }
}
</script>
