<template>
  <div>
    <v-container>
      <portal to="app-bar-icons">
        <v-btn type="submit" icon @click="save()">
          <v-icon>fas fa-save</v-icon>
        </v-btn>
      </portal>
      <v-card v-if="todo" class="mb-4">
        <v-card-title><b>{{ todo.weekDay }}:&nbsp;</b>{{ todo.name }}</v-card-title>
        <v-card-text>
          <v-form ref="form" class="animal-row" @submit.prevent="save()">
            <template v-for="animal in todo.animals">
              <div :key="animal.id + '-chip'">
                <v-chip class="ma-1" :color="animal.status ? 'green' : animal.status === false ? 'red' : 'yellow'" :to="'/ai/scan/' + animal.breeding._id" v-text="animal.id" />
              </div>
              <v-radio-group :key="animal.id + '-options'" v-model="animal.pendingScanResult" row>
                <v-radio label="Negative" :value="false" />
                <v-radio label="Not Scanned" :value="null" />
                <v-radio label="Positive" :value="true" />
              </v-radio-group>
            </template>
            <template v-if="!todo.animals.length">
              No animals to process yet
            </template>
          </v-form>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Todos from '@/scripts/todos';

export default {
  name: 'TodoScan',
  data: vm => ({
    todo: null,
    isSaving: false,
    rules: {
      required: value => !vm.isEmptyProperty(value) || 'Required'
    }
  }),
  async created() {
    let todo = (await Todos.getTodos())[this.$route.params.key];
    for(let [, animal] of todo.animals.entries()) {
      animal.pendingScanResult = animal.breeding.scanResult !== undefined ? animal.breeding.scanResult : null;
    }
    this.todo = todo;
  },
  methods: {
    getDateToday() {
      return new Date().toISOString().slice(0, 10);
    },
    isEmptyProperty(value) {
      return value == null || value.toString().length == 0;
    },
    async save() {
      if(this.isSaving) return;
      if(!this.$refs.form.validate()) {
        let firstInvalidField = this.$refs.form.inputs.find(input => !input.valid)
        if(firstInvalidField) {
          firstInvalidField.focus();
          return;
        }
      }
      this.isSaving = true;
      let db = this.$pouch.getDB();
      
      Promise.all(this.todo.animals.map(animal => {
        if(animal.breeding.scanResult == animal.pendingScanResult) return Promise.resolve();
        animal.breeding.scanResult = animal.pendingScanResult;
        animal.breeding.scanDate = animal.pendingScanResult != null ? this.getDateToday() : null;
        return db.put(animal.breeding).then(res => {
          console.log(res);
        });
      })).then(() => {
        this.$store.dispatch('returnToLastRoute', '/todo');
      }).catch(err => {
        console.error(err);
        this.isSaving = false;
      });
    }
  }
}
</script>

<style scoped>
.animal-row {
  display: grid;
  align-items: center;
  gap: 0 10px;
  grid-template-columns: max-content auto;
  color: black;
}
</style>