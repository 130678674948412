<template>
  <v-dialog v-model="modalOpen" max-width="500">
    <v-card class="mx-auto" max-width="500">
      <v-form v-model="isValid" @submit.prevent="nextStep()">
        <v-window v-model="step" touchless>
          <v-window-item :value="1">
            <v-card-text>
              <v-text-field v-model="farmId" label="Farm" autofocus :rules="[rules.required]" />
              <span class="text-caption grey--text text--darken-1">
                Enter your farm's account identifier
              </span>
            </v-card-text>
          </v-window-item>

          <v-window-item :value="2">
            <v-card-text>
              <v-alert v-if="login.error" dense outlined type="error">
                <b>Error</b>: {{ login.error }}
              </v-alert>
              <v-text-field v-model="user" autocomplete="username" label="Username" type="text" :autofocus="!user" :rules="step == 2 ? [rules.required] : []" />
              <v-text-field v-model="pass" autocomplete="current-password" label="Password" type="password" :autofocus="!!user" :rules="step == 2 ? [rules.required] : []" />
              <span class="text-caption grey--text text--darken-1">
                Log in to your <b>{{ farmId }}</b> farm account
              </span>
            </v-card-text>
          </v-window-item>

          <v-window-item :value="3">
            <div class="pa-4 text-center">
              <v-img class="mb-4" contain height="128" src="/img/icons/icon-512.png" />
              <h3 class="text-h6 font-weight-light mb-2">
                Welcome to Pig Manager
              </h3>
              <div class="text-caption grey--text">
                {{ login.status }}
              </div>
              <v-progress-linear indeterminate color="primary lighten-2" class="mt-5" />
            </div>
          </v-window-item>
        </v-window>

        <v-divider />

        <v-card-actions v-if="step !== 3">
          <v-btn text @click="previousStep()">
            {{ step === 1 || $store.state.loggedIn ? 'Cancel' : 'Back' }}
          </v-btn>
          <v-spacer />
          <v-btn color="primary" depressed type="submit" :disabled="!isValid">
            {{ step === 2 ? 'Log In' : 'Next' }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import router from '@/router';

export default {
  name: "LogIn",
  data: () => ({
    modalOpen: true,
    step: !localStorage.getItem('farmId') ? 1 : 2,
    farmId: localStorage.getItem('farmId'),
    user: localStorage.getItem('user'),
    pass: '',
    isValid: false,
    login: {
      status: 'Please wait while we log you in!',
      error: ''
    },
    rules: {
      required: value => !!value || 'Required'
    }
  }),
  watch: {
    modalOpen(newVal) {
      if(!newVal) {
        router.replace(this.$store.state.loggedIn ? router.currentRoute.query.redirect || '/' : '/about');
      }
    }
  },
  methods: {
    previousStep() {
      if(this.step === 1) {
        router.replace('/about');
      } else if(this.$store.state.loggedIn) {
        router.replace(router.currentRoute.query.redirect || '/');
      } else {
        this.step--;
      }
    },
    nextStep() {
      this.step++;
      if(this.step === 3) {
        this.$store.dispatch('logIn', {
          farmId: this.farmId,
          user: this.user,
          pass: this.pass,
          loginData: this.login
        }).then(() => {
          router.replace(router.currentRoute.query.redirect || '/');
        }).catch(msg => {
          console.error(msg);
          this.login.error = msg.charAt(0).toUpperCase() + msg.slice(1);
          this.step--;
        });
      }
    }
  }
};
</script>