<template>
  <v-container>
    <portal to="app-bar-icons">
      <v-btn icon to="/purchased-semen/add">
        <v-icon>fas fa-plus-circle</v-icon>
      </v-btn>
    </portal>
    <v-data-table :headers="headers" :items="purchased_semen_data" item-key="_id" class="elevation-2" mobile-breakpoint="0" @click:row="editRecord" @contextmenu:row.prevent="openContextMenu" />
    <v-menu v-model="contextMenu.visible" absolute :position-x="contextMenu.x" :position-y="contextMenu.y" offset-y>
      <v-list>
        <v-list-item v-for="item in contextMenu.items" :key="item.text" :ripple="item.color ? {class: item.color + '--text'} : true" @click="item.action">
          <v-list-item-icon><v-icon :color="item.color" v-text="item.icon" /></v-list-item-icon>
          <v-list-item-title :class="item.color ? item.color + '--text' : ''" v-text="item.text" />
        </v-list-item>
      </v-list>
    </v-menu>
  </v-container>
</template>

<script>
export default {
  name: 'PurchasedSemen',
  data: vm => ({
    headers: [
      {text: 'Date Purchased', value: 'date'},
      {text: 'Breed', value: 'breed'},
      {text: 'Batch No', value: 'batchNo'},
      {text: 'Boar ID', value: 'boarId'},
      {text: 'Qty', value: 'quantity'},
      {text: 'Left', value: 'remaining'},
      {text: 'Supplier Name', value: 'supplierName'},
      {text: 'Supplier Details', value: 'supplierDetails'}
    ],
    contextMenu: {
      visible: false,
      x: 0,
      y: 0,
      recordId: null,
      items: [
        {
          icon: 'fa-pencil-alt',
          text: 'Edit',
          action() {
            vm.$router.push('/purchased-semen/' + vm.contextMenu.recordId);
          }
        },
        {
          icon: 'fa-trash',
          text: 'Delete',
          color: 'red',
          action() {
            vm.$router.push('/purchased-semen/' + vm.contextMenu.recordId + '/delete');
          }
        }
      ]
    }
  }),
  computed: {
    suppliers_data() {
      return this.suppliers || [];
    },
    purchased_semen_data() {
      return (this.purchased_semen || []).map(row => {
        let supplier = this.suppliers_data.find(supplier => supplier._id == row.supplier);
        if(supplier) {
          row.supplierName = supplier.name;
          row.supplierDetails = supplier.details;
        }
        // format date for user's locale
        if(/[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(row.date)) {
          row.date = new Date(row.date).toLocaleDateString();
        }
        return row;
      })
    }
  },
  methods: {
    editRecord(record) {
      this.$router.push('/purchased-semen/' + record._id);
    },
    openContextMenu(event, row) {
      this.contextMenu.visible = true;
      this.contextMenu.x = event.x;
      this.contextMenu.y = event.y;
      this.contextMenu.recordId = row.item._id;
    }
  },
  pouch: {
    purchased_semen() {
      return {
        database: 'pig_manager',
        sort: [{date: "desc"}],
        selector: {type: 'purchasedSemen'}
      }
    },
    suppliers() {
      return {
        database: 'pig_manager',
        selector: {type: 'supplier'}
      }
    }
  }
}
</script>

<style>
tbody tr {
  cursor: pointer;
}
</style>