<template>
  <v-dialog v-model="modalOpen" max-width="500">
    <v-card class="mx-auto" max-width="500">
      <v-form @submit.prevent="performDelete()">
        <v-card-text>
          <v-alert v-if="!canDelete" dense outlined type="error">
            <b>Error</b>: {{ recordExists ? 'This ' + recordType + ' record already has a record associated with it. All related records must be deleted first before this one can be deleted.' : 'The requested record could not be found. It may have already been deleted.' }}
          </v-alert>
          <b v-if="canDelete">Are you sure you want to delete this {{ recordType }} record?</b>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn text @click="$router.back()">
            Cancel
          </v-btn>
          <v-btn color="red" depressed type="submit" :disabled="!canDelete" :dark="canDelete">
            Delete
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeleteRecord",
  data: () => ({
    modalOpen: true
  }),
  computed: {
    recordType() {
      return this.$route.meta.type;
    },
    recordTypeCamel() {
      // Adapted from: https://stackoverflow.com/a/24916469/1476989
      return this.recordType.split(' ').map(
        (word, i) => (word[0] || '')[i == 0 ? 'toLowerCase' : 'toUpperCase']() + word.substr(1).toLowerCase()
      ).join('');
    },
    recordExists() {
      return !!this.existing_record && !!this.existing_record._id;
    },
    hasExistingRecords() {
      return this.recordExists && !!this.existing_id_record && this.existing_id_record.length > 0;
    },
    canDelete() {
      return this.recordExists && (!this.hasExistingRecords || this.$route.meta.farrow);
    },
    existingRecordId() {
      return this.existing_record ? this.existing_record._id : null;
    },
    existingRecordConditions() {
      switch(this.recordTypeCamel) {
        case 'animal': return { // AI, medicine, fallen stock
          $not: {type: "animal"},
          $or: [
            {id: this.existingRecordId},
            {semenOne: this.existingRecordId},
            {semenTwo: this.existingRecordId},
            {animals: {$elemMatch: {$in: [this.existingRecordId]}}}
          ]
        }
        case 'purchasedMedicine': return { // medicine
          type: 'medicine',
          product: this.existingRecordId
        }
        case 'breeding': return {
          farrowId: this.existingRecordId
        }
        case 'purchasedSemen': return { // AI
          $or: [
            {semenOne: this.existingRecordId},
            {semenTwo: this.existingRecordId}
          ]
        }
      }
      return {}
    }
  },
  watch: {
    modalOpen(newVal) {
      if(!newVal) {
        this.$router.back();
      }
    }
  },
  created: function() {
    document.addEventListener('keyup', this.enterKeyListener);
  },
  destroyed: function() {
    document.removeEventListener('keyup', this.enterKeyListener);
  },
  methods: {
    enterKeyListener(event) {
      if(event.key == "Enter") this.performDelete();
    },
    performDelete() {
      if(!this.canDelete) return;

      let db = this.$pouch.getDB();
      if(this.$route.meta.farrow) {
        Promise.all(this.existing_id_record.map(record => {
          return db.remove(record).then(console.log).catch(console.error);
        })).then(() => {
          delete this.existing_record.farrowDate;
          delete this.existing_record.farrowComments;
          delete this.existing_record.alive;
          delete this.existing_record.stillborn;
          delete this.existing_record.mummified;
          delete this.existing_record.weaned;
          db.put(this.existing_record);
        });
      } else {
        this.$pouch.getDB().remove(this.existing_record);
      }
      this.modalOpen = false;
    }
  },
  pouch: {
    existing_record() {
      return {
        database: 'pig_manager',
        selector: {
          type: this.recordTypeCamel,
          _id: this.$route.params.id
        },
        first: true
      }
    },
    existing_id_record() {
      return {
        database: 'pig_manager',
        selector: this.existingRecordConditions,
        disabled: !this.existing_record || Object.keys(this.existingRecordConditions).length === 0
      }
    }
  }
};
</script>