<template>
  <div>
    <v-container>
      <portal to="app-bar-icons">
        <v-btn type="submit" icon @click="save()">
          <v-icon>fas fa-save</v-icon>
        </v-btn>
      </portal>
      <v-card v-if="todo" class="mb-4">
        <v-card-title><b>{{ todo.weekDay }}:&nbsp;</b>{{ todo.name }}</v-card-title>
        <v-card-text>
          <div v-if="todo.target == 'mother'">
            <v-chip v-for="animal in todo.animals" :key="animal._id" class="ma-1" :color="animal.status ? 'green' : animal.status === false ? 'red' : 'yellow'" :to="'/farrow/' + animal.breeding._id" v-text="animal.id" />
          </div>
          <div v-else class="animals-grid">
            <template v-if="todo.animals.length">
              <read-only-input key="header-mother" label="Mother" compact align="center" />
              <read-only-input key="header-alive" label="Alive" compact align="center" />
              <read-only-input key="header-stillborn" label="Stillborn" compact align="center" />
              <read-only-input key="header-mummified" label="Mummified" compact align="center" />
              <read-only-input key="header-stillAlive" label="Still Alive" compact align="center" />
              <div v-for="i in 5" :key="'top-divider-' + i" :class="'mt-2 divider divider-' + i" />
            </template>
            <template v-for="animal in todo.animals">
              <div :key="animal.id + '-chip'">
                <v-chip class="ma-1" :color="animal.status ? 'green' : animal.status === false ? 'red' : 'yellow'" :to="'/farrow/' + animal.breeding._id" v-text="animal.id" />
              </div>
              <read-only-input :key="animal.id + '-alive'" :value="animal.breeding.alive" compact align="center" />
              <read-only-input :key="animal.id + '-stillborn'" :value="animal.breeding.stillborn" compact align="center" />
              <read-only-input :key="animal.id + '-mummified'" :value="animal.breeding.mummified" compact align="center" />
              <read-only-input :key="animal.id + '-stillAlive'" :value="animal.numberStillAlive" compact align="center" />
            </template>
            <template v-if="todo.animals.length">
              <div v-for="i in 5" :key="'mid-divider-' + i" :class="'divider divider-' + i" />
              <read-only-input key="total-farrowed" :value="piglet_totals.farrowed" compact align="center" />
              <read-only-input key="total-alive" :value="piglet_totals.alive" compact align="center" />
              <read-only-input key="total-stillborn'" :value="piglet_totals.stillborn" compact align="center" />
              <read-only-input key="total-mummified'" :value="piglet_totals.mummified" compact align="center" />
              <b><read-only-input key="total-stillAlive'" :value="piglet_totals.stillAlive" compact align="center" /></b>
              <div v-for="i in 5" :key="'low-divider-' + i" :class="'divider divider-' + i" />
            </template>
          </div>
          <template v-if="!todo.animals.length">
            No animals to process yet
          </template>
          <div v-else class="mt-4 vaccines-grid">
            <template v-for="product in todo.productData">
              <b :key="product.name" v-text="product.name" />
              <template v-for="[i, medicine] in product.records.entries()">
                <div :key="product.name + '-' + medicine._id + '-details'" v-text="'BN ' + medicine.productData.batchNo + ' (on ' + new Date(medicine.startDate).toLocaleDateString() + ')'" />
                <div :key="product.name + '-' + medicine._id + '-qty'" v-text="medicine.animalQuantity" />
                <div :key="product.name + '-' + medicine._id + '-icon'">
                  <v-btn icon :to="'/medicine/' + medicine._id">
                    <v-icon small>fa-pencil-alt</v-icon>
                  </v-btn>
                  <v-btn icon :to="'/medicine/' + medicine._id + '/delete'">
                    <v-icon small>fa-trash</v-icon>
                  </v-btn>
                </div>
                <div v-if="getUnvaccinatedQty(product) > 0 || i < product.records.length - 1" :key="product.name + '-' + medicine._id + '-spacer'" />
              </template>
              <template v-if="getUnvaccinatedQty(product) > 0">
                <div :key="product.name + '-details'">Unvaccinated</div>
                <div :key="product.name + 'qty'" v-text="getUnvaccinatedQty(product)" />
                <v-btn :key="product.name + '-icon'" icon :to="getCreatePath(todo, product)">
                  <v-icon small>fa-plus-square</v-icon>
                </v-btn>
              </template>
            </template>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Todos from '@/scripts/todos';
import ReadOnlyInput from '@/components/ReadOnlyInput';

export default {
  name: 'TodoMedicine',
  components: { ReadOnlyInput },
  data: vm => ({
    todo: null,
    isSaving: false,
    rules: {
      required: value => !vm.isEmptyProperty(value) || 'Required'
    }
  }),
  computed: {
    piglet_totals() {
      return this.todo.animals.reduce((summary, animal) => {
        return {
          farrowed: summary.farrowed + 1,
          alive: summary.alive + animal.breeding.alive,
          stillborn: summary.stillborn + animal.breeding.stillborn,
          mummified: summary.mummified + animal.breeding.mummified,
          stillAlive: summary.stillAlive + animal.numberStillAlive
        };
      }, {farrowed: 0, alive: 0, stillborn: 0, mummified: 0, stillAlive: 0});
    },
    total_quantity() {
      return this.todo.target == 'mother' ? this.piglet_totals.farrowed : this.piglet_totals.stillAlive;
    }
  },
  watch: {
    '$route': {
      immediate: true,
      async handler() {
        if(!this.$route.meta.modal) {
          this.todo = (await Todos.getTodos())[this.$route.params.key];
        }
      }
    }
  },
  methods: {
    getDateToday() {
      return new Date().toISOString().slice(0, 10);
    },
    isEmptyProperty(value) {
      return value == null || value.toString().length == 0;
    },
    getCreatePath(todo, product) {
      return "/medicine/add?product=" + encodeURIComponent(product.name) + "&reason=" + (encodeURIComponent(todo.reason) || '') + "&animals=" + (todo.target == "mother" ? todo.animals.filter(animal => !animal.status).map(animal => animal.task.doc._id) : "Piglets&qty=" + this.getUnvaccinatedQty(product));
    },
    getUnvaccinatedQty(product) {
      return this.total_quantity - product.total;
    }
  }
}
</script>

<style scoped lang="scss">
.animals-grid, .vaccines-grid {
  display: grid;
  align-items: center;
  gap: 0 20px;
  grid-template-columns: max-content auto auto auto auto;
  color: black;
}

.vaccines-grid {
  grid-template-columns: max-content minmax(auto, max-content) max-content max-content;
}

.compact-checkbox {
  margin-top: 0;
  margin-bottom: -16px;
}

.divider {
  height: 1px;
  background: black;

  &:not(.divider-5) {
    margin-right: -20px;
  }
}
</style>