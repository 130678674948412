<template>
  <v-container>
    <portal to="app-bar-icons">
      <v-btn icon to="/farrow/add">
        <v-icon>fas fa-plus-circle</v-icon>
      </v-btn>
    </portal>
    <v-data-table :headers="headers" :items="breeding_data" item-key="_id" :item-class="(item) => item.scanResult === false ? 'repeat-row' : null" class="elevation-2" mobile-breakpoint="0" @click:row="editRecord" @contextmenu:row.prevent="openContextMenu">
      <template v-slot:item.scanResultFormatted="props">
        <div @click.stop="$router.push('/farrow/scan/' + props.item._id)" v-text="props.item.scanResultFormatted" />
      </template>
    </v-data-table>
    <v-menu v-model="contextMenu.visible" absolute :position-x="contextMenu.x" :position-y="contextMenu.y" offset-y>
      <v-list>
        <v-list-item v-for="item in contextMenu.items" :key="item.text" :ripple="item.color ? {class: item.color + '--text'} : true" @click="item.action">
          <v-list-item-icon><v-icon :color="item.color" v-text="item.icon" /></v-list-item-icon>
          <v-list-item-title :class="item.color ? item.color + '--text' : ''" v-text="item.text" />
        </v-list-item>
      </v-list>
    </v-menu>
  </v-container>
</template>

<script>
export default {
  name: 'Farrow',
  components: {
    
  },
  data: vm => ({
    headers: [
      {text: 'Animal ID', value: 'displayId'},
      {text: 'Farrow Date', value: 'farrowDateFormatted'},
      {text: 'Alive', value: 'alive'},
      {text: 'Stillborn', value: 'stillborn'},
      {text: 'Mummified', value: 'mummified'},
      {text: 'Total Born', value: 'totalBorn'},
      {text: 'Weaned', value: 'weaned'},
      {text: 'Comments', value: 'farrowComments'}
    ],
    contextMenu: {
      visible: false,
      x: 0,
      y: 0,
      recordId: null,
      items: [
        {
          icon: 'fa-pencil-alt',
          text: 'Edit',
          action() {
            vm.$router.push('/farrow/' + vm.contextMenu.recordId);
          }
        },
        {
          icon: 'fa-trash',
          text: 'Delete',
          color: 'red',
          action() {
            vm.$router.push('/farrow/' + vm.contextMenu.recordId + '/delete');
          }
        }
      ]
    }
  }),
  computed: {
    animals_data() {
      return this.animals || [];
    },
    breeding_data() {
      return (this.breeding || []).map(row => {
        // format data
        let animal = this.animals_data.find(animal => animal._id == row.id);
        if(animal) row.displayId = animal.id;

        // format dates for user's locale
        if(/[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(row.farrowDate)) {
          row.farrowDateFormatted = new Date(row.farrowDate).toLocaleDateString();
        }
        return row;
      })
    }
  },
  methods: {
    editRecord(record) {
      this.$router.push('/farrow/' + record._id);
    },
    openContextMenu(event, row) {
      this.contextMenu.visible = true;
      this.contextMenu.x = event.x;
      this.contextMenu.y = event.y;
      this.contextMenu.recordId = row.item._id;
    },
    getDaysBeforeDueDate(record, daysBeforeDue) {
      // x days before due date of 114 day gestation
      let date = new Date(record.dateOne);
      date.setDate(date.getDate() + 114 - daysBeforeDue);
      return date;
    }
  },
  pouch: {
    breeding() {
      return {
        database: 'pig_manager',
        sort: [{farrowDate: "desc"}],
        selector: {
          type: 'breeding',
          farrowDate: {$type: 'string'}
        }
      }
    },
    animals() {
      return {
        database: 'pig_manager',
        selector: this.isEditing ? {_id: this.breeding.id} : {
          type: 'animal',
          animalType: 'Sow/gilt'
        }
      }
    }
  }
}
</script>

<style>
tbody tr {
  cursor: pointer;
}
</style>