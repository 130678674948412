<template>
  <v-container>
    <v-card class="home">
      <v-card-title>What does Pig Manager do?</v-card-title>
      <v-card-text>Pig Manager is an electronic pig farm record manager, and is able to provide easy storage, retrieval, analysis and input of all common farm records.</v-card-text>
      <v-card-title>Why Pig Manager?</v-card-title>
      <v-card-text>
        <ul>
          <li>An intuitive, modern, user-friendly interface</li>
          <li>Runs securely in your web browser for maximum device compatibility - supports mobile, tablet, PC and most modern devices with a web browser</li>
          <li>Offline support ensures the app functions without a hitch even in areas with low connectivity</li>
          <li>Real-time, instant sync between all connected devices</li>
          <li>Reminders and weekly to-do lists that are actionable to default the majority of data for input</li>
        </ul>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'About'
}
</script>
