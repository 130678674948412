<template>
  <v-container>
    <portal to="app-bar-icons">
      <v-btn type="submit" icon @click="save()">
        <v-icon>fas fa-save</v-icon>
      </v-btn>
    </portal>
    <v-card>
      <v-card-text>
        <v-form ref="form" v-model="isValid" @submit.prevent="save()">
          <v-autocomplete id="editor_product" ref="productField" v-model="medicine.product" label="Product" :autofocus="!isEditing" :items="purchased_medicine_data" item-value="_id" :rules="[rules.required]">
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title v-text="data.item.product + ' - ' + data.item.remainingQuantity + data.item.measurementUnit + ' remaining'" />
                <v-list-item-subtitle v-text="'BN ' + data.item.batchNo + ' (expires ' + data.item.expiryDateFormatted + ')'" />
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <date-picker id="editor_startDate" v-model="medicine.startDate" label="Start Date" :max="getDateToday()" :rules="[rules.required]" :clearable="false" @input="$refs.form.validate()" />
          <v-combobox id="editor_animals" ref="animalsField" v-model="selectedAnimals" label="Animal IDs" :items="animals_data" item-text="id" :rules="[rules.required, rules.selectedAnimalsError]" clearable multiple chips @change="sortSelectedAnimals">
            <template v-slot:prepend-item>
              <v-list-item ripple @click="selectAllAnimals()">
                <v-list-item-action>
                  <v-simple-checkbox :ripple="false" :value="are_all_animal_results_selected" />
                </v-list-item-action>
                <v-list-item-content><b>Select All</b></v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:selection="data">
              <v-chip v-bind="data.attrs" :input-value="data.selected" :title="data.item.error" :color="data.item.error ? 'red' : ''" close @click="data.select" @click:close="removeAnimal(data.item)">
                {{ data.item.id || data.item }}
              </v-chip>
            </template>
          </v-combobox>
          <v-row>
            <v-col :cols="$vuetify.breakpoint.smAndUp ? 4 : 6">
              <v-text-field id="editor_doseAmount" ref="doseAmountField" v-model="medicine.doseAmount" :label="'Dose Amount (' + measurement_unit + ')'" type="number" :rules="[rules.required, rules.notNegative, rules.insufficientQuantity]" />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.smAndUp ? 4 : 6">
              <v-text-field v-if="has_text_input" id="editor_animalQuantity" v-model="medicine.animalQuantity" label="No. Treated" type="number" :rules="[rules.required, rules.moreThanZero, rules.atLeastAnimalQuantity]" />
              <read-only-input v-if="!has_text_input" id="editor_animalQuantity" :value="medicine.animalQuantity" label="No. Treated" />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.smAndUp ? 4 : 12">
              <read-only-input id="editor_dailyAmount" :value="daily_qty_used" :label="'Daily Amount (' + measurement_unit + ')'" />
            </v-col>
          </v-row>
          <v-text-field id="editor_reason" v-model="medicine.reason" label="Reason" :rules="[rules.required]" />
          <date-picker id="editor_endDate" ref="endDateField" v-model="medicine.endDate" label="Treatment End Date" :min="medicine.startDate" :max="getDateToday()" :rules="[rules.required, rules.endDateNotBeforeStartDate]" :clearable="false" />
          <v-checkbox v-model="medicine.reminders" label="Remind me tomorrow" :value="getRelativeDate(1)" dense />
          <v-checkbox v-model="medicine.reminders" label="Remind me in 2 days" :value="getRelativeDate(2)" dense />
          <read-only-input label="Clearance Date" :value="medicine.clearanceDate ? new Date(medicine.clearanceDate).toLocaleDateString() : '-'" />
          <read-only-input id="editor_totalQuantityUsed" label="Total Quantity Used" :value="medicine.totalQuantityUsed + measurement_unit" />

          <v-btn type="submit" class="d-none" />
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import DatePicker from '@/components/DatePicker';
import ReadOnlyInput from '@/components/ReadOnlyInput';
import Vue from 'vue';

export default {
  name: 'MedicineEditor',
  components: {
    DatePicker,
    ReadOnlyInput
  },
  data: vm => ({
    isLoaded: false,
    isSaving: false,
    hasLoadedParams: false,
    isValid: null,
    editing: false,
    hasSelectedAnimalErrors: false,
    selectedAnimals: [],
    originalQuantity: 0,
    productFilter: null,
    medicine: {
      type: "medicine",
      product: null,
      animals: [],
      animalQuantity: 0,
      startDate: vm.getDateToday(),
      endDate: vm.getDateToday(),
      clearanceDate: vm.getDateToday(),
      doseAmount: null,
      totalQuantityUsed: 0,
      reason: null,
      reminders: []
    },
    rules: {
      required: value => !vm.isEmptyProperty(value) || 'Required',
      selectedAnimalsError: () => !vm.hasSelectedAnimalErrors || 'Hover over animals highlighted in red for error details',
      notNegative: value => value >= 0 || 'Number cannot be less than zero',
      moreThanZero: value => value > 0 || 'Number must be more than zero',
      atLeastAnimalQuantity: value => Number(value) >= vm.selectedAnimals.length || 'Quantity cannot be less than number of animals selected',
      endDateNotBeforeStartDate: value => (vm.isEmptyProperty(value) || vm.isEmptyProperty(vm.medicine.startDate) || vm.medicine.endDate >= vm.medicine.startDate) || 'End Date cannot be before start Date',
      insufficientQuantity: () => {
        if(!vm.selected_medicine_product) return true;
        let remaining = vm.selected_medicine_product.remainingQuantity;
        if(vm.isEditing) remaining += vm.originalQuantity;
        return remaining >= vm.medicine.totalQuantityUsed || vm.medicine.totalQuantityUsed + vm.measurement_unit + ' not available (' + remaining + vm.measurement_unit + ' total remaining)';
      }
    }
  }),
  computed: {
    isEditing() {
      return !!this.$route.params.id;
    },
    animals_data() {
      let animals = this.animals || [];
      if(this.$route.query.animals) {
        let allowedIds = this.$route.query.animals.split(',');
        animals = animals.filter(animal => allowedIds.includes(animal._id));
      }
      return animals;
    },
    purchased_medicine_data() {
      let products = this.purchased_medicine || [];
      if(this.productFilter) products = products.filter(product => product.product == this.productFilter);
      return products.map(record => {
        record.expiryDateFormatted = new Date(record.expiryDate).toLocaleDateString();
        record.text = record.product + ', BN: ' + record.batchNo + ', Exp Date: ' + record.expiryDateFormatted;
        return record;
      });
    },
    overlapping_medicine_data() {
      return this.overlapping_medicine || [];
    },
    validate_selected_animals() {
      return [this.overlapping_medicine_data, this.selectedAnimals, this.selected_medicine_product];
    },
    are_all_animal_results_selected() {
      return this.isLoaded && this.$refs.animalsField.filteredItems.find(item =>
        this.selectedAnimals.find(selected => selected._id == item._id) == null // find visible item that isn't selected
      ) == null; // true if there are no visible items that aren't selected
    },
    has_text_input() {
      return this.selectedAnimals.find(selected => typeof selected == 'string') != null;
    },
    selected_medicine_product() {
      return this.purchased_medicine_data.find(record => record._id == this.medicine.product);
    },
    measurement_unit() {
      return this.selected_medicine_product ? this.selected_medicine_product.measurementUnit : 'ml';
    },
    treatment_duration_days() {
      let startDate = new Date(this.medicine.startDate), endDate = new Date(this.medicine.endDate);
      let days = Math.ceil((endDate - startDate) / 86400000) + 1; // inclusive of last day
      return days < 1 ? 1 : days;
    },
    daily_qty_used() {
      return Number(this.medicine.doseAmount) * Number(this.medicine.animalQuantity)
    },
    total_qty_used() {
      return this.daily_qty_used * this.treatment_duration_days;
    },
    watch_clearance_date() {
      return [this.medicine.endDate, this.selected_medicine_product];
    }
  },
  watch: {
    existing_record() {
      if(!this.editing && this.existing_record) {
        this.medicine = this.existing_record;
        this.selectedAnimals = this.existing_record.animals.map(id => this.animals_data.find(animal => animal._id == id) || id);
        this.originalQuantity = this.existing_record.totalQuantityUsed;
        this.editing = true;
      }
    },
    animals_data() {
      if(!this.hasLoadedParams && this.$route.query.animals) {
        let animalIds = this.$route.query.animals.split(',');
        let animals = this.animals_data.filter(animal => animalIds.includes(animal._id));
        if(animals.length > 0) {
          this.selectedAnimals = animals;
        } else {
          this.selectedAnimals = [this.$route.query.animals];
        }
        
        if(this.$route.query.qty && !animals.length) {
          this.medicine.animalQuantity = this.$route.query.qty;
        }
        if(this.$route.query.reason) {
          this.medicine.reason = this.$route.query.reason;
        }
        if(this.$route.query.product) {
          this.productFilter = this.$route.query.product;
        }
        this.hasLoadedParams = true;
      }
    },
    purchased_medicine_data() {
      if(!this.medicine.product && this.purchased_medicine_data.length == 1) {
        this.medicine.product = this.purchased_medicine_data[0]._id;
        this.$refs.doseAmountField.focus();
      }
    },
    validate_selected_animals() {
      let hasError = false;
      if (this.selected_medicine_product) {
        for(let animal of this.selectedAnimals) {
          if(typeof animal == 'string') continue;

          let overlappingRecords = this.overlapping_medicine_data.filter(record => 
            record._id != this.medicine._id && record.animals.includes(animal._id)
          ) 
          if(overlappingRecords) {
            let sameProductRecord = overlappingRecords.find(record => {
              let purchase = this.purchased_medicine_data.find(product => product._id == record.product);
              return purchase.product == this.selected_medicine_product.product;
            });
            if(sameProductRecord) {
              let dateRange =  new Date(sameProductRecord.startDate).toLocaleDateString();
              if(sameProductRecord.startDate != sameProductRecord.endDate) {
                dateRange = 'from ' + dateRange + ' - ' + new Date(sameProductRecord.endDate).toLocaleDateString();
              } else {
                dateRange = 'on ' + dateRange;
              }
              Vue.set(animal, 'error', 'Existing medicine record for same product would overlap ' + dateRange);
              hasError = true;
              continue;
            }
          }
          Vue.set(animal, 'error', null);
        }
      }
      if(this.hasSelectedAnimalErrors != hasError) {
        this.hasSelectedAnimalErrors = hasError;
        this.$refs.form.validate();
      }
    },
    selectedAnimals() {
      let modified = false;
      let newData = this.selectedAnimals.map(item => {
        if(typeof item == 'string') {
          let match = this.animals_data.find(animal => animal.id == item);
          if(match) {
            modified = true;
            return match;
          }
        }
        return item;
      });
      if(modified) this.selectedAnimals = newData;
      if(!this.has_text_input) this.medicine.animalQuantity = this.selectedAnimals.length;
      this.medicine.animals = this.selectedAnimals.map(animal => typeof animal == 'string' ? animal : animal._id);
      this.$refs.form.validate(); // update animal quantity validation
    },
    total_qty_used(newVal) {
      this.medicine.totalQuantityUsed = newVal;
      this.$refs.form.validate(); // update dose amount validation
    },
    watch_clearance_date() {
      if(this.selected_medicine_product && this.medicine.endDate) {
        let clearanceDate = new Date(this.medicine.endDate);
        clearanceDate.setDate(clearanceDate.getDate() + Number(this.selected_medicine_product.withdrawalPeriod));
        this.medicine.clearanceDate = clearanceDate.toISOString().slice(0, 10);
      } else {
        this.medicine.clearanceDate = null;
      }
    }
  },
  mounted() {
    this.isLoaded = true;
  },
  methods: {
    getDateToday() {
      return new Date().toISOString().slice(0, 10);
    },
    getRelativeDate(daysOffset) {
      let dateObject = new Date();
      dateObject.setDate(dateObject.getDate() + daysOffset);
      return dateObject.toISOString().split('T')[0];
    },
    isEmptyProperty(value) {
      return value == null || value.toString().length == 0;
    },
    removeAnimal(item) {
      const index = this.selectedAnimals.indexOf(item);
      if (index >= 0) this.selectedAnimals.splice(index, 1);
    },
    sortSelectedAnimals() {
      this.selectedAnimals.sort((a, b) => {
        return (a.id || a).localeCompare(b.id || b);
      });
    },
    selectAllAnimals() {
      if(this.are_all_animal_results_selected) {
        for(let animal of this.$refs.animalsField.filteredItems) {
          this.removeAnimal(animal);
        }
      } else {
        for(let animal of this.$refs.animalsField.filteredItems) {
          if(!this.selectedAnimals.find(selected => selected._id == animal._id)) this.selectedAnimals.push(animal);
        }
        this.sortSelectedAnimals();
      }
    },
    save() {
      if(this.isSaving) return;
      if(!this.$refs.form.validate()) {
        let firstInvalidField = this.$refs.form.inputs.find(input => !input.valid)
        if(firstInvalidField) {
          firstInvalidField.focus();
          return;
        }
      }
      this.isSaving = true;

      // ensure numeric fields are stored as numbers, not strings
      this.medicine.doseAmount = Number(this.medicine.doseAmount);
      this.medicine.animalQuantity = Number(this.medicine.animalQuantity);

      let db = this.$pouch.getDB();
      let method = this.isEditing ? db.put : db.post;
      method(this.medicine).then(res => {
        console.log(res);
        this.$store.dispatch('returnToLastRoute', '/medicine');
      }).catch(console.error);
    }
  },
  pouch: {
    animals() {
      return {
        database: 'pig_manager',
        selector: {
          type: 'animal',
          $or: [
            {dateCulled: null},
            {dateCulled: {$exists: false}}
          ]
        },
        sort: [{id: 'asc'}]
      }
    },
    overlapping_medicine() {
      return {
        database: 'pig_manager',
        selector: {
          type: 'medicine',
          $or: [
            {startDate: {$lte: this.medicine.startDate}, endDate: {$gte: this.medicine.startDate}}, // b starts in a
            {startDate: {$lte: this.medicine.endDate}, endDate: {$gte: this.medicine.endDate}}, // b ends in a
            {startDate: {$gt: this.medicine.startDate}, endDate: {$lt: this.medicine.endDate}}, // a in b
          ]
        },
        sort: [{endDate: 'desc'}],
        disabled: !this.medicine.startDate || !this.medicine.endDate
      }
    },
    purchased_medicine() {
      return {
        database: 'pig_manager',
        sort: [{product: 'asc', expiryDate: 'asc'}],
        selector: this.isEditing ? {
          $or: [
            {type: 'purchasedMedicine', remainingQuantity: {$gt: 0}},
            {_id: this.medicine.product}
          ]
        } : {type: 'purchasedMedicine', remainingQuantity: {$gt: 0}}
      }
    },
    existing_record() {
      return {
        database: 'pig_manager',
        selector: {
          type: 'medicine',
          _id: this.$route.params.id
        },
        first: true,
        disabled: !this.isEditing
      }
    }
  }
}
</script>

<style scoped>
.row {
  margin-top: 0;
  margin-bottom: 0;
}

.col {
  padding-top: 0;
  padding-bottom: 0;
}
</style>