<template>
  <div :class="'readonly-outer' + (compact ? ' readonly-compact' : '')">
    <div class="readonly-inner" :style="align ? {textAlign: align} : {}">
      <div v-if="label != null" class="readonly-label" v-text="label" />
      <div v-if="value != null" :id="id" :class="'readonly-value ' + color + '--text'" v-bind="$attrs" v-text="value" />
      <div class="readonly-error-outer">
        <div :class="'readonly-error' + (valid ? ' readonly-error-hidden' : '')" v-text="errorText" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReadOnlyInput',
  props: {
    label: {type: [String, Number], default: null},
    value: {type: [String, Number, Boolean], default: null},
    id: {type: String, default: null},
    color: {type: String, default: 'black'},
    rules: {type: Array, default() {return []}},
    align: {type: String, default: null},
    compact: {type: Boolean, default: false}
  },
  data: () => ({
    valid: true,
    errorText: null
  }),
  watch: {
    value: {
      immediate: true,
      handler() {
        for(let rule of this.rules) {
          let result = rule(this.value);
          if(result !== true) {
            this.valid = false;
            this.errorText = result;
            return;
          }
        }
        this.valid = true;
      }
    }
  }
}
</script>

<style scoped>
.readonly-outer:not(.readonly-compact) {
  padding-top: 12px;
  margin-top: 4px;
}

.readonly-inner {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  font-size: 16px;
}

.readonly-label {
  transform-origin: top left;
  left: 0px;
  right: auto;
  position: absolute;
  max-width: 133%;
  transform: translateY(-18px) scale(0.75);
  width: 133%;
  pointer-events: auto;
  height: 20px;
  line-height: 20px;
  letter-spacing: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  top: 6px;
  white-space: nowrap;
}

.readonly-value {
  flex: 1 1 auto;
  line-height: 20px;
  padding: 8px 0 8px;
  max-width: 100%;
  min-width: 0px;
  width: 100%;
}

.readonly-compact .readonly-error-outer {
  display: none;
}

.readonly-error {
  color: #ff5252;
  text-align: left;
  flex: 1 1 auto;
  font-size: 12px;
  min-height: 14px;
  min-width: 1px;
  position: relative;
  font-weight: 400;
  line-height: 1rem;
  transition: top 0.25s;
  white-space: normal;
  top: 0;
}

.readonly-error-outer {
  overflow: hidden;
  flex: 1 1 auto;
  font-size: 12px;
  min-height: 14px;
  min-width: 1px;
}

.readonly-error-hidden {
  top: -20px;
}
</style>