<template>
  <v-container>
    <portal to="app-bar-icons">
      <v-btn type="submit" icon @click="save()">
        <v-icon>fas fa-save</v-icon>
      </v-btn>
    </portal>
    <v-card>
      <v-card-text>
        <v-form ref="form" v-model="isValid" @submit.prevent="save()">
          <v-autocomplete id="editor_animal" ref="animalField" v-model="selectedFarrow" label="Animal ID" :autofocus="!isEditing" return-object :items="upcoming_farrow_data" item-text="temp.displayId" :rules="[rules.required, rules.positiveScanResult]" :readonly="isEditing">
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title v-text="data.item.temp.displayId" />
                <v-list-item-subtitle v-text="data.item.temp.dueDateRelative + ' (' + data.item.temp.dueDate.toLocaleDateString() + ')'" />
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <date-picker id="editor_farrowDate" v-model="farrow.farrowDate" label="Farrow Date" :min="farrow.dateOne" :max="getDateToday()" :rules="[rules.required]" />
          <v-row>
            <v-col :cols="$vuetify.breakpoint.smAndUp ? 3 : 6">
              <v-text-field id="editor_alive" v-model="farrow.alive" label="Alive" type="number" :rules="[rules.nonNegativeNumber]" />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.smAndUp ? 3 : 6">
              <v-text-field id="editor_stillborn" v-model="farrow.stillborn" label="Stillborn" type="number" :rules="[rules.nonNegativeNumber]" />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.smAndUp ? 3 : 6">
              <v-text-field id="editor_mummified" v-model="farrow.mummified" label="Mummified" type="number" :rules="[rules.nonNegativeNumber]" />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.smAndUp ? 3 : 6">
              <read-only-input id="editor_totalBorn" ref="totalBornField" label="Total Born" :value="total_born" :rules="[rules.positiveNumber]" />
            </v-col>
          </v-row>
          <v-textarea id="editor_comments" v-model="farrow.farrowComments" label="Comments" />
          <v-data-table :headers="pigletDeathsHeaders" class="elevation-2 twotone-grey" mobile-breakpoint="0" hide-default-footer :items-per-page="-1" :items="pigletDeaths" item-key="_id">
            <template v-slot:top>
              <v-card-text class="pb-0 font-weight-bold">
                Piglet Deaths
              </v-card-text>
            </template>
            <template v-slot:header._id>
              <v-btn icon @click="pigletDeaths.push({date: getDateToday()})">
                <v-icon style="text-align: right">
                  fa-plus
                </v-icon>
              </v-btn>
            </template>
            <template v-slot:item.quantity="props">
              <v-text-field v-model="props.item.quantity" :autofocus="!props.item._id" type="number" :rules="[rules.required, rules.positiveNumber, rules.weanedNotNegative]" />
            </template>
            <template v-slot:item.date="props">
              <date-picker v-model="props.item.date" :min="farrow.farrowDate" :max="getDateToday()" :rules="[rules.required]" />
            </template>
            <template v-slot:item.reason="props">
              <v-text-field v-model="props.item.reason" :rules="[rules.required]" />
            </template>
            <template v-slot:item._id="props">
              <v-edit-dialog ref="animalRecordDeleteDialog" persistent>
                <v-btn icon>
                  <v-icon>
                    fa-trash
                  </v-icon>
                </v-btn>
                <template v-slot:input>
                  <v-card-subtitle>
                    Are you sure you want to delete?
                  </v-card-subtitle>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn text @click="$refs.animalRecordDeleteDialog.cancel()">
                      Cancel
                    </v-btn>
                    <v-btn color="red" depressed type="submit" dark @click="$refs.animalRecordDeleteDialog.save(); removePigletDeathRecord(props.item)">
                      Delete
                    </v-btn>
                  </v-card-actions>
                </template>
              </v-edit-dialog>
            </template>
          </v-data-table>
          <v-checkbox id="editor_weaned" v-model="isWeaned" :label="'Weaned (' + (still_living_amount > 0 ? still_living_amount : 0) + ')'" />
          <div v-if="isWeaned" class="ms-8">
            <date-picker id="editor_dateWeaned" v-model="farrow.weanedDate" label="Date Weaned" :max="getDateToday()" :rules="[rules.required]" />
          </div>
          <v-btn type="submit" class="d-none" />
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import DatePicker from '@/components/DatePicker';
import ReadOnlyInput from '@/components/ReadOnlyInput';

export default {
  name: 'FarrowEditor',
  components: {
    DatePicker,
    ReadOnlyInput
  },
  data: vm => ({
    isValid: null,
    isSaving: false,
    editing: false,
    selectedFarrow: null,
    pigletDeaths: [],
    isWeaned: false,
    farrow: {
      type: "breeding",
      farrowDate: vm.getDateToday(),
      farrowComments: null,
      alive: null,
      stillborn: null,
      mummified: null,
      totalBorn: null,
      weaned: null,
      weanedDate: null
    },
    rules: {
      required: value => !vm.isEmptyProperty(value) || 'Required',
      nonNegativeNumber: value => vm.isEmptyProperty(value) || value >= 0 || 'Cannot be less than 0',
      positiveNumber: value => vm.isEmptyProperty(value) || value > 0 || 'Must be more than 0',
      weanedNotNegative: () => vm.still_living_amount >= 0 || 'Quantity dead cannot be more than total born alive',
      positiveScanResult: () => vm.farrow.scanResult === true || 'Scan result must be positive'
    },
    pigletDeathsHeaders: [
      {text: 'Date', value: 'date'},
      {text: 'Qty', value: 'quantity'},
      {text: 'Reason', value: 'reason'},
      {text: '', value: '_id', sortable: false, align: 'right'}
    ]
  }),
  computed: {
    isEditing() {
      return !!this.$route.params.id;
    },
    animals_data() {
      return this.animals || [];
    },
    existing_record_data() {
      return this.existing_record || {};
    },
    piglet_deaths_data() {
      return this.piglet_deaths || [];
    },
    total_born() {
      return [this.farrow.alive, this.farrow.stillborn, this.farrow.mummified].reduce((subtotal, value) => value ? subtotal + Number(value) : subtotal, 0);
    },
    upcoming_farrow_data() {
      let source = this.isEditing ? [this.existing_record_data] : (this.upcoming_farrows || []);
      let today = new Date();
      return source.map(record => {
        record.temp = {};
        let animal = this.animals_data.find(animal => animal._id == record.id);
        if(animal) record.temp.displayId = animal.id;
        let farrowDate = new Date(record.dateOne)
        farrowDate.setDate(farrowDate.getDate() + 114);
        record.temp.dueDate = farrowDate;
        const diffTime = farrowDate - today;
        const diffDays = Math.ceil(diffTime / 86400000); // milliseconds in a day
        record.temp.daysUntilDue = diffDays;
        record.temp.dueDateRelative = 'Due ' + (diffDays == 0 ? 'today' : diffDays > 0 ? 'in ' + diffDays + ' day' + (diffDays == 1 ? '' : 's') : Math.abs(diffDays) + ' day' + (diffDays == -1 ? '' : 's') + ' ago');
        return record;
      });
    },
    still_living_amount() {
      let totalDeaths = this.pigletDeaths.reduce((subtotal, deathRecord) => deathRecord.quantity > 0 ? subtotal + Number(deathRecord.quantity) : subtotal, 0) || 0;
      return this.farrow.alive - totalDeaths;
    },
    weaned_amount() {
      return this.isWeaned ? this.still_living_amount : null;
    }
  },
  watch: {
    existing_record() {
      if(!this.editing && this.existing_record) {
        this.farrow = this.selectedFarrow = this.existing_record;
        this.isWeaned = this.farrow.weaned != null;
        this.editing = true;
      }
    },
    selectedFarrow(newVal) {
      if(newVal) {
        this.farrow = Object.assign({}, this.farrow, newVal); // merge ai/scan with any values already started in this record
        delete this.farrow.temp;
      }
    },
    total_born(newVal) {
      this.farrow.totalBorn = newVal;
    },
    piglet_deaths_data(newVal) {
      if(!this.pigletDeaths.length) this.pigletDeaths = JSON.parse(JSON.stringify(newVal));
      else {
        for(let death of newVal) {
          if(!this.pigletDeaths.find(existing => existing._id && existing._id == death._id)) this.pigletDeaths.push(death);
        }
        for(let death of this.pigletDeaths) {
          if(death._id && !newVal.find(newRecord => newRecord._id == death._id)) this.pigletDeaths.splice(this.pigletDeaths.indexOf(death), 1);
        }
      }
    },
    weaned_amount(newVal) {
      this.farrow.weaned = newVal;
      if(newVal == null) this.farrow.weanedDate = null;
      else if(this.farrow.weanedDate == null) this.farrow.weanedDate = this.getDateToday();
    }
  },
  methods: {
    getDateToday() {
      return new Date().toISOString().slice(0, 10);
    },
    isEmptyProperty(value) {
      return value == null || value.toString().length == 0;
    },
    save() {
      if(this.isSaving) return;
      if(!this.$refs.form.validate() || !this.$refs.totalBornField.valid) {
        let firstInvalidField = this.$refs.form.inputs.find(input => !input.valid);
        if(firstInvalidField) firstInvalidField.focus();
        return;
      }
      this.isSaving = true;
      this.farrow.alive = !this.farrow.alive ? 0 : Number(this.farrow.alive);
      this.farrow.stillborn = !this.farrow.stillborn ? 0 : Number(this.farrow.stillborn);
      this.farrow.mummified = !this.farrow.mummified ? 0 : Number(this.farrow.mummified);
      let db = this.$pouch.getDB();
      db.put(this.farrow).then(res => {
        console.log(res)
        
        Promise.all(this.pigletDeaths.map(pigletDeath => {
          let method = pigletDeath._id ? db.put : db.post;
          if(!pigletDeath._id) {
            pigletDeath.type = 'fallenStock';
            pigletDeath.farrowId = this.farrow._id;
            pigletDeath.id = 'Piglet';
            pigletDeath.location = 'Farrowing House';
            pigletDeath.euthanized = false;
          }
          pigletDeath.quantity = Number(pigletDeath.quantity);
          return method(pigletDeath).then(console.log).catch(console.error);
        })).then(() => {
          this.$store.dispatch('returnToLastRoute', '/farrow');
        });
      }).catch(console.error);
    },
    removePigletDeathRecord(pigletDeath) {
      if(pigletDeath._id) {
        let db = this.$pouch.getDB();
        db.remove(pigletDeath).then(res => {
          console.log(res)
          this.pigletDeaths.splice(this.pigletDeaths.indexOf(pigletDeath), 1);
        }).catch(console.error);
      } else {
        this.pigletDeaths.splice(this.pigletDeaths.indexOf(pigletDeath), 1);
      }
    }
  },
  pouch: {
    animals() {
      return {
        database: 'pig_manager',
        selector: {
          type: 'animal',
          animalType: 'Sow/gilt'
        },
        sort: [{id: 'asc'}]
      }
    },
    upcoming_farrows() {
      return {
        database: 'pig_manager',
        selector: {
          type: 'breeding',
          farrowDate: {$exists: false}
        },
        sort: [{dateOne: 'asc'}],
        disabled: this.isEditing
      }
    },
    piglet_deaths() {
      return {
        database: 'pig_manager',
        selector: {
          type: 'fallenStock',
          farrowId: this.farrow._id
        },
        sort: [{date: 'asc'}],
        disabled: !this.farrow._id
      }
    },
    existing_record() {
      return {
        database: 'pig_manager',
        selector: {
          type: 'breeding',
          _id: this.$route.params.id
        },
        first: true,
        disabled: !this.isEditing
      }
    }
  }
}
</script>

<style scoped>
.row {
  margin-top: 0;
  margin-bottom: 0;
}

.col {
  padding-top: 0;
  padding-bottom: 0;
}
</style>

<style>
.twotone-grey.v-data-table {
  background-color: #e0e0e0;
}

.twotone-grey tbody {
  background-color: #f5f5f5;
}
</style>