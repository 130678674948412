<template>
  <div>
    <portal to="app-bar-icons">
      <v-btn icon @click="open()">
        <v-icon>fa-print</v-icon>
      </v-btn>
    </portal>
    <v-dialog v-model="isOpen" max-width="500">
      <v-card class="mx-auto">
        <v-card-title>
          Print {{ name }} Records
        </v-card-title>
        <v-card-text>
          <v-form ref="printSetupForm" @submit.prevent="validatePrint()">
            <date-picker v-model="startDate" label="Start Date" :rules="[rules.required]" :clearable="false" />
            <date-picker v-model="endDate" label="End Date" :rules="[rules.required]" :clearable="false" />
            <v-card-actions>
              <v-spacer />
              <v-btn text @click="isOpen = false">
                Cancel
              </v-btn>
              <v-btn color="primary" depressed type="submit" dark>
                Print
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import prntr from 'prntr';
import DatePicker from '@/components/DatePicker';

export default {
  name: 'RecordPrinter',
  components: { DatePicker },
  props: {
    name: {type: String, default: null},
    data: {type: Array, default: () => []},
    structure: {type: Array, default: () => []},
    startDateKey: {type: String, default: 'startDate'},
    endDateKey: {type: String, default: 'endDate'},
    filter: {type: [Boolean, Function], default: false}
  },
  data: vm => ({
    isOpen: false,
    startDate: vm.getYearStartDate(),
    endDate: vm.getYearEndDate(),
    rules: {
      required: value => !vm.isEmptyProperty(value) || 'Required'
    }
  }),
  computed: {
    properties() {
      return this.structure.map(column => ({field: column.value, displayName: column.text}));
    }
  },
  methods: {
    getYearStartDate() {
      return new Date(new Date().getFullYear(), 0, 1).toISOString().split('T')[0];
    },
    getYearEndDate() {
      let date = new Date(new Date().getFullYear() + 1, 0, 1);
      date.setDate(date.getDate() - 1);
      return date.toISOString().split('T')[0];
    },
    isEmptyProperty(value) {
      return value == null || value.toString().length == 0;
    },
    validatePrint() {
      if(!this.$refs.printSetupForm.validate()) {
        let firstInvalidField = this.$refs.printSetupForm.inputs.find(input => !input.valid);
        if(firstInvalidField) firstInvalidField.focus();
        return;
      }
      this.isOpen = false;
      this.executePrint();
    },
    executePrint() {
      let data = [...this.data]; // copy array so we don't modify the source
      if(!this.filter)
        data = data.filter(row => row[this.startDateKey] <= this.endDate && row[this.endDateKey] >= this.startDate);
      else
        data = data.filter(this.filter);
      data.sort((a,b) => a[this.startDateKey].localeCompare(b[this.startDateKey]));
      prntr({
        printable: data,
        properties: this.properties,
        type: 'json',
        documentTitle: this.name + ' Record' + (!this.filter ? 's (' + new Date(this.startDate).toLocaleDateString() + ' - ' + new Date(this.endDate).toLocaleDateString() + ')' : '')
      })
    },
    open() {
      if(!this.filter) {
        this.isOpen = true;
      } else {
        this.executePrint();
      }
    }
  }
}
</script>
