import Vue from 'vue'
import PouchDB from 'pouchdb-browser'
import PouchDBFind from 'pouchdb-find'
import PouchDBLiveFind from 'pouchdb-live-find'
import PouchDBAuth from 'pouchdb-authentication'
import PouchDBLite from 'vue-pouchdb-lite'

PouchDB.plugin(PouchDBFind);
PouchDB.plugin(PouchDBLiveFind);
PouchDB.plugin(PouchDBAuth);

Vue.use(PouchDBLite, "pig_manager");

// Keep a reference to the "upstream" function.
var pouchBulkDocs = PouchDB.prototype.bulkDocs;
PouchDB.plugin({bulkDocs: validBulkDocs});

async function validBulkDocs(...args) {
  let [body, options, callback] = args;
  if (typeof options == 'function') {
    callback = options
    options = {}
  }
  if(callback) {
    var docs = body;
    if (!Array.isArray(body)) docs = body.docs;

    for (let doc of docs) {
      if(doc.replicator) continue; // ignore sync stuff

      doc.modifiedAt = new Date().getTime();
      doc.modifiedBy = localStorage.getItem('user');

      // Handle AI/Medicine record updates - should update purchased semen capacity
      let semenIds = [], purchasedMedicineIds = [];
      if(doc._id) { // handles edits and deletions
        try {
          let oldDoc = await this.get(doc._id);
          if(oldDoc.type == 'breeding') semenIds.push(oldDoc.semenOne, oldDoc.semenTwo);
          if(oldDoc.type == 'medicine') purchasedMedicineIds.push(oldDoc.product);
        // eslint-disable-next-line no-empty
        } catch(e) {}
      }
      if(doc.type == 'breeding') semenIds.push(doc.semenOne, doc.semenTwo);
      if(doc.type == 'medicine') purchasedMedicineIds.push(doc.product);
      for(let semenId of new Set(semenIds)) // sets remove duplicates
        await updatePurchasedSemenRemaining(this, doc, semenId);
      for(let purchasedMedicineId of new Set(purchasedMedicineIds)) // sets remove duplicates
        await updatePurchasedMedicineRemaining(this, doc, purchasedMedicineId);
      // < end AI/Medicine record updates
    }
  }

  // All documents check out. Pass them to PouchDB.
  return pouchBulkDocs.call(this, ...args);
}

async function updatePurchasedSemenRemaining(db, currentAiDoc, semenId) {
  if(semenId == null) return;

  try {
    let semenRecord = await db.get(semenId);
    if(semenRecord.type == 'purchasedSemen') {
      let allUses = (await db.find({
        selector: {
          type: 'breeding',
          $or: [
            {semenOne: semenId},
            {semenTwo: semenId}
          ]
        }
      })).docs;
      let totalUses = allUses.reduce((subtotal, use) => {
        if(use._id != currentAiDoc._id) {
          if(use.semenOne == semenId) subtotal++;
          if(use.semenTwo == semenId) subtotal++;
        }
        return subtotal;
      }, 0);
      if(currentAiDoc.semenOne == semenId) totalUses++;
      if(currentAiDoc.semenTwo == semenId) totalUses++;
      semenRecord.remaining = semenRecord.quantity - totalUses;
      await db.put(semenRecord);
    }
  } catch(error) {
    console.error(error);
  }
}

async function updatePurchasedMedicineRemaining(db, currentMedicineDoc, purchasedMedicineId) {
  if(purchasedMedicineId == null) return;
  try {
    let purchasedMedicineRecord = await db.get(purchasedMedicineId);
    if(purchasedMedicineRecord.type == 'purchasedMedicine') {
      let allUses = (await db.find({
        selector: {
          type: 'medicine',
          product: purchasedMedicineId
        }
      })).docs;
      let totalUsed = allUses.reduce((subtotal, use) => {
        if(use._id != currentMedicineDoc._id) {
          subtotal += use.totalQuantityUsed;
        }
        return subtotal;
      }, 0);
      if(currentMedicineDoc.product == purchasedMedicineId) totalUsed += currentMedicineDoc.totalQuantityUsed;
      purchasedMedicineRecord.remainingQuantity = purchasedMedicineRecord.totalQuantity - totalUsed;
      await db.put(purchasedMedicineRecord);
    }
  } catch(error) {
    console.error(error);
  }
}