<template>
  <v-container>
    <portal to="app-bar-icons">
      <v-btn type="submit" icon @click="save()">
        <v-icon>fas fa-save</v-icon>
      </v-btn>
    </portal>
    <v-card>
      <v-card-text>
        <v-form ref="form" v-model="isValid" @submit.prevent="save()">
          <v-combobox id="editor_product" ref="productField" v-model="purchasedMedicine.product" label="Product" :autofocus="!isEditing" :items="medicineProducts" :rules="[rules.required]" @keydown="handleEnterKey" @keyup="handleEnterKeyFinish" />
          <v-row>
            <v-col cols="6">
              <v-text-field id="editor_batchNo" v-model="purchasedMedicine.batchNo" label="Batch No." :rules="[rules.required]" />
            </v-col>
            <v-col cols="6">
              <date-picker id="editor_expiryDate" v-model="purchasedMedicine.expiryDate" label="Expiry Date" :rules="[rules.required]" :clearable="false" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-select id="editor_measurementUnit" ref="measurementUnitField" v-model="purchasedMedicine.measurementUnit" label="Measurement Unit" :items="measurementUnits" :rules="[rules.required]" />
            </v-col>
            <v-col cols="6">
              <v-text-field id="editor_totalQuantity" v-model="purchasedMedicine.totalQuantity" :label="'Total Qty (' + purchasedMedicine.measurementUnit + ')'" type="number" :rules="[rules.required, rules.moreThanZero, rules.remainingNotNegative]" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <date-picker id="editor_purchaseDate" v-model="purchasedMedicine.purchaseDate" label="Purchase Date" :rules="[rules.required]" :clearable="false" />
            </v-col>
            <v-col cols="6">
              <v-text-field id="editor_withdrawalPeriod" v-model="purchasedMedicine.withdrawalPeriod" label="Withdrawal Period (days)" type="number" :rules="[rules.notNegative]" />
            </v-col>
          </v-row>
          
          <v-btn type="submit" class="d-none" />
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import DatePicker from '@/components/DatePicker';
import Vue from 'vue';

export default {
  name: 'PurchasedMedicineEditor',
  components: {
    DatePicker
  },
  data: vm => ({
    isValid: null,
    isSaving: false,
    editing: false,
    medicineProducts: [],
    existingQtyUsed: 0,
    purchasedMedicine: {
      type: "purchasedMedicine",
      purchaseDate: vm.getDateToday(),
      product: null,
      batchNo: null,
      expiryDate: null,
      measurementUnit: "ml",
      totalQuantity: null,
      remainingQuantity: null,
      withdrawalPeriod: null
    },
    measurementUnits: [
      {text: 'Millilitres (ml)', value: 'ml'},
      {text: 'Kilograms (kg)', value: 'kg'}
    ],
    rules: {
      required: value => (value != null && value.toString().length > 0) || 'Required',
      notNegative: value => value >= 0 || 'Number cannot be less than zero',
      moreThanZero: value => value > 0 || 'Number must be more than zero',
      remainingNotNegative: () => vm.purchasedMedicine.remainingQuantity == null || vm.purchasedMedicine.remainingQuantity >= 0 || 'Total quantity cannot be less than quantity already used'
    }
  }),
  computed: {
    isEditing() {
      return !!this.$route.params.id;
    },
    watch_quantity() {
      return [this.purchasedMedicine.totalQuantity, this.existingQtyUsed];
    }
  },
  watch: {
    existing_record() {
      if(!this.editing && this.existing_record) {
        this.purchasedMedicine = this.existing_record;
        this.editing = true;
      }
    },
    watch_quantity() {
      this.purchasedMedicine.remainingQuantity = this.purchasedMedicine.totalQuantity != null ? Number(this.purchasedMedicine.totalQuantity) - this.existingQtyUsed : null;
    },
    existing_uses(newVal) {
      this.existingQtyUsed = newVal.reduce((subtotal, use) => subtotal + use.totalQuantityUsed, 0);
    }
  },
  async created() {
    this.medicineProducts = (await this.$pouch.getDB().query('app/medicine-products', {group:true})).rows.map(row => row.key);
  },
  methods: {
    getDateToday() {
      return new Date().toISOString().slice(0, 10);
    },
    save() {
      if(this.isSaving) return;
      if(this.$refs.productField.isFocused && this.$refs.productField.value != this.$refs.productField.internalSearch) {
        // the value isn't usually updated until the combobox loses focus
        this.$refs.productField.setValue(this.$refs.productField.internalSearch);
      }
      if(!this.isValid) {
        this.$refs.form.validate();
        let firstInvalidField = this.$refs.form.inputs.find(input => !input.valid)
        if(firstInvalidField) {
          firstInvalidField.focus();
          return;
        }
      }
      this.isSaving = true;
      if(!this.purchasedMedicine.withdrawalPeriod) this.purchasedMedicine.withdrawalPeriod = 0;
      this.purchasedMedicine.totalQuantity = Number(this.purchasedMedicine.totalQuantity);

      let db = this.$pouch.getDB();
      let method = this.isEditing ? db.put : db.post;
      method(this.purchasedMedicine).then((res) => {
        console.log(res);
        this.$store.dispatch('returnToLastRoute', '/purchased-medicine');
      }).catch(err => {
        console.error(err);
      });
    },
    handleEnterKey(event) {
      if(event.code === "Enter") {
        let el = event.target;
        while(!el.__vue__ && el.parentNode) el = el.parentNode;
        if(el.__vue__.internalSearch && el.__vue__.value == el.__vue__.internalSearch) {
          event.preventDefault();
          this.save();
        }
      }
    },
    handleEnterKeyFinish(event) {
      if(event.code === "Enter") {
        let el = event.target;
        while(!el.__vue__ && el.parentNode) el = el.parentNode;
        if(el.__vue__.internalSearch && el.__vue__.value == el.__vue__.internalSearch) {
          Vue.nextTick(() => {
            el.__vue__.isMenuActive = false;
          });
        }
      }
    }
  },
  pouch: {
    existing_record() {
      return {
        database: 'pig_manager',
        selector: {
          type: 'purchasedMedicine',
          _id: this.$route.params.id
        },
        first: true,
        disabled: !this.isEditing
      }
    },
    existing_uses() {
      return {
        database: 'pig_manager',
        selector: {
          type: 'medicine',
          product: this.purchasedMedicine._id
        },
        disabled: !this.purchasedMedicine._id
      }
    }
  }
}
</script>

<style scoped>
.row {
  margin-top: 0;
  margin-bottom: 0;
}

.col {
  padding-top: 0;
  padding-bottom: 0;
}
</style>