<template>
  <v-app-bar app dark color="primary">
    <v-btn v-if="isDesktop && $store.state.lastRoute.length && $store.state.lastRoute[$store.state.lastRoute.length-1] != '/'" icon @click="$router.back()">
      <v-icon>fas fa-arrow-left</v-icon>
    </v-btn>
    <v-btn v-if="$route.name != 'Home'" icon @click="$router.push('/')">
      <v-icon>fas fa-home</v-icon>
    </v-btn>
    <v-toolbar-title>
      Pig Manager
      <portal-target name="app-bar-subheading" tag="div" class="subheading" v-text="$router.currentRoute.name" />
    </v-toolbar-title>
    <v-spacer />
    <portal-target name="app-bar-icons" multiple />

    <v-menu v-if="$store.state.loggedIn" bottom left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>fas fa-ellipsis-v</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item @click="$store.dispatch('logOut')">
          <v-list-item-icon><v-icon> fas fa-user-circle</v-icon></v-list-item-icon>
          <v-list-item-title>Log out</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-btn v-if="!$store.state.loggedIn" icon to="/login">
      <v-icon>fas fa-user-circle</v-icon>
    </v-btn>

    <template v-if="$route.meta.tabs" v-slot:extension>
      <v-tabs centered>
        <v-tabs-slider color="yellow" />
        <v-tab v-for="(path, text) in $route.meta.tabs" :key="text" exact :to="path.replace(':id', $route.params.id)" replace v-text="text" />
      </v-tabs>
    </template>
  </v-app-bar>
</template>

<script>
import Bowser from "bowser";

export default {
  name: 'AppBar',
  computed: {
    isDesktop() {
      // used to add a back button to the UI on desktop - mobile devices have a built-in back button already
      let parser = Bowser.getParser(window.navigator.userAgent);
      return parser.getPlatformType() == 'desktop'; // possible values are: tablet, mobile, desktop, tv
    }
  }
}
</script>