<template>
  <v-container>
    <portal to="app-bar-icons">
      <v-btn type="submit" icon @click="save()">
        <v-icon>fas fa-save</v-icon>
      </v-btn>
    </portal>
    <v-card>
      <v-card-text>
        <v-form ref="form" v-model="isValid" @submit.prevent="save()">
          <v-text-field id="editor_animalId" ref="idField" v-model="animal.id" label="Animal ID" :autofocus="!isEditing" :rules="[rules.required, rules.uniqueAnimal]" prepend-icon="fa-hashtag" />
          <v-row>
            <v-col :cols="isSowOrGilt ? 6 : 12">
              <v-select id="editor_type" v-model="animal.animalType" label="Type" :items="['Sow/gilt', 'Boar']" :rules="[rules.required]" />
            </v-col>
            <v-col v-if="isSowOrGilt" cols="6">
              <v-text-field id="editor_startingParity" v-model="animal.startingParity" label="Starting Parity" type="number" :rules="[rules.required, rules.notNegative]" />
            </v-col>
          </v-row>
          <v-combobox id="editor_breed" ref="breedField" v-model="animal.breed" label="Breed" type="text" :items="breeds" :rules="[rules.required]" clearable @keydown="handleKeyUp" />
          <v-text-field id="editor_damId" ref="damIdField" v-model="damDisplayId" label="Dam ID" type="text" :rules="[rules.emptyOrValidDamId]" />
          <date-picker id="editor_dateSelected" v-model="animal.dateSelected" label="Date selected" :max="getDateToday()" :rules="[rules.required]" />
          <v-checkbox id="editor_cycleNotifications" v-model="cycleNotifications" label="Cycle notifications" />
          <div v-if="cycleNotifications" class="ms-8">
            <date-picker id="editor_lastCycleDetected" v-model="animal.lastCycleDetected" label="Last cycle detected" :max="getDateToday()" :rules="[rules.required]" />
          </div>
          <v-checkbox id="editor_culled" v-model="culled" label="Culled" />
          <div v-if="culled" class="ms-8">
            <date-picker id="editor_dateCulled" v-model="animal.dateCulled" label="Date Culled" :max="getDateToday()" :rules="[rules.required]" />
          </div>
          <v-btn type="submit" class="d-none" />
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import DatePicker from '@/components/DatePicker'

export default {
  name: 'HerdEditor',
  components: {
    DatePicker
  },
  data: vm => ({
    isValid: null,
    isSaving: false,
    cycleNotifications: false,
    culled: false,
    editing: false,
    originalId: null,
    breeds: [],
    damDisplayId: null,
    animal: {
      id: null,
      type: "animal",
      animalType: "Sow/gilt",
      startingParity: 0,
      breed: null,
      dateSelected: vm.getDateToday(),
      lastCycleDetected: null,
      damId: null,
      dateCulled: null
    },
    rules: {
      required: value => (value != null && value.toString().length > 0) || 'Required',
      notNegative: value => value >= 0 || 'Number cannot be negative',
      uniqueAnimal: () => vm.hasUniqueAnimalId || vm.originalId == vm.animal.id || 'ID is already in use',
      emptyOrValidDamId: value => value == null || value.toString().length == 0 || vm.hasValidDamId || 'Dam ID does not exist'
    }
  }),
  computed: {
    isEditing() {
      return !!this.$route.params.id;
    },
    isSowOrGilt() {
      return this.animal.animalType == 'Sow/gilt';
    },
    hasUniqueAnimalId() {
      return this.animal_id_unique_check == null;
    },
    hasValidDamId() {
      return this.dam_id_lookup && !!this.dam_id_lookup._id;
    }
  },
  watch: {
    cycleNotifications(newVal) {
      if(!newVal) this.animal.lastCycleDetected = null;
    },
    culled(newVal) {
      this.animal.dateCulled = newVal ? this.getDateToday() : null;
    },
    isSowOrGilt(newVal) {
      if(!newVal) this.animal.startingParity = null;
      else if(!this.animal.startingParity) this.animal.startingParity = 0;
    },
    existing_animal() {
      if(!this.editing && this.existing_animal) {
        this.animal = this.existing_animal;
        this.cycleNotifications = !!this.animal.lastCycleDetected;
        this.culled = !!this.animal.dateCulled;
        this.editing = true;
        this.originalId = this.animal.id;
      }
    },
    animal_id_unique_check() {
      this.$refs.idField.validate(); // updates field validation when db live update is complete
    },
    dam_id_lookup() {
      this.$refs.damIdField.validate();
    },
    current_dam_id_lookup() {
      if(this.current_dam_id_lookup) {
        this.damDisplayId = this.current_dam_id_lookup.id;
      }
      this.$refs.damIdField.validate();
    }
  },
  async created() {
    this.breeds = (await this.$pouch.getDB().query('app/animal-breeds', {group:true})).rows.map(row => row.key);
  },
  methods: {
    getDateToday() {
      return new Date().toISOString().slice(0, 10);
    },
    save() {
      if(this.isSaving) return;
      if(this.$refs.breedField.isFocused && this.$refs.breedField.value != this.$refs.breedField.internalSearch) {
        // the value isn't usually updated until the combobox loses focus
        this.$refs.breedField.setValue(this.$refs.breedField.internalSearch);
      }
      if(!this.isValid) {
        this.$refs.form.validate();
        let firstInvalidField = this.$refs.form.inputs.find(input => !input.valid)
        if(firstInvalidField) {
          firstInvalidField.focus();
          return;
        }
      }
      this.isSaving = true;
      this.animal.startingParity = Number(this.animal.startingParity || 0);
      if(!this.current_dam_id_lookup || this.current_dam_id_lookup.id != this.damDisplayId) {
        this.animal.damId = this.dam_id_lookup ? this.dam_id_lookup._id : null;
      }
      let db = this.$pouch.getDB()
      let method = this.isEditing ? db.put : db.post;
      method(this.animal).then((res) => {
        console.log(res);
        this.$store.dispatch('returnToLastRoute', '/herd');
      }).catch(err => {
        console.error(err);
      });
    },
    handleKeyUp(event) {
      if(event.code === "Enter") {
        if(!event.target.hasAttribute('aria-activedescendant') || event.target.value == document.getElementById(event.target.getAttribute('aria-activedescendant')).innerText) {
          event.preventDefault();
          this.save();
        }
      }
    }
  },
  pouch: {
    existing_animal() {
      return {
        database: 'pig_manager',
        selector: {
          type: 'animal',
          _id: this.$route.params.id
        },
        first: true,
        disabled: !this.isEditing
      }
    },
    animal_id_unique_check() {
      return {
        database: 'pig_manager',
        selector: {
          type: 'animal',
          id: this.animal.id,
          $or: [
            {dateCulled: null},
            {dateCulled: {$exists: false}}
          ]
          // allows reusing animal ids once moved off
        },
        first: true
      }
    },
    current_dam_id_lookup() {
      return {
        database: 'pig_manager',
        selector: {
          type: 'animal',
          _id: this.animal.damId
        },
        first: true,
        disabled: !this.animal.damId
      }
    },
    dam_id_lookup() {
      return {
        database: 'pig_manager',
        selector: {
          type: 'animal',
          id: this.damDisplayId
        },
        first: true,
        disabled: !this.damDisplayId
      }
    }
  }
}
</script>