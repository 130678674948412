<template>
  <v-container>
    <portal to="app-bar-icons">
      <v-btn type="submit" icon @click="save()">
        <v-icon>fas fa-save</v-icon>
      </v-btn>
    </portal>
    <v-card>
      <v-card-text>
        <v-form ref="form" v-model="isValid" @submit.prevent="save()">
          <div class="label">
            Animal ID
          </div>
          <v-chip class="my-1 readonly-value" v-text="animal_data.id" />
          <div class="label mt-4">
            Scan Due Date
          </div>
          <div class="readonly-value black--text">
            {{ scanDueDate }}
          </div>
          <v-radio-group v-model="ai.scanResult" autofocus row :rules="[rules.positiveIfFarrowed]">
            <v-radio label="Negative" :value="false" />
            <v-radio label="Not Scanned" :value="null" />
            <v-radio label="Positive" :value="true" />
          </v-radio-group>
          <date-picker v-if="ai.scanResult != null" v-model="ai.scanDate" label="Scan Date" :max="getDateToday()" :rules="[rules.required]" />
          <v-btn type="submit" class="d-none" />
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import DatePicker from '@/components/DatePicker';

export default {
  name: 'AIScanEditor',
  components: { DatePicker },
  data: vm => ({
    isValid: null,
    isSaving: false,
    editing: false,
    ai: {
      type: "breeding",
      id: null,
      scanResult: null,
      scanDate: vm.getDateToday()
    },
    rules: {
      required: value => !vm.isEmptyProperty(value) || 'Required',
      positiveIfFarrowed: () => !vm.ai.farrowDate || vm.ai.scanResult === true || 'Scan must be positive when farrow record is present'
    }
  }),
  computed: {
    animal_data() {
      return this.animal || [];
    },
    scanDueDate() {
      let date = '';
      if(this.ai.dateOne) {
        date = new Date(this.ai.dateOne);
        date.setDate(date.getDate() + 28); // 1 month AI'ed
        date = date.toLocaleDateString();
      }
      return date;
    },
    watch_scan_result() {
      return this.ai.scanResult;
    }
  },
  watch: {
    existing_record() {
      if(!this.editing && this.existing_record) {
        this.ai = Object.assign({}, this.ai, this.existing_record);
        this.editing = true;
      }
    },
    watch_scan_result() {
      if(this.ai.scanResult == null) this.ai.scanDate = null;
    }
  },
  methods: {
    getDateToday() {
      return new Date().toISOString().slice(0, 10);
    },
    isEmptyProperty(value) {
      return value == null || value.toString().length == 0;
    },
    save() {
      if(this.isSaving) return;
      if(!this.$refs.form.validate()) {
        let firstInvalidField = this.$refs.form.inputs.find(input => !input.valid)
        if(firstInvalidField) {
          firstInvalidField.focus();
          return;
        }
      }
      this.isSaving = true;
      let db = this.$pouch.getDB();
      db.put(this.ai).then(res => {
        console.log(res);
        this.$store.dispatch('returnToLastRoute', '/ai');
      }).catch(console.error);
    }
  },
  pouch: {
    animal() {
      return {
        database: 'pig_manager',
        selector: {
          type: 'animal',
          _id: this.existing_record ? this.existing_record.id : null
        },
        first: true
      }
    },
    existing_record() {
      return {
        database: 'pig_manager',
        selector: {
          type: 'breeding',
          _id: this.$route.params.id
        },
        first: true
      }
    }
  }
}
</script>

<style scoped>
.row {
  margin-top: 0;
  margin-bottom: 0;
}

.col {
  padding-top: 0;
  padding-bottom: 0;
}

.label {
  font-size: 12px;
}

.readonly-value {
  font-size: 16px;
}
</style>