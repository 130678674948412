<template>
  <v-container>
    <portal to="app-bar-icons">
      <v-btn icon to="/medicine/add">
        <v-icon>fas fa-plus-circle</v-icon>
      </v-btn>
    </portal>
    <v-data-table :headers="headers" :items="medicine_data" item-key="_id" class="elevation-2" mobile-breakpoint="0" @click:row="editRecord" @contextmenu:row.prevent="openContextMenu" />
    <v-menu v-model="contextMenu.visible" absolute :position-x="contextMenu.x" :position-y="contextMenu.y" offset-y>
      <v-list>
        <v-list-item v-for="item in contextMenu.items" :key="item.text" :ripple="item.color ? {class: item.color + '--text'} : true" @click="item.action">
          <v-list-item-icon><v-icon :color="item.color" v-text="item.icon" /></v-list-item-icon>
          <v-list-item-title :class="item.color ? item.color + '--text' : ''" v-text="item.text" />
        </v-list-item>
      </v-list>
    </v-menu>
    <record-printer name="Medicine" :structure="headers" :data="medicine_data" />
  </v-container>
</template>

<script>
import RecordPrinter from '@/components/RecordPrinter';

export default {
  name: 'Medicine',
  components: { RecordPrinter },
  data: vm => ({
    headers: [
      {text: 'Start Date', value: 'startDateFormatted'},
      {text: 'End Date', value: 'endDateFormatted'},
      {text: 'Clearance Date', value: 'clearanceDateFormatted'},
      {text: 'Product', value: 'productName'},
      {text: 'Batch No', value: 'batchNo'},
      {text: 'Expiry Date', value: 'expiryDateFormatted'},
      {text: 'Animal Qty', value: 'animalQuantity'},
      {text: 'Animal IDs', value: 'animalsFormatted'},
      {text: 'By Whom', value: 'modifiedBy'},
      {text: 'Withdrawal Period', value: 'withdrawalPeriod'},
      {text: 'Total Used', value: 'totalQuantityUsedFormatted'},
      {text: 'Reason', value: 'reason'}
    ],
    contextMenu: {
      visible: false,
      x: 0,
      y: 0,
      recordId: null,
      items: [
        {
          icon: 'fa-pencil-alt',
          text: 'Edit',
          action() {
            vm.$router.push('/medicine/' + vm.contextMenu.recordId);
          }
        },
        {
          icon: 'fa-trash',
          text: 'Delete',
          color: 'red',
          action() {
            vm.$router.push('/medicine/' + vm.contextMenu.recordId + '/delete');
          }
        }
      ]
    }
  }),
  computed: {
    purchased_medicine_data() {
      return this.purchased_medicine || [];
    },
    animals_data() {
      return this.animals || [];
    },
    medicine_data() {
      return (this.medicine || []).map(row => {
        // format data
        row.animalsFormatted = row.animals.map(rawAnimalId => {
          let animal = this.animals_data.find(animal => animal._id == rawAnimalId);
          return animal ? animal.id : rawAnimalId;
        }).join(', ');
        if(row.animalsFormatted.length > 30) row.animalsFormatted = row.animalsFormatted.substr(0, 29) + '…';

        let purchasedMedicine = this.purchased_medicine_data.find(record => record._id == row.product);
        if(purchasedMedicine) {
          row.productName = purchasedMedicine.product;
          row.batchNo = purchasedMedicine.batchNo;
          row.expiryDateFormatted = new Date(purchasedMedicine.expiryDate).toLocaleDateString();
          row.withdrawalPeriod = purchasedMedicine.withdrawalPeriod + (purchasedMedicine.withdrawalPeriod == 1 ? ' day' : ' days');
          row.totalQuantityUsedFormatted = row.totalQuantityUsed + purchasedMedicine.measurementUnit;
        }

        // format dates for user's locale
        row.startDateFormatted = new Date(row.startDate).toLocaleDateString();
        row.endDateFormatted = new Date(row.endDate).toLocaleDateString();
        row.clearanceDateFormatted = new Date(row.clearanceDate).toLocaleDateString();
        return row;
      })
    }
  },
  methods: {
    editRecord(record) {
      this.$router.push('/medicine/' + record._id);
    },
    openContextMenu(event, row) {
      this.contextMenu.visible = true;
      this.contextMenu.x = event.x;
      this.contextMenu.y = event.y;
      this.contextMenu.recordId = row.item._id;
    }
  },
  pouch: {
    medicine() {
      return {
        database: 'pig_manager',
        sort: [{startDate: "desc"}],
        selector: {type: 'medicine'}
      }
    },
    animals() {
      return {
        database: 'pig_manager',
        selector: {
          type: 'animal'
        }
      }
    },
    purchased_medicine() {
      return {
        database: 'pig_manager',
        selector: {
          type: 'purchasedMedicine'
        }
      }
    }
  }
}
</script>